const INTITIAL_STATE = {
  active_table: "kam",
};

const dashboardTableSelectReducer = (state = INTITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_TABLE":
      return {
        ...state,
        active_table: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardTableSelectReducer;

import React from "react"
import "./MDOTableList.styles.scss"

const MDOTableList = ({ data }) => {
  const { name, image, contact_number, spot_codes, id, status } = data
  return (
    <tr className="mdo-row">
      <td className="worker-avatar-column">
        <div className="avatar-chip">
          {image ? (
            <img
              src={`${image}`}
              alt={`worker name ${name}`}
              className="worker-image"
            />
          ) : (
            <img
              src={`https://api.adorable.io/avatars/285/${id}`}
              alt={`worker name ${name}`}
              className="worker-image"
            />
          )}

          <p>{name}</p>
          {status === "Deactivated" ? (
            <span className="status">Deactivated</span>
          ) : null}
        </div>
      </td>

      <td className="mdo-phone">
        <span>{contact_number}</span>
      </td>

      <td className="spots">
        {spot_codes.map((spot, index) => (
          <span key={index}>{spot}, </span>
        ))}
      </td>
    </tr>
  )
}

export default MDOTableList

import React, { Component } from "react"
// import axios from "axios";
import http from "../../../utilities/_http"
import PropTypes from "prop-types"

import moment from "moment"

import LoadingCard from "./loadingCard"

import { query_generate } from "../../../utilities/_query_generate"

import "./../styles/agencyPerformance.scss"

const agency_performance = {
  card_heading: "Agency Performance",
  state: "agency_performance_state",
  api_link: `api/organizations/api/v1/agency-report`,
  conrner_right_top_img: "none",
}

/**
 * @description Table of agencies and their respective performance metrics
 * @component Agency Performance
 * @category Dashboard
 * @author Zoha
 */
class AgencyPerformance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data_fetching: false,
      view_all: false,
      agency_performance_state: [],
      data_length: 0,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.date !== prevProps.date ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set()
  }

  set_data_set = () => {
    // let query_pre_process_original = [
    //   {
    //     key: "team",
    //     values:
    //       this.props.filterElement.relational !== undefined
    //         ? this.props.filterElement.relational.team
    //         : [],
    //   },
    //   {
    //     key: "cluster_manager",
    //     values:
    //       this.props.filterElement.relational !== undefined
    //         ? this.props.filterElement.relational.managers
    //         : [],
    //   },
    //   {
    //     key: "agency",
    //     values:
    //       this.props.filterElement.relational !== undefined
    //         ? this.props.filterElement.relational.agency
    //         : [],
    //   },
    // ]
    let query_pre_process = [
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "region",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.region
            : [],
      },
      {
        key: "area",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.area
            : [],
      },
      {
        key: "territory",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.territory
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
    ]
    let query = query_generate(query_pre_process)

    this.setState({
      data_fetching: true,
    })

    http
      .get(
        `${process.env.REACT_APP_API_URL}/${
          agency_performance["api_link"]
        }?start_time=${moment(this.props.date).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(this.props.date).format("YYYY-MM-DD")}${
          query !== "" ? "&" + query : ""
        }`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) =>
        this.state.view_all
          ? this.setState({
              [`${agency_performance["state"]}`]: res.data,
              data_fetching: false,
              data_length: res.data.length,
            })
          : this.setState({
              [`${agency_performance["state"]}`]: res.data.slice(0, 5),
              data_fetching: false,
              data_length: res.data.length,
            })
      )
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  view_all = () => {
    this.setState(
      {
        view_all: true,
      },
      () => this.set_data_set()
    )
  }

  view_fewer = () => {
    this.setState(
      {
        view_all: false,
      },
      this.set_data_set()
    )
  }

  render() {
    return (
      <div className="card-col col s12 l12">
        {this.state.data_fetching ? (
          <LoadingCard card_heading={`${agency_performance.card_heading}`} />
        ) : (
          <div className="card agency-performance-cardx">
            <div className="card-title">{agency_performance.card_heading}</div>
            <div className="card-body">
              {this.state.agency_performance_state && (
                <table className="agency-performance">
                  <thead className="agency-performance-main-head">
                    <tr className="agency-performance-main-head-tr">
                      <th className="agency-performance-main-head-th blank_th">
                        Agency
                      </th>
                      <th className="agency-performance-main-head-th">
                        Total Calls
                      </th>
                      <th className="agency-performance-main-head-th">
                        Calls/Active MDO
                      </th>
                      <th className="agency-performance-main-head-th">
                        Lead Closed/ Active MDO
                      </th>
                      <th className="agency-performance-main-head-th">
                        Avg. Days Taken
                      </th>
                      <th className="agency-performance-main-head-th">
                        Qr Display
                      </th>
                      <th className="agency-performance-main-head-th">
                        POSM Presence
                      </th>
                    </tr>
                  </thead>

                  <tbody className="agency-performance-main-body">
                    {this.state.agency_performance_state.map((item, index) => (
                      <tr className="agency-performance-body-tr" key={index}>
                        <td className="agency-performance-text-value">
                          {item.agency_name}
                        </td>
                        <td className="agency-performance-value">
                          {item.total_call}
                        </td>
                        <td className="agency-performance-value">
                          {item.calls_per_mdo}
                        </td>
                        <td className="agency-performance-value">
                          {item.lead_closed_per_mdo}
                        </td>
                        <td className="agency-performance-value">
                          {item.avg_day_for_lead_closed}
                        </td>
                        <td className="agency-performance-value">
                          {item.qr_display_percentage}%
                        </td>
                        <td className="agency-performance-value">
                          {item.posm_presence_percentage}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            {this.state.data_length > 5 && (
              <div className="card-btn">
                {this.state.view_all ? (
                  <div className="view-all-btn" onClick={this.view_fewer}>
                    <span>View Fewer Agency Performances</span>
                  </div>
                ) : (
                  <div className="view-all-btn" onClick={this.view_all}>
                    <span>View All Agency Performances</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default AgencyPerformance

AgencyPerformance.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

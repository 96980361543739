import React, { Component } from "react";
// import axios from "axios";
import http from "../../utilities/_http";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";

import { query_generate } from "./../../utilities/_query_generate";
import {
  relational_filter_list,
  non_relational_filter_list,
  non_relational_filter_list_dash_agency,
} from "./../../utilities/_filter_list_builder";
import { has_selected_filter } from "./../../utilities/_has_selected_filter";

import Call from "./components/callCard";
import LoadingCard from "./components/loading";

import FilterElement from "./../layout/FilterElement";
import Pagination from "../layout/Pagination";
import Calendar from "../layout/Calendar";

import { updateFilterElement } from "../../store/actions/filterElementAction";
import { updateFilterHide } from "./../../store/actions/filterAction";
import { signOut } from "../../store/actions/authAction";

import "./style.css";
import "./../layout/styles/filter.css";
import Footer from "../Footer/Footer";

class Calls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calls: null,
      total: null,
      limit: null,

      data_fetching: false,
    };
  }

  componentDidMount() {
    this.set_data_set();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.currentPage !== prevProps.currentPage ||
      this.props.currentLimit !== prevProps.currentLimit ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set();
  }

  set_data_set = () => {
    let query = query_generate([
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "cluster_manager",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.managers
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
      {
        key: "call_outcome",
        values:
          this.props.filterElement.non_relational !== undefined
            ? this.props.filterElement.non_relational.call_outcome
            : [],
      },
    ]);

    this.setState({
      data_fetching: true,
    });

    http
      .get(
        `${process.env.REACT_APP_API_URL}/api/calls/v1/list?start_time=${moment(
          this.props.startDate
        ).format("YYYY-MM-DD")}&end_time=${moment(this.props.endDate).format(
          "YYYY-MM-DD"
        )}&page_size=${this.props.currentLimit}&page_index=${
          this.props.currentPage
        }${query !== "" ? "&" + query : query}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          calls: res.data.data,
          total: res.data.count,
          limit: res.data.limit,

          data_fetching: false,
        });
      })
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      );
  };

  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ));

  async remove_all_filter() {
    await this.props.updateFilterElement({
      calls: {
        relational: {},
        non_relational: {},
      },
    });
  }

  callBackForOutletRouting = (outlet_number) => {
    console.log(outlet_number);
  };

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"));
    if (!localUserToken) return <Redirect to="/signin" />;
    const localUserType = JSON.parse(localStorage.getItem("currUserType"));

    let current_time = Date.now().valueOf() / 1000;
    if (this.props.expiration < current_time) this.props.signOut();

    return (
      <div className="main-container">
        {/* <div className={JSON.parse(localStorage.getItem('currUserType')) !== "agency_user" ?
                "calls"
                : "full-width-calls"}
            > */}
        <div className="calls">
          <div className="page-header">
            <div className="page-heading">
              Calls
              <div className="page-sub-heading">
                {moment(this.props.startDate).format("ddd, MMM DD")} to{" "}
                {moment(this.props.endDate).format("ddd, MMM DD")}
              </div>
            </div>
            <div className="page-settings">
              {this.props.filterHide && (
                <div
                  className="filter-container"
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <div className="header">
                    <div className="heading">Select Filter</div>
                    {has_selected_filter(this.props.filterElement) ? (
                      <div className="clear">
                        <button
                          className="active-button"
                          onClick={() => this.remove_all_filter()}
                        >
                          Clear All
                        </button>
                      </div>
                    ) : (
                      <div className="clear">
                        <button
                          className="inactive-button"
                          onClick={() => this.remove_all_filter()}
                          disabled
                        >
                          Clear All
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="body">
                    {localUserType === "agency_user"
                      ? this.create_filter_elements(
                          non_relational_filter_list("calls"),
                          "non_relational"
                        )
                      : this.create_filter_elements(
                          relational_filter_list("calls"),
                          "relational"
                        )}

                    {/* {localUserType === "territory_manager" ||
                      localUserType === "area_manager" ||
                      (localUserType === "regional_manager" &&
                        this.create_filter_elements(
                          non_relational_filter_list_dash_agency(
                            "dex_dashboard"
                          ),
                          "relational"
                        ))} */}
                  </div>
                </div>
              )}

              <div className="info">
                <Calendar
                  page={"calls"}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                />
              </div>
            </div>
          </div>

          <Pagination
            page={"calls"}
            currentPage={this.props.currentPage}
            total={this.state.total}
            limit={this.props.currentLimit}
          />
          {this.state.data_fetching ? (
            <LoadingCard
            // card_heading={`${item.card_heading}`}
            />
          ) : (
            this.state.calls && (
              <div className="card-row row">
                {this.state.calls.map((item, index) => (
                  <div key={index} className="card-col col l6 s12">
                    <Call
                      call={item}
                      index={index}
                      goToOutlet={() =>
                        this.callBackForOutletRouting(item.outlet__wallet_no)
                      }
                    />
                  </div>
                ))}
              </div>
            )
          )}

          <Pagination
            page={"calls"}
            currentPage={this.props.currentPage}
            total={this.state.total}
            limit={this.props.currentLimit}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let startDate = state.calendar.calls
    ? state.calendar.calls.startDate
    : state.calendar.default.startDate;
  let endDate = state.calendar.calls
    ? state.calendar.calls.endDate
    : state.calendar.default.endDate;
  let currentPage = state.page.calls
    ? state.page.calls.currentPage
    : state.page.default.currentPage;
  let filterElement = state.filterElement.calls
    ? state.filterElement.calls
    : state.filterElement.default;
  let filterHide = state.filter.filterHide;

  let currentLimit = state.paginationLimit.calls
    ? state.paginationLimit.calls.currentLimit
    : 10;

  let expiration = state.auth.authToken
    ? state.auth.authToken.expiration
    : 20000;

  return {
    startDate,
    endDate,
    currentPage,
    filterElement,
    filterHide,
    expiration,
    currentLimit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
    updateFilterHide: (filterHide) => dispatch(updateFilterHide(filterHide)),
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calls);

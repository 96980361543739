import "../styles/call-overview.scss"
import React from "react"
import LoadingCard from "./loadingCard"
import call_overview_img from "./../../../img/dashboard_card/call_overview.svg"
import Chart from "react-apexcharts"
import useSWR from "swr"

import { connect } from "react-redux"

const CallOverview = ({ idNumber, current_month }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/performance/v1/get-call-overview/${idNumber}?month=${current_month}`
  const { data } = useSWR(url)

  return (
    <div className="card-col col s12 m12 l12 ">
      {!data ? (
        <LoadingCard />
      ) : (
        <div className="card call-overview-card">
          <img
            className="img card-corner"
            src={call_overview_img}
            alt="card-corner"
          />
          <div className="card-title">Call Overview</div>
          <div className="card-body">
            <div className="row-1">
              <div className="data-cols total-calls">
                <span className="number">{data.total_call}</span>
                <span className="label">Total Calls</span>
              </div>
              <div className="data-cols calls-per-day">
                <span className="number">{data.avg_call_per_day}</span>
                <span className="label">Calls/Day</span>
              </div>
              <div className="data-cols position">
                <span className="number">
                  {data.rank_diff >= 0
                    ? `+${data.rank_diff}`
                    : `-${data.rank_diff}`}
                </span>
                <span className="label">Position Up From Last Month</span>
              </div>
            </div>
            <div className="row-2 chart">
              <Chart
                options={barChartOptions}
                series={[
                  {
                    name: "Calls/Day",
                    data: data.day_wise_list,
                  },
                ]}
                type="bar"
                height={200}
              />
            </div>
            <div className="row-3">
              <hr className="divider-custom" />
            </div>
            <div className="row-4">
              <div className="donut-container">
                {data.call_overview.new_acquisition_total === 0 &&
                data.call_overview.lead_close_total === 0 &&
                data.call_overview.followup_acquisition_total === 0 &&
                data.call_overview.regular_total === 0 &&
                data.call_overview.activation_total === 0 &&
                data.call_overview.rejection_total === 0 ? null : (
                  <Chart
                    options={pieChartOptions}
                    series={[
                      data.call_overview.new_acquisition_total,
                      data.call_overview.lead_close_total,
                      data.call_overview.followup_acquisition_total,
                      data.call_overview.regular_total,
                      data.call_overview.activation_total,
                      data.call_overview.rejection_total,
                    ]}
                    type="donut"
                    height={220}
                  />
                )}
              </div>
              <div className="labels">
                <div className="col-1">
                  <div className="label">
                    <div className="color-circle"></div>
                    <div className="info"></div>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const dummy = [
  [1, 45],
  [2, 12],
  [3, 44],
  [4, 19],
  [5, 25],
  [6, 41],
  [7, 33],
  [8, 35],
  [9, 11],

  [19, 35],
  [20, 11],
  [21, 10],
  [22, 49],
  [23, 45],
  [24, 12],
  [25, 44],
  [26, 19],
]
// [
//   data.call_overview.new_acquisition_total,
//   data.call_overview.lead_close_total,
//   data.call_overview.followup_acquisition_total,
//   data.call_overview.regular_total,
//   data.call_overview.activation_total,
//   data.call_overview.rejection_total,
// ]
const pieDummy = [0, 0, 0, 32, 0, 0]

const barChartOptions = {
  chart: {
    type: "bar",
    height: "200px",

    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      // columnWidth: "22%",
      endingShape: "rounded",
      borderRadius: 5,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    // categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
    title: {
      text: "This Month",
    },
  },

  fill: {
    opacity: 1,
    colors: ["#E2136E"],
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val
      },
    },
    x: {
      formatter: function (val) {
        return "Day: " + val
      },
    },
  },
}

const pieChartOptions = {
  labels: [
    "New Leads",
    "Leads Closed",
    "Follow-up",
    "Merchants Served",
    "Activation",
    "Validation",
  ],
  legend: {
    show: true,

    position: "right",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    formatter: function (seriesName, opts) {
      return [
        seriesName,
        " - ",
        Math.round(opts.w.globals.seriesPercent[opts.seriesIndex]) + "%",
      ].join("")
    },
    inverseOrder: false,
    width: 300,
    height: 200,

    offsetX: -55,
    offsetY: -15,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 16,
      height: 16,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: undefined,
      radius: 16,
      offsetX: -14,
      offsetY: 3,
    },
    itemMargin: {
      horizontal: 5,
      vertical: 5,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  colors: ["#DD8E9E", "#E2136E", "#B620E0", "#6236FF", "#0066FF", "#00CFFF"],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            show: true,
            // formatter: function (val) {
            //   return console.log(val)
            // },
          },
          value: {
            show: true,
            // formatter: function (val) {
            //   return console.log(val)
            // },
          },
        },
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  chart: {
    type: "donut",
    height: "400px",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 400,
        },
      },
    },
  ],
}

const mapStateToProps = (state) => ({
  current_month: state.leaderboardPage.profile.current_month,
})

export default connect(mapStateToProps)(CallOverview)

import React from "react"
import "./UploadStatusTable.styles.scss"

import LoadingCard from "./loadingCard"
import useSWR from "swr"
import moment from "moment"

const UploadStatusTable = () => {
  const upload_status_api_url = `${process.env.REACT_APP_API_URL}/api/uploads/v1/upload-list?limit=20`
  const { data: upload_status_data, error: upload_status_data_error } = useSWR(
    upload_status_api_url
  )

  return (
    <div className="card-col col s12 l12">
      {!upload_status_data ? (
        <LoadingCard />
      ) : (
        <div className="card upload-status">
          <div className="card-title">Status of uploaded files</div>
          <div className="card-body">
            <table>
              <thead>
                <tr>
                  <th>Upload File</th>
                  <th>Upload File Type</th>
                  <th>Start time</th>
                  <th>End time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {upload_status_data.data.map((data, index) => {
                  return (
                    <Row
                      key={index}
                      fileName={data.file_name}
                      fileTag={data.file_tag}
                      startTime={data.zone_aware_start_time}
                      endTime={data.zone_aware_end_time}
                      status={data.status}
                      isCompleted={data.is_completed}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

const Row = ({
  fileName,
  fileTag,
  startTime,
  endTime,
  status,
  isCompleted,
}) => {
  return (
    <tr>
      <td>{fileName}</td>
      <td>{fileTag}</td>
      <td>{moment(startTime).format("MMMM Do YYYY, h:mm:ss a")}</td>
      <td>
        {endTime === null
          ? " "
          : moment(endTime).format("MMMM Do YYYY, h:mm:ss a")}
      </td>
      <td>
        <span className={`status ${status}`}>{status}</span>
      </td>
    </tr>
  )
}

export default UploadStatusTable

import React, { Component } from "react"
import "../styles/kycSummary.scss"
import PropTypes from "prop-types"

// import axios from "axios";
import http from "../../../utilities/_http"

import moment from "moment"

import LoadingCard from "./loadingCard"

import { query_generate } from "../../../utilities/_query_generate"

const kyc_summary = {
  card_heading: "KYC Summary",
  state: "kyc_summary_state",
  api_link: `api/kyc/v2/summary`,
  conrner_right_top_img: "none",
}

const NumberBlock = ({ color, value, label }) => {
  const styles = {
    fontFamily: "Roboto",
    fontSize: " 24px",
    color: `${color}`,
  }
  return (
    <div className="nb-container">
      <div style={styles}>{value}</div>
      <div className="nb-label">{label}</div>
    </div>
  )
}

/**
 * @description A summary of the KYC flow of the selected date and filter
 * @component KYC Summary
 * @category Dashboard
 * @author Zoha
 */

class KYCSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data_fetching: false,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.date !== prevProps.date ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set()
  }

  set_data_set = () => {
    let query_pre_process = [
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "region",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.region
            : [],
      },
      {
        key: "area",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.area
            : [],
      },
      {
        key: "territory",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.territory
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
    ]
    let query = query_generate(query_pre_process)

    this.setState({
      data_fetching: true,
    })

    http
      .get(
        `${process.env.REACT_APP_API_URL}/${
          kyc_summary["api_link"]
        }?date=${moment(this.props.date).format("YYYY-MM-DD")}${
          query !== "" ? "&" + query : ""
        }`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) =>
        this.setState({
          [`${kyc_summary["state"]}`]: res.data,
          data_fetching: false,
        })
      )
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  render() {
    return (
      <div className="card-col col s12 l12">
        {this.state.data_fetching ? (
          <LoadingCard card_heading={`${kyc_summary.card_heading}`} />
        ) : (
          <div className="card kyc-summary-card">
            <div className="card-title">{kyc_summary.card_heading}</div>
            <div className="card-body">
              {this.state.kyc_summary_state && (
                <table className="kyc-table">
                  <thead className="kyc-table-main-head">
                    <tr className="kyc-table-main-head-tr">
                      <th className="kyc-table-main-head-th blank_th"></th>
                      <th className="kyc-table-main-head-th" colSpan="1">
                        MDO
                      </th>
                      <th className="kyc-table-main-head-th" colSpan="3">
                        Agency QC
                      </th>
                      {/* <th className="kyc-table-main-head-th" colSpan="3">
                        bKash QC
                      </th> */}
                      <th className="kyc-table-main-head-th" colSpan="2">
                        bKash CS
                      </th>
                    </tr>
                  </thead>
                  <tbody className="kyc-table-main-body">
                    <tr className="kyc-table-body-tr">
                      <td className="kyc-table-side-head-td">This Month</td>
                      <td className="kyc-table-value border-right border-left">
                        <NumberBlock
                          color="#00B136"
                          value={this.state.kyc_summary_state[0].lead_closed}
                          label="Leads Closed"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#434364"
                          value={
                            this.state.kyc_summary_state[0].agency_received
                          }
                          label="Received"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#B620E0"
                          value={
                            this.state.kyc_summary_state[0].agency_processed
                          }
                          label="Processed"
                        />
                      </td>
                      <td
                        className="kyc-table-value"
                        style={{ paddingRight: "25px" }}
                      >
                        <NumberBlock
                          color="red"
                          value={this.state.kyc_summary_state[0].agency_passed}
                          label="Passed"
                        />
                      </td>
                      {/* <td className="kyc-table-value border-left">
                        <NumberBlock
                          color="#434364"
                          value={
                            this.state.kyc_summary_state[0].bkash_qc_received
                          }
                          label="Received"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#B620E0"
                          value={
                            this.state.kyc_summary_state[0].bkash_qc_processed
                          }
                          label="Processed"
                        />
                      </td>
                      <td
                        className="kyc-table-value"
                        style={{ paddingRight: "25px" }}
                      >
                        <NumberBlock
                          color="red"
                          value={
                            this.state.kyc_summary_state[0].bkash_qc_passed
                          }
                          label="Passed"
                        />
                      </td> */}
                      <td className="kyc-table-value border-left">
                        <NumberBlock
                          color="red"
                          value={
                            this.state.kyc_summary_state[0].bkash_cs_activated
                          }
                          label="Activated"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#FF9800"
                          value={
                            this.state.kyc_summary_state[0].bkash_cs_rejected
                          }
                          label="Rejected"
                        />
                      </td>
                    </tr>
                    <tr className="kyc-table-body-tr">
                      <td className="kyc-table-side-head-td">Previous Month</td>
                      <td className="kyc-table-value border-right border-left">
                        <NumberBlock
                          color="#00B136"
                          value={this.state.kyc_summary_state[1].lead_closed}
                          label="Leads Closed"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#434364"
                          value={
                            this.state.kyc_summary_state[1].agency_received
                          }
                          label="Received"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#B620E0"
                          value={
                            this.state.kyc_summary_state[1].agency_processed
                          }
                          label="Processed"
                        />
                      </td>
                      <td
                        className="kyc-table-value"
                        style={{ paddingRight: "25px" }}
                      >
                        <NumberBlock
                          color="red"
                          value={this.state.kyc_summary_state[1].agency_passed}
                          label="Passed"
                        />
                      </td>
                      {/* <td className="kyc-table-value border-left">
                        <NumberBlock
                          color="#434364"
                          value={
                            this.state.kyc_summary_state[1].bkash_qc_received
                          }
                          label="Received"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#B620E0"
                          value={
                            this.state.kyc_summary_state[1].bkash_qc_processed
                          }
                          label="Processed"
                        />
                      </td>
                      <td
                        className="kyc-table-value"
                        style={{ paddingRight: "25px" }}
                      >
                        <NumberBlock
                          color="red"
                          value={
                            this.state.kyc_summary_state[1].bkash_qc_passed
                          }
                          label="Passed"
                        />
                      </td> */}
                      <td className="kyc-table-value border-left">
                        <NumberBlock
                          color="red"
                          value={
                            this.state.kyc_summary_state[1].bkash_cs_activated
                          }
                          label="Activated"
                        />
                      </td>
                      <td className="kyc-table-value">
                        <NumberBlock
                          color="#FF9800"
                          value={
                            this.state.kyc_summary_state[1].bkash_cs_rejected
                          }
                          label="Rejected"
                        />
                      </td>
                      {/* <td className="kyc-table-value">
                        <NumberBlock
                          color="#434364"
                          value={
                            this.state.kyc_summary_state
                              .bkash_cs_yet_to_be_processed
                          }
                          label="Yet to be processed"
                        />
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default KYCSummary

KYCSummary.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

import React, { useState } from "react"
import "./styles/servicingPerformance.scss"
import useSWR from "swr"
import moment from "moment"

import link_icon from "../../../img/dashboard_card/open_link.svg"
import LoadingCard from "./loadingCard"

import ServicingModal from "./servicingModal"
// import Axios from "axios";

const servicing_performance = {
  card_heading: "Servicing Performance",
  api_link: `reports/v1/servicing-report`,
}

const ServicingPerformance = ({ date }) => {
  //api-dexter-kam.retaildata.xyz/prod/reports/v1/servicing-report?
  const url = `${
    process.env.REACT_APP_KAM_API_URL
  }reports/v1/servicing-report?date=${moment(date).format("YYYY-MM-DD")}`

  // const url = `${process.env.REACT_APP_KAM_API_URL}${servicing_performance.api_link}?date=2020-11-04`;

  // const url = `${process.env.REACT_APP_KAM_API_URL}${
  //   servicing_performance.api_link
  // }?date=${moment(date).format("YYYY-MM-DD")}`;

  const { data } = useSWR(url)
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   Axios.get(
  //     `https://api-dexter-kam.retaildata.xyz/prod/reports/v1/servicing-report?date=${moment(
  //       date
  //     ).format("YYYY-MM-DD")}`,
  //     {
  //       headers: {
  //         Authorization:
  //           // "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
  //           "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjIxNTk4MzY2LCJqdGkiOiJkMTllNjQxZmM1ZmI0MDdkOWZkM2FjNjAxODA4YzIwZiIsInVzZXJfaWQiOjl9.KZ5Q_sT543z2-qM0rCdggt49UlPrpj_dn6-pwKhXtug",
  //       },
  //     }
  //   )
  //     .then((res) => setData(res.data))
  //     .catch((err) => err);
  // }, []);

  return (
    <div className="card-col col s12 l12">
      {!data ? (
        <LoadingCard card_heading={servicing_performance.card_heading} />
      ) : (
        <div className="card servicing-perf-card">
          <div className="card-title">{servicing_performance.card_heading}</div>
          <div className="card-body">
            <table className="servicing-perf">
              <thead className="servicing-perf-main-head">
                <tr className="servicing-perf-main-head-tr">
                  <th className="name-empty"></th>
                  <th>Total</th>
                  <th>Visit</th>
                  <th>Phone Call</th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="servicing-perf-main-body">
                {data.map((person, i) => (
                  <PeopleRow date={date} person={person} key={i} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

const PeopleRow = ({ person, date }) => {
  const [modal, set_modal] = useState(false)

  return (
    <>
      <tr
        className="servicing-perf-main-body-tr"
        onClick={() => set_modal(true)}
      >
        <td className="name">{person.kam_name}</td>
        <td className="total">{person.total}</td>
        <td className="visit">{person.visit_percentage}%</td>
        <td className="phone-call">{person.call_percentage}%</td>
        <td>
          <div
            className="link-button-container"
            onClick={() => set_modal(true)}
          >
            <img src={link_icon} alt="view all" />
            <span>View Full List</span>
          </div>
        </td>
      </tr>
      <ServicingModal
        show={modal}
        handleHide={() => set_modal(false)}
        person={person}
        date={date}
      />
    </>
  )
}
export default ServicingPerformance

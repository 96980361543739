import React, { Component } from "react"
import axios from "axios"

import "./../../styles/actions/markReceive.css"

class MarkReceive extends Component {
  constructor(props) {
    super(props)

    this.state = {
      action_taken: null,

      loading: false,

      permission: {
        agency_user: [null, "fresh", "kyc_bkash_rejected"],
        bkash_user: ["kyc_agency_approved", "kyc_bkash_cs_rejected"],
        cluster_manager: ["kyc_agency_approved", "kyc_bkash_cs_rejected"],
      },
    }
  }

  render() {
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))
    return (
      <div
        id={this.props.id}
        className="mark-received-button-container"
        key={this.props.id}
      >
        {!this.state.permission[`${localUserType}`].includes(
          this.props.status
        ) ? (
          <button className="action-taken" disabled>
            Received
          </button>
        ) : (
          <button
            key={this.props.id}
            onClick={() => {
              this.setState({
                loading: true,
              })
              localUserType === "agency_user"
                ? this.props.status === "fresh" || this.props.status === null
                  ? axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/api/kyc/v1/create`,
                        {
                          call: this.props.id,
                        },
                        {
                          headers: {
                            Authorization:
                              "Bearer " +
                              JSON.parse(localStorage.getItem("currUserToken")),
                          },
                        }
                      )
                      .then(() =>
                        this.setState({
                          action_taken: "Received",
                        })
                      )
                      .then(() =>
                        this.props.kyc_received_set_data_set === "none"
                          ? null
                          : this.setState({ action_taken: null }, () =>
                              this.props.kyc_received_set_data_set()
                            )
                      )
                  : axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/api/kyc/v1/${this.props.kyc_id}/actions`,
                        {
                          action_type: "kyc_agency_acknowledged",
                        },
                        {
                          headers: {
                            Authorization:
                              "Bearer " +
                              JSON.parse(localStorage.getItem("currUserToken")),
                          },
                        }
                      )
                      .then(() =>
                        this.setState({
                          action_taken: "Received",
                        })
                      )
                      .then(() =>
                        this.props.kyc_received_set_data_set === "none"
                          ? null
                          : this.setState({ action_taken: null }, () =>
                              this.props.kyc_received_set_data_set()
                            )
                      )
                : axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/api/kyc/v1/${this.props.id}/actions`,
                      {
                        action_type: "kyc_bkash_received",
                      },
                      {
                        headers: {
                          Authorization:
                            "Bearer " +
                            JSON.parse(localStorage.getItem("currUserToken")),
                        },
                      }
                    )
                    .then(() =>
                      this.setState({
                        action_taken: "Received",
                      })
                    )
                    .then(() =>
                      this.props.kyc_received_set_data_set === "none"
                        ? null
                        : this.setState({ action_taken: null }, () =>
                            this.props.kyc_received_set_data_set()
                          )
                    )
            }}
            className={
              this.state.loading
                ? "mark-received-button-loading"
                : "mark-received-button"
            }
            disabled={this.state.loading}
          >
            Mark Received
          </button>
        )}
      </div>
    )
  }
}

export default MarkReceive

import React, { useState } from "react"
import "./style.scss"

import useSWR from "swr"
import { connect } from "react-redux"
import axios from "axios"
import moment from "moment"

import Footer from "../Footer/Footer"
import CalendarDateRangePicker from "./components/CalendarDateRangePicker"

import Tabs from "./components/tabs"
import NewServices from "./components/newServices"
import PendingServices from "./components/pendingServices"
import ResolvedServices from "./components/resolvedServices"
import Pagination from "../layout/Pagination"
import Dropdown from "./components/Dropdown"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import Search from "@material-ui/icons/Search"
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import { ThemeProvider } from "@material-ui/core/styles"
import { theme } from "../../utilities/theme"
import grey from "@material-ui/core/colors/grey"
import DownloadReportButton from "./components/DownloadReportButton"
import BounceLoader from "react-spinners/BounceLoader"
import { useNotification } from "../Notifications/NotificationProvider"

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    backgroundColor: "white",
    border: "1px solid #F2F2F2",
    // label: {
    //   top: -6,
    //   left: 0,
    // },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const KAMServicing = ({
  date,
  dateRaw,
  active_table,
  currentNewServicesLimit,
  currentNewServicesPage,
  currentPendingServicesLimit,
  currentPendingServicesPage,
  currentResolvedServicesLimit,
  currentResolvedServicesPage,
}) => {
  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const classes = useStyles()
  let [color, setColor] = useState("#e2136e")
  const [loadingReport, setLoadingReport] = useState(false)
  const dispatchNotification = useNotification()

  const handleChange = (event) => {
    setFilter(event.target.value)
  }

  const getDate = () => {
    if (dateRaw.servicing) {
      if (Array.isArray(date)) {
        return `?start_time=${moment(date[0]).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date[1]).format("YYYY-MM-DD")}`
      } else {
        // console.log("date not array");
        return `?start_time=${moment(date).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date).format("YYYY-MM-DD")}`
      }
    } else return ""
  }

  const getBulkReport = () => {
    setLoadingReport(true)

    const requestBody = {
      category: filter.category,
      issue: filter.feedback,
      bulk: true,
      services: [],
    }

    axios
      .post(
        `${process.env.REACT_APP_KAM_API_URL}services/v1/generate-eml-report`,
        requestBody,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        dispatchNotification({
          type: "SUCCESS",
          message: "Report fetched successfully",
        })
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `${filter.feedback}_${
            Array.isArray(date)
              ? `${moment(date[0]).format("YYYY-MM-DD")}_${moment(
                  date[1]
                ).format("YYYY-MM-DD")}`
              : moment(date).format("YYYY-MM-DD")
          }_bulk.eml`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()

        setLoadingReport(false)
      })
      .catch((err) => {
        dispatchNotification({
          type: "ERROR",
          message: "Could not fetch report",
        })
        setLoadingReport(false)
      })
  }

  let newUrl = `${process.env.REACT_APP_KAM_API_URL}services/v1/feedback-list`

  const url = `${
    process.env.REACT_APP_KAM_API_URL
  }services/v1/feedbacks${getDate()}`

  const { data: issueList } = useSWR(newUrl)
  const { data: totalCountData } = useSWR(url)

  return (
    <div className="main-container">
      <div className="kam-servicing-container">
        <div className="page-header">
          <div className="text-container">
            Servicing
            <div className="page-sub-heading">
              {date.length > 0
                ? `${moment(date[0]).format("ddd, MMM DD")} to ${moment(
                    date[1]
                  ).format("ddd, MMM DD")}`
                : moment(date).format("ddd, MMM DD")}
              {/* {moment(date).format("ddd, MMM DD")} */}
              {/* Showing Today's Data Only */}
            </div>
          </div>

          <div className="page-tabs">
            <Tabs />
          </div>
        </div>

        <div className="divider" />

        <div className="page-options">
          <div className="filter-by-issue">
            <ThemeProvider theme={theme}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">
                  Filter by Issue
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={filter.feedback}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {issueList
                    ? issueList.map((issue, i) => (
                        <MenuItem key={i} value={issue}>
                          {issue.feedback}
                        </MenuItem>
                      ))
                    : null}
                  {/* <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl className={`searchbox ${classes.margin}`}>
                <OutlinedInput
                  id="input-with-icon-adornment"
                  onChange={(e) => setSearch(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search style={{ color: grey[400] }} />
                    </InputAdornment>
                  }
                  placeholder="KAM or Merchant Name ..."
                />
              </FormControl>
            </ThemeProvider>
          </div>
          {filter !== "" && active_table !== "new" && (
            <DownloadReportButton
              onClick={getBulkReport}
              disabled={loadingReport}
            >
              <BounceLoader
                color={color}
                loading={loadingReport}
                css={override}
                size={16}
              />
              Bulk Download
            </DownloadReportButton>
          )}

          <CalendarDateRangePicker page={"servicing"} date={date} />
        </div>

        <div className="card-row row">
          {!totalCountData ? null : (
            <Pagination
              page={
                active_table === "new"
                  ? `newservices`
                  : active_table === "pending"
                  ? `pendingservices`
                  : `resolvedservices`
              }
              currentPage={
                active_table === "new"
                  ? currentNewServicesPage
                  : active_table === "pending"
                  ? currentPendingServicesPage
                  : currentResolvedServicesPage
              }
              total={
                active_table === "new"
                  ? totalCountData.new_count
                  : active_table === "pending"
                  ? totalCountData.pending_count
                  : active_table === "resolved"
                  ? totalCountData.resolved_count
                  : ""
              }
              limit={
                active_table === "new"
                  ? currentNewServicesLimit
                  : active_table === "pending"
                  ? currentPendingServicesLimit
                  : currentResolvedServicesLimit
              }
            />
          )}
          {active_table === "new" && (
            <NewServices
              issue={filter}
              search={search}
              pageLimit={currentNewServicesLimit}
              currentPage={currentNewServicesPage}
            />
          )}
          {active_table === "pending" && (
            <PendingServices
              issue={filter}
              search={search}
              pageLimit={currentPendingServicesLimit}
              currentPage={currentPendingServicesPage}
            />
          )}
          {active_table === "resolved" && (
            <ResolvedServices
              issue={filter}
              search={search}
              pageLimit={currentResolvedServicesLimit}
              currentPage={currentResolvedServicesPage}
            />
          )}

          {!totalCountData ? null : (
            <Pagination
              page={
                active_table === "new"
                  ? `newservices`
                  : active_table === "pending"
                  ? `pendingservices`
                  : `resolvedservices`
              }
              currentPage={
                active_table === "new"
                  ? currentNewServicesPage
                  : active_table === "pending"
                  ? currentPendingServicesPage
                  : currentResolvedServicesPage
              }
              total={
                active_table === "new"
                  ? totalCountData.new_count
                  : active_table === "pending"
                  ? totalCountData.pending_count
                  : active_table === "resolved"
                  ? totalCountData.resolved_count
                  : ""
              }
              limit={
                active_table === "new"
                  ? currentNewServicesLimit
                  : active_table === "pending"
                  ? currentPendingServicesLimit
                  : currentResolvedServicesLimit
              }
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => {
  let dateRaw = state.calendarDatePicker

  let date = state.calendarDatePicker.servicing
    ? state.calendarDatePicker.servicing.date
    : state.calendarDatePicker.default.date

  let active_table = state.servicingTable.active_table

  let currentNewServicesLimit = state.paginationLimit.newservices
    ? state.paginationLimit.newservices.currentLimit
    : state.paginationLimit.default.currentLimit

  let currentNewServicesPage = state.page.newservices
    ? state.page.newservices.currentPage
    : state.page.default.currentPage

  let currentPendingServicesLimit = state.paginationLimit.pendingservices
    ? state.paginationLimit.pendingservices.currentLimit
    : state.paginationLimit.default.currentLimit

  let currentPendingServicesPage = state.page.pendingservices
    ? state.page.pendingservices.currentPage
    : state.page.default.currentPage

  let currentResolvedServicesLimit = state.paginationLimit.resolvedservices
    ? state.paginationLimit.resolvedservices.currentLimit
    : state.paginationLimit.default.currentLimit

  let currentResolvedServicesPage = state.page.resolvedservices
    ? state.page.resolvedservices.currentPage
    : state.page.default.currentPage

  return {
    date,
    dateRaw,
    active_table,
    currentNewServicesLimit,
    currentNewServicesPage,
    currentPendingServicesLimit,
    currentPendingServicesPage,
    currentResolvedServicesLimit,
    currentResolvedServicesPage,
  }
}

export default connect(mapStateToProps)(KAMServicing)

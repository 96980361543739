import React, { Component } from "react"
import axios from "axios"

import "./../../styles/actions/sendBkashMDO.css"

class sendBkashMDO extends Component {
  constructor(props) {
    super(props)

    this.state = {
      action_taken: null,

      loading: false,

      status: {
        agency_user: {
          send_bkash: {
            api: "api/kyc/v1/",
            query: undefined,
            body: { action_type: "kyc_agency_approved" },
          },
          send_mdo: {
            api: "api/kyc/v1/",
            query: undefined,
            body: { action_type: "kyc_agency_acknowledged" },
          },
        },
      },

      permission: {
        agency_user: ["kyc_received"],
      },
    }
  }

  update_action_taken = (action_taken) =>
    this.setState({ action_taken: action_taken })

  render() {
    return (
      <div className={`${this.props.classNamePrefix}-send-bkash-mdo-container`}>
        <button
          onClick={() => {
            this.setState({
              loading: true,
            })

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/kyc/v1/${this.props.kyc_id}/actions`,
                {
                  action_type: "kyc_agency_approved",
                },
                {
                  headers: {
                    Authorization:
                      "Bearer " +
                      JSON.parse(localStorage.getItem("currUserToken")),
                  },
                }
              )
              .then(() =>
                this.setState({
                  action_taken: "Received",
                  loading: false,
                })
              )
              .then(() =>
                this.props.pending_processing_set_data_set === "none"
                  ? null
                  : this.setState({ action_taken: null }, () =>
                      this.props.pending_processing_set_data_set()
                    )
              )
              .catch(() =>
                this.setState({
                  loading: false,
                })
              )
          }}
          className={
            this.state.loading
              ? `${this.props.classNamePrefix}-send-loading`
              : `${this.props.classNamePrefix}-send-bkash`
          }
          disabled={this.state.loading}
        >
          Send to Bkash
        </button>
        <button
          onClick={() => {
            this.setState({
              loading: true,
            })

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/kyc/v1/${this.props.kyc_id}/actions`,
                {
                  action_type: "kyc_agency_acknowledged",
                },
                {
                  headers: {
                    Authorization:
                      "Bearer " +
                      JSON.parse(localStorage.getItem("currUserToken")),
                  },
                }
              )
              .then(() =>
                this.setState({
                  action_taken: "Received",
                  loading: false,
                })
              )
              .then(() =>
                this.props.pending_processing_set_data_set === "none"
                  ? null
                  : this.setState({ action_taken: null }, () =>
                      this.props.pending_processing_set_data_set()
                    )
              )
              .catch(() =>
                this.setState({
                  loading: false,
                })
              )
          }}
          className={
            this.state.loading
              ? `${this.props.classNamePrefix}-send-loading`
              : `${this.props.classNamePrefix}-send-mdo`
          }
          disabled={this.state.loading}
        >
          Send to MDO
        </button>
      </div>
    )
  }
}

export default sendBkashMDO

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
        <Checkbox
          ref={resolvedRef}
          {...rest}
          inputProps={{ "aria-label": "indeterminate checkbox" }}
        />
      </>
    );
  }
);

export default IndeterminateCheckbox;

import React from "react"
import moment from "moment"
import LoadingCard from "./loadingCard"
import call_overview_img from "../../../img/dashboard_card/call_overview.svg"
import "./styles/callOverviewCard.scss"
import useSWR from "swr"

const call_overview = {
  card_heading: "Call Overview",
  state: "call_overview_state",
  api_link: `api/reports/v2/call-type-wise-report`,
  conrner_right_top_img: call_overview_img,
}

const CallOverviewCard = ({ date }) => {
  const url = `${
    process.env.REACT_APP_KAM_API_URL
  }calls/v1/call-type-wise-report?date=${moment(date).format("YYYY-MM-DD")}`

  // const url = `${
  //   process.env.REACT_APP_KAM_API_URL
  // }calls/v1/call-type-wise-report?date=${moment(date).format("YYYY-MM-DD")}`;

  // const url = `${process.env.REACT_APP_KAM_API_URL}calls/v1/call-type-wise-report?date=2020-11-04`;

  const { data } = useSWR(url)

  return (
    <div className="card-col col s12 m6 l4 ">
      {!data ? (
        <LoadingCard card_heading={`${call_overview.card_heading}`} />
      ) : (
        <div className="card kam-call-overview-card">
          {call_overview.conrner_right_top_img !== "none" ? (
            <img
              className="img card-corner"
              src={call_overview.conrner_right_top_img}
              alt="card-corner"
            />
          ) : null}
          <div className="card-title">{call_overview.card_heading}</div>
          {data && (
            <div className="card-body">
              <div className="new-leads">
                <div className="value">{data.new_leads}</div>
                <div className="tag">New Leads</div>
              </div>

              <div className="lead-closed">
                <div className="value">{data.close_leads}</div>
                <div className="tag">Leads Closed</div>
              </div>

              <div className="followed-up">
                <div className="value">{data.follow_up_leads}</div>
                <div className="tag">Leads Followed-up</div>
              </div>

              <div className="merchant-served">
                <div className="value">{data.merchant_served}</div>
                <div className="tag">Existing Merchant</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CallOverviewCard

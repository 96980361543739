import React, { useState } from "react"
import "./styles/activeStatusCard.scss"
import useSwr from "swr"

import active_status_card from "../../../img/dashboard_card/active_status_card.svg"
import link_icon from "../../../img/dashboard_card/open_link.svg"

import LoadingCard from "./loadingCard"
import ActiveStatusModal from "./activeStatusModal"

const active_status = {
  card_heading: "Active Status",
  state: "call_overview_state",
  api_link: `api/reports/v2/call-type-wise-report`,
  conrner_right_top_img: active_status_card,
}

const ActiveStatusCard = () => {
  const [modal, set_modal] = useState(false)
  const url = `${process.env.REACT_APP_KAM_API_URL}users/v1/get-active-status`
  const { data } = useSwr(url)
  // console.log(data);

  return (
    <div className="card-col col s12 m6 l4 ">
      {!data ? (
        <LoadingCard />
      ) : (
        <div className="card active-status-card">
          {active_status.conrner_right_top_img !== "none" ? (
            <img
              className="img card-corner"
              src={active_status.conrner_right_top_img}
              alt="card-corner"
            />
          ) : null}
          <div className="card-title">{active_status.card_heading}</div>

          <div className="card-body">
            <div className="people-list-container">
              {data.slice(0, 3).map((person, i) => (
                <PeopleActive
                  key={person.whitelisting_number}
                  name={person.name}
                  status={person.active_time}
                />
              ))}
            </div>
          </div>
          <div
            className="link-button-container"
            onClick={() => set_modal(true)}
          >
            <img src={link_icon} alt="view all" />
            <span>View Full List</span>
          </div>
          <ActiveStatusModal
            show={modal}
            handleHide={() => set_modal(false)}
            data={data}
          />
        </div>
      )}
    </div>
  )
}

const PeopleActive = ({ name, status }) => (
  <div className="people">
    <div className="name">
      <span>{name}</span>
    </div>
    <div className="status">
      <span>{status}</span>
    </div>
  </div>
)

export default ActiveStatusCard

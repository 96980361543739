export const changeTable = (table) => ({
  type: "CHANGE_LEADERBOARD_TABLE",
  payload: table,
})

export const changeTableFilter = (table) => ({
  type: "CHANGE_LEADERBOARD_TABLE_FILTER",
  payload: table,
})

export const setSearch = (search) => ({
  type: "SET_LEADERBOARD_SEARCH",
  payload: search,
})

export const setPastMonths = (months) => ({
  type: "SET_PROFILE_PAST_MONTHS",
  payload: months,
})

export const setCurrentMonth = (month) => ({
  type: "SET_PROFILE_CURRENT_MONTH",
  payload: month,
})

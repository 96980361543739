import React from "react"
import "./styles/kamPerformanceCard.scss"
// import axios from "axios";
import moment from "moment"
import useSWR from "swr"

import LoadingCard from "./loadingCard"

import inc_img from "./../../../img/dashboard_card/inc_img.svg"
import dec_img from "./../../../img/dashboard_card/dec_img.svg"
import kam_performance_img from "./../../../img/dashboard_card/MDO_performance.svg"

const kam_performance = {
  card_heading: "KAM Performance",
  state: "kam_performance_state",
  api_link: `api/reports/v2/mdo-performance-report`,
  inc_img: inc_img,
  dec_img: dec_img,
  conrner_right_top_img: kam_performance_img,
}

const KAMPerformanceCard = ({ date }) => {
  const url = `${
    process.env.REACT_APP_KAM_API_URL
  }users/v1/kam-performance-report?date=
  ${moment(date).format("YYYY-MM-DD")}`

  // const url = `${
  //   process.env.REACT_APP_KAM_API_URL
  // }users/v1/kam-performance-report?date=${moment(date).format("YYYY-MM-DD")}`;

  const { data } = useSWR(url)

  return (
    <div className="card-col col s12 m6 l4 ">
      {!data ? (
        <LoadingCard card_heading={`${kam_performance.card_heading}`} />
      ) : (
        <div className="card kam-perf-card">
          {kam_performance.conrner_right_top_img !== "none" ? (
            <img
              className="img card-corner"
              src={kam_performance.conrner_right_top_img}
              alt="card-corner"
            />
          ) : null}
          <div className="card-title">{kam_performance.card_heading}</div>
          {data && (
            <div className="card-body">
              <div className="total-calls">
                <div className="value">{data.total_call}</div>
                <div className="tag">Total Calls</div>
              </div>

              <div className="comparison">
                <div className="value">
                  {data.deltas.with_last_week > 0 ? (
                    <img src={kam_performance.inc_img} alt="inc" />
                  ) : data.deltas.with_last_week < 0 ? (
                    <img src={kam_performance.dec_img} alt="dec" />
                  ) : null}{" "}
                  {data.deltas.with_last_week > 0
                    ? data.deltas.with_last_week
                    : data.deltas.with_last_week * -1}
                  %
                </div>
                <div className="tag">
                  {data.deltas.with_last_week > 0 ? `More` : "Less"} Than Last
                  Week
                </div>
              </div>

              <div className="active-mdo">
                <div className="value">{data.calls_per_kam}</div>
                <div className="tag">Calls/Active KAM</div>
              </div>

              <div className="comparison">
                <div className="value">
                  {data.deltas.with_last_week > 0 ? (
                    <img src={kam_performance.inc_img} alt="inc" />
                  ) : data.deltas.with_last_week < 0 ? (
                    <img src={kam_performance.dec_img} alt="dec" />
                  ) : null}{" "}
                  {data.deltas.with_last_week > 0
                    ? data.deltas.with_last_week
                    : data.deltas.with_last_week * -1}
                  %
                </div>
                <div className="tag">
                  {data.deltas.with_last_week > 0 ? `More` : "Less"} Than Last
                  Week
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default KAMPerformanceCard

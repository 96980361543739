import React from "react"
import moment from "moment"
import "./styles/EmailModal.scss"
import EscapeOutside from "react-escape-outside"

import close_green_corner from "../../../img/dashboard_card/close_green_corner.svg"
import close_button from "../../../img/dashboard_card/close_button.svg"

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <EscapeOutside onEscapeOutside={handleClose}>
        <div className="wrapper-modal">
          <section className="modal-main">{children}</section>
        </div>
      </EscapeOutside>
    </div>
  )
}

const EmailModal = ({ handleHide, show, data, modalType }) => {
  return (
    <section className="email-modal-section">
      {/* <h3 className="ex-title">Modal Type: 1</h3> */}

      <Modal show={show} handleClose={handleHide}>
        <div className="modal-header">
          <div className="headline">
            <div className="main-headline">
              {modalType === "email"
                ? `Email`
                : modalType === "comment"
                ? `Comment`
                : modalType === "image"
                ? `Images`
                : `Date Range`}
            </div>

            {modalType === "email" ? (
              <div className="sub-headline">Email Address of the Recepient</div>
            ) : modalType === "comment" ? (
              <div className="sub-headline">Comments to the Recepient</div>
            ) : modalType === "image" ? (
              <div className="sub-headline">
                Images Available to View and Download
              </div>
            ) : (
              <div className="sub-headline">
                Start date and end date selected
              </div>
            )}
          </div>
          <div className="close-button-corner" onClick={handleHide}>
            <img className="corner" src={close_green_corner} alt="close" />
            <img className="cross" src={close_button} alt="close" />
          </div>
        </div>
        <div className="modal-body">
          <div className="basic">
            {modalType === "image" ? (
              Array.isArray(data) ? (
                data.map((link, i) => (
                  <div className="link" key={i}>
                    <a href={link} target="_blank">{`Image ${i + 1}`}</a>
                  </div>
                ))
              ) : (
                <a href={data} target="_blank">{`Image 1`}</a>
              )
            ) : modalType === "daterange" ? (
              <div className="sed">
                <span>
                  <strong>Start Date: </strong>{" "}
                  {moment(data.sdate).format("Do MMM YYYY")}
                </span>
                <br />
                <span>
                  <strong>End Date: </strong>{" "}
                  {moment(data.edate).format("Do MMM YYYY")}
                </span>
              </div>
            ) : (
              data
            )}
          </div>
        </div>
      </Modal>
    </section>
  )
}

export default EmailModal

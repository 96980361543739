import React from "react";
import "./styles/activeStatusModal.scss";
import EscapeOutside from "react-escape-outside";

import close_green_corner from "../../../img/dashboard_card/close_green_corner.svg";
import close_button from "../../../img/dashboard_card/close_button.svg";

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <EscapeOutside onEscapeOutside={handleClose}>
        <div className="wrapper-modal">
          <section className="modal-main">{children}</section>
        </div>
      </EscapeOutside>
    </div>
  );
};

const ActiveStatusModal = ({ handleHide, show, data }) => {
  return (
    <section className="mmodal-section">
      {/* <h3 className="ex-title">Modal Type: 1</h3> */}

      <Modal show={show} handleClose={handleHide}>
        <div className="modal-header">
          <div className="headline">
            <div className="main-headline">Active Status</div>
            <div className="sub-headline">Last Active Status of KAM's</div>
          </div>
          <div className="close-button-corner" onClick={handleHide}>
            <img className="corner" src={close_green_corner} alt="close" />
            <img className="cross" src={close_button} alt="close" />
          </div>
        </div>
        <div className="modal-body">
          <div className="basic">
            {data.map((person, i) => (
              <div className="people" key={i}>
                <div className="name">
                  <span>{person.name}</span>
                </div>
                <div className="status">
                  <span>{person.active_time}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default ActiveStatusModal;

import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#e2136e",
      light: "#ebc7d7",
      dark: "#8b1146",
    },

    info: {
      main: "#e2136e",
      light: "#ebc7d7",
      dark: "#8b1146",
    },
  },
});

import axios from "axios"
import JwtDecode from "jwt-decode"
// import jwt_decode from "jwt-decode";

export const signIn = (credentials, rtoken) => {
  return (dispatch, getState) => {
    const verifyCaptcha = axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/verify-captcha`,
      {
        rtoken: rtoken,
      }
    )

    const user_token = axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/token`,
      {
        username: credentials.username,
        password: credentials.password,
      }
    )

    dispatch({
      type: "LOGGING_IN",
    })
    axios
      .all([verifyCaptcha, user_token])
      .then(
        axios.spread((...responses) => {
          const token = responses[1].data.access
          const refresh = responses[1].data.refresh
          const portal = responses[1].data.portal

          console.log(responses[0])

          if (responses[0].data.success === true) {
            localStorage.setItem("currUserToken", JSON.stringify(token))
            localStorage.setItem("accessRefreshToken", JSON.stringify(refresh))
            localStorage.setItem("portal", JSON.stringify(portal))

            if (portal === "dexter") {
              axios
                .get(`${process.env.REACT_APP_API_URL}/api/users/api/v1/me`, {
                  headers: { Authorization: "Bearer " + token },
                })
                .then((res) => {
                  localStorage.setItem(
                    "currUserType",
                    JSON.stringify(res.data.user_type)
                  )

                  const type = JSON.parse(localStorage.getItem("currUserType"))
                  const expiration = JwtDecode(token).exp
                  const status = res.status
                  const message = res.data

                  dispatch({
                    type: "LOGIN_SUCCESS",
                    auth: {
                      token,
                      refresh,
                      expiration,
                      type,
                      portal,
                      status,
                      message,
                    },
                  })
                })
                .catch((err) => {
                  dispatch({
                    type: "LOGIN_ERROR",
                    err: {
                      status: err.status,
                      message: err.response,
                    },
                  })
                  localStorage.removeItem("currUserToken")
                  localStorage.removeItem("accessRefreshToken")
                  localStorage.removeItem("portal")
                })
            }

            if (portal === "kam") {
              axios
                .get(`${process.env.REACT_APP_KAM_API_URL}users/v1/me`, {
                  headers: { Authorization: "Bearer " + token },
                })
                .then((res) => {
                  localStorage.setItem(
                    "currUserType",
                    JSON.stringify(res.data.user_type)
                  )
                  const type = JSON.parse(localStorage.getItem("currUserType"))
                  const expiration = JwtDecode(token).exp
                  const status = res.status
                  const message = res.data

                  dispatch({
                    type: "LOGIN_SUCCESS",
                    auth: {
                      token,
                      refresh,
                      expiration,
                      type,
                      portal,
                      status,
                      message,
                    },
                  })
                })
                // .then((res) => {})
                .catch((err) => {
                  dispatch({
                    type: "LOGIN_ERROR",
                    err: {
                      status: err.status,
                      message: err.detail,
                    },
                  })
                  localStorage.removeItem("currUserToken")
                  localStorage.removeItem("accessRefreshToken")
                  localStorage.removeItem("portal")
                })
            }
          } else {
            dispatch({
              type: "LOGIN_ERROR",
              err: {
                status: "RCX",
                message: "Captcha not passed",
                freeze: null,
              },
            })
            localStorage.removeItem("currUserToken")
            localStorage.removeItem("accessRefreshToken")
            localStorage.removeItem("portal")
          }
        })
      )
      .catch((err) => {
        const regex = /\d+/g
        let message = ""
        let matches = null

        if (err.response.status.toString() === "429") {
          message = err.response.data.detail
          matches = message.match(regex)
        } else {
          message = err.response.data.message
        }

        dispatch({
          type: "LOGIN_ERROR",
          err: {
            status: err.response.status,
            message,
            freeze: matches,
          },
        })
        localStorage.removeItem("currUserToken")
        localStorage.removeItem("accessRefreshToken")
        localStorage.removeItem("portal")
      })
  }
}

export const signOut = () => {
  return (dispatch, getState) => {
    let promise = new Promise((resolve, reject) => {
      resolve(localStorage.removeItem("currUserToken"))
    })
    promise.then(() => window.location.reload())

    dispatch({ type: "SIGNOUT_SUCCESS" })
  }
}

import React, { Component } from "react"
import ReactModal from "react-modal"
// import axios from 'axios'
import http from "../../../utilities/_http"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import moment from "moment"

import { string_uppercase } from "../../../utilities/_string_uppercase"
import CallLocation from "./callLocation"

import outlet_img from "./../../../img/calls_card/outlet.svg"
import mdo_img from "./../../../img/calls_card/mdo.svg"
import bKash_img from "./../../../img/calls_card/bKash.svg"
import phone_img from "./../../../img/calls_card/phone.svg"
import event_img from "./../../../img/calls_card/event.svg"
import bmcc_img from "./../../../img/calls_card/bmcc.svg"
import spot_img from "./../../../img/calls_card/spot.svg"
import biponon_img from "./../../../img/calls_card/biponon.svg"
import market_img from "./../../../img/calls_card/market.svg"
// import posm_img from "./../../../img/calls_card/posm.svg";
import qr_img from "./../../../img/calls_card/qr.svg"
import outlet_type_img from "./../../../img/calls_card/outlet_type.svg"
// import upload_img from "./../../../img/calls_card/upload.svg";
import address_img from "./../../../img/calls_card/address.svg"

import call_card_bottom_corner_img from "./../../../img/calls_card/call_card_bottom_corner.svg"

import "../styles/callModal.css"

export default class CallModal extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      call_detail_state: null,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModal() {
    http
      .get(
        `${process.env.REACT_APP_KAM_API_URL}calls/v1/${this.props.call_id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
            // "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjIxNTk4MzY2LCJqdGkiOiJkMTllNjQxZmM1ZmI0MDdkOWZkM2FjNjAxODA4YzIwZiIsInVzZXJfaWQiOjl9.KZ5Q_sT543z2-qM0rCdggt49UlPrpj_dn6-pwKhXtug",
          },
        }
      )
      .then((res) =>
        this.setState({
          call_detail_state: res.data,
        })
      )
      .catch((err) => err)
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <div className="modal-container call-modal-container">
        <button className="open-modal-button" onClick={this.handleOpenModal}>
          View More Details
        </button>
        <img src={call_card_bottom_corner_img} alt="bottom corner" />
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="call-modal"
        >
          <img
            src={this.props.corner_image}
            alt="right-top"
            className="modal-corner"
          />

          <div className="row header">
            <div className="col s12 l12">
              <img src={this.props.call_outcome_image} alt="outlet" />

              <div className="call-outcome">
                Call Outcome
                <span
                  className={
                    this.props.call_outcome.toUpperCase() ===
                    "EXISTING MERCHANT SERVED"
                      ? "existing_merchant_served_info"
                      : this.props.call_outcome.toUpperCase() ===
                        "LEAD GENERATED"
                      ? "lead_generated_info"
                      : this.props.call_outcome.toUpperCase() ===
                        "LEAD FOLLOWED-UP"
                      ? "lead_generated_info"
                      : "lead_closed_info"
                  }
                >
                  {string_uppercase(this.props.call_outcome) || "..."}
                </span>
              </div>
            </div>
          </div>

          {this.state.call_detail_state && (
            <div className="row">
              <div className="col l6 s12">
                <Zoom>
                  <div className="outlet-image-container">
                    <img
                      className="outlet-image"
                      src={this.state.call_detail_state.outlet_image}
                      alt="outlet"
                    />
                  </div>
                </Zoom>
              </div>
              <div className="col l6 s12">
                <CallLocation
                  location={[
                    this.state.call_detail_state.outlet__lat,
                    this.state.call_detail_state.outlet__lng,
                  ]}
                />
              </div>
            </div>
          )}

          {this.state.call_detail_state && (
            <div className="row">
              <div className="col l6 s12">
                <div className="call-modal-info-header">Outlet Info</div>

                <div className="call-info-container">
                  <img src={outlet_img} alt="outlet img" />
                  <div className="call-info name">
                    Outlet Name
                    <span>
                      {string_uppercase(
                        this.state.call_detail_state.outlet__brand_name
                      ) || "..."}
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={bKash_img} alt="bKash img" />
                  <div className="call-info">
                    Outlet's bKash Number
                    <span>
                      {this.state.call_detail_state.outlet__wallet_no || "..."}
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={outlet_type_img} alt="outlet img" />
                  <div className="call-info">
                    Outlet Type
                    <span
                      className={
                        // this.state.call_detail_state.outlet_type.toUpperCase() ===
                        // "MICRO MERCHANT"
                        //   ? "blue-type"
                        //   : this.state.call_detail_state.outlet_type.toUpperCase() ===
                        //     "MERCHANT PLUS LITE B"
                        //   ? "navy-blue-type"
                        //   : this.state.call_detail_state.outlet_type.toUpperCase() ===
                        //     "MERCHANT PLUS LITE A"
                        //   ? "purpple-type"
                        //   : this.state.call_detail_state.outlet_type.toUpperCase() ===
                        //     "MEDIUM MERCHANT"
                        //   ? "olive-type"
                        //   : "green-type"
                        "green-type"
                      }
                    >
                      {/* {string_uppercase(
                        this.state.call_detail_state.outlet_type
                      )} */}
                      Merchant Plus
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={market_img} alt="outlet img" />
                  <div className="call-info">
                    Branch Name
                    <span>
                      {string_uppercase(
                        this.state.call_detail_state.outlet__branch_name
                      ) || "..."}
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={address_img} alt="outlet img" />
                  <div className="call-info">
                    Address
                    <span>
                      {string_uppercase(
                        this.state.call_detail_state.outlet__address
                      )}
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={biponon_img} alt="event img" />
                  <div className="call-info">
                    Merchant Type
                    <span>
                      {/* {string_uppercase(
                        this.state.call_detail_state.outlet_type
                      )} */}
                      Merchant Plus
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={spot_img} alt="spot img" />
                  <div className="call-info">
                    Cluster Code
                    <span>
                      {this.state.call_detail_state
                        .outlet__cluster_code__code || "..."}
                    </span>
                    <span className="code-description">
                      {this.state.call_detail_state
                        .outlet__cluster_code__name || "..."}
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={bmcc_img} alt="bmcc img" />
                  <div className="call-info">
                    BMCC
                    <span>
                      {this.state.call_detail_state.outlet__bmcc__code || "..."}
                    </span>
                    <span className="code-description bmcc-code-description">
                      {this.state.call_detail_state.outlet__bmcc__name || "..."}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col l6 s12">
                <div className="call-modal-info-header">MDO Info</div>

                <div className="call-info-container">
                  <img src={mdo_img} alt="mdo img" />
                  <div className="call-info name">
                    Assigned KAM's Name
                    <span>
                      {string_uppercase(
                        this.state.call_detail_state.outlet__assigned_kam__name
                      )}
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={phone_img} alt="phone img" />
                  <div className="call-info">
                    KAM's phone Number
                    <span>
                      {
                        this.state.call_detail_state
                          .outlet__assigned_kam__whitelisting_number
                      }
                    </span>
                  </div>
                </div>

                <div className="call-info-container">
                  <img src={event_img} alt="event img" />
                  <div className="call-info">
                    Call Date & Time
                    <span>
                      {moment(this.state.call_detail_state.created).format(
                        "llll"
                      )}
                    </span>
                  </div>
                </div>

                {/* <div className="call-info-container">
                  <img src={upload_img} alt="address img" />
                  <div className="call-info">
                    Upload Date & Time
                    <span>
                      {moment(this.state.call_detail_state.upload_time).format(
                        "llll"
                      )}
                    </span>
                  </div>
                </div> */}
                <div className="call-modal-info-header">
                  QR & POSM Info
                  <div className="call-info-container">
                    <img src={qr_img} alt="mdo img" />
                    <div className="call-info">
                      QR Code Availability
                      <span>
                        {this.state.call_detail_state.outlet__qr_present
                          ? `Yes`
                          : `No`}
                      </span>
                    </div>
                  </div>
                </div>
                {/* {this.state.call_detail_state.feedbacks.length > 0 ? (
                  <>
                    <div className="call-modal-info-header">QR & POSM Info</div>

                    <div className="call-info-container">
                      <img src={qr_img} alt="mdo img" />
                      <div className="call-info name">
                        QR Code Availability
                        <span>
                          {this.state.call_detail_state.feedbacks[0] &&
                          this.state.call_detail_state.feedbacks[0].question ===
                            "QR কোডটি কি সবসময় ডিসপ্লেতে থাকে?"
                            ? string_uppercase(
                                this.state.call_detail_state.feedbacks[0].answer
                              )
                            : this.state.call_detail_state.feedbacks[1]
                            ? string_uppercase(
                                this.state.call_detail_state.feedbacks[1].answer
                              )
                            : "..."}
                        </span>
                      </div>
                    </div>

                    <div className="call-info-container">
                      <img src={posm_img} alt="phone img" />
                      <div className="call-info">
                        POSM Availability
                        <span>
                          {this.state.call_detail_state.feedbacks[1] &&
                          this.state.call_detail_state.feedbacks[1].question ===
                            "মার্চেন্ট পয়েন্টে QR কোড বাদে অন্য কোন POSM আছে?"
                            ? string_uppercase(
                                this.state.call_detail_state.feedbacks[1].answer
                              )
                            : this.state.call_detail_state.feedbacks[0]
                            ? string_uppercase(
                                this.state.call_detail_state.feedbacks[0].answer
                              )
                            : "..."}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null} */}
              </div>
            </div>
          )}
        </ReactModal>
      </div>
    )
  }
}

const initState = {
  authError: null,
  authToken: null,
  logging_in: false,
  authResponseMessage: null,
  authStatusCode: null,
  authFreeze: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: "LOGIN_FAILD",
        logging_in: false,
        authResponseMessage: action.err.message,
        authStatusCode: action.err.status,
        authFreeze: action.err.freeze,
      }
    case "LOGGING_IN":
      return {
        ...state,
        // authToken: action.auth,
        // authError: null,
        logging_in: true,
      }
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authToken: action.auth,
        authError: null,
        logging_in: false,
        authResponseMessage: action.auth.message,
        authStatusCode: action.auth.status,
      }
    case "SIGNOUT_SUCCESS":
      return {
        ...state,
        authToken: null,
        authError: null,
        logging_in: false,
        authResponseMessage: null,
        authStatusCode: null,
        authFreeze: null,
      }
    default:
      return state
  }
}

export default authReducer

import React from "react"
import "./styles/servicingModal.scss"
import EscapeOutside from "react-escape-outside"
import useSWR from "swr"
import moment from "moment"

import close_green_corner from "../../../img/dashboard_card/close_green_corner.svg"
import close_button from "../../../img/dashboard_card/close_button.svg"
import LoadingCard from "./loadingCard"
// import Axios from "axios"

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <EscapeOutside onEscapeOutside={handleClose}>
        <div className="wrapper-modal">
          <section className="modal-main">{children}</section>
        </div>
      </EscapeOutside>
    </div>
  )
}

const ServicingModal = ({ handleHide, show, person, date }) => {
  const url = `${
    process.env.REACT_APP_KAM_API_URL
  }calls/v1/servicing-history?date=${moment(date).format(
    "YYYY-MM-DD"
  )}&whitelisting_number=${person.kam_number}`
  const { data } = useSWR(url)
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   Axios.get(
  //     `https://api-dexter-kam.retaildata.xyz/prod/calls/v1/servicing-history?date=${moment(
  //       date
  //     ).format("YYYY-MM-DD")}&whitelisting_number=${person.kam_number}`,
  //     {
  //       headers: {
  //         Authorization:
  //           // "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
  //           "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjIxNTk4MzY2LCJqdGkiOiJkMTllNjQxZmM1ZmI0MDdkOWZkM2FjNjAxODA4YzIwZiIsInVzZXJfaWQiOjl9.KZ5Q_sT543z2-qM0rCdggt49UlPrpj_dn6-pwKhXtug",
  //       },
  //     }
  //   )
  //     .then((res) => setData(res.data))
  //     .catch((err) => err);
  // }, []);

  return (
    <section className="modal-main servicing-modal-section">
      {/* <h3 className="ex-title">Modal Type: 1</h3> */}

      <Modal show={show} handleClose={handleHide}>
        <div className="modal-header">
          <div className="headline">
            <div className="main-headline">
              {person.kam_name}'s Servicing List
            </div>
            <div className="sub-headline">
              List of merchants serviced by {person.kam_name}
            </div>
          </div>
          <div className="close-button-corner" onClick={handleHide}>
            <img className="corner" src={close_green_corner} alt="close" />
            <img className="cross" src={close_button} alt="close" />
          </div>
        </div>
        <div className="modal-body">
          <div className="basic">
            <table className="merch-list-table">
              <thead className="merch-list-table-head">
                <tr className="merch-list-table-head-row">
                  <th>Merchant Name</th>
                  <th>Wallet Number</th>
                  <th>Merchant Servicing</th>
                  <th>Merchant Requirements</th>
                  <th>POSM Status</th>
                  <th>Feedback</th>
                  <th>Status</th>
                </tr>
              </thead>
              {!data || !data.length > 0 ? (
                <LoadingCard />
              ) : (
                <tbody className="merch-list-table-body">
                  {data.map((merchant, i) => (
                    <MerchList data={merchant} key={i} />
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </Modal>
    </section>
  )
}

const MerchList = ({ data }) => {
  return (
    <tr className="merch-list-table-body-row">
      <td>{data.outlet__brand_name}</td>
      <td>{data.outlet__wallet_no}</td>
      <td>{data["Merchant Servicing"]}</td>
      <td>{data["Merchant Requirements"]}</td>
      <td>{data.posm_status}</td>
      <td>{data.Content}</td>
      <td>{`${data.call_tag.charAt(0).toUpperCase()}${data.call_tag.slice(
        1
      )}`}</td>
    </tr>
  )
}

export default ServicingModal

import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"

const KAMServicing = [
  {
    icon: "business",
    name: "Servicing",
    to: "/",
  },
  {
    icon: "description",
    name: "Reports",
    to: "/reports",
  },
]

const NavLinkList = [
  {
    icon: "apps",
    name: "Dashboard",
    to: "/",
  },

  {
    icon: "description",
    name: "Reports",
    to: "/reports",
  },
  {
    icon: "local_phone",
    name: "Calls",
    to: "/calls",
  },
  {
    icon: "store",
    name: "Outlets",
    to: "/outlets",
  },
  {
    icon: "track_changes",
    name: "KYC",
    to: "/kyc",
  },
]
const NavLinkList_territory_manager = [
  {
    icon: "apps",
    name: "Dashboard",
    to: "/",
  },

  {
    icon: "description",
    name: "Reports",
    to: "/reports",
  },
  {
    icon: "local_phone",
    name: "Calls",
    to: "/calls",
  },
  {
    icon: "store",
    name: "Outlets",
    to: "/outlets",
  },
  {
    icon: "business",
    name: "Admin",
    to: "/admin",
  },
  {
    icon: "people",
    name: "Leaderboard",
    to: "/leaderboard",
  },
  {
    icon: "track_changes",
    name: "KYC",
    to: "/kyc",
  },
]

const NavKAMList = [
  {
    icon: "apps",
    name: "Dashboard",
    to: "/",
  },
  {
    icon: "code",
    name: "Transfers",
    to: "/transfers",
  },

  {
    icon: "description",
    name: "Reports",
    to: "/reports",
  },
  {
    icon: "local_phone",
    name: "Calls",
    to: "/calls",
  },
  {
    icon: "store",
    name: "Outlets",
    to: "/outlets",
  },
]

const NavLinkList__super_user = [
  {
    icon: "apps",
    name: "Dashboard",
    to: "/",
  },
  {
    icon: "description",
    name: "Reports",
    to: "/reports",
  },
  {
    icon: "local_phone",
    name: "Calls",
    to: "/calls",
  },
  {
    icon: "store",
    name: "Outlets",
    to: "/outlets",
  },
  {
    icon: "upload",
    name: "Upload",
    to: "/admin-upload",
  },
  {
    icon: "track_changes",
    name: "KYC",
    to: "/kyc",
  },
  {
    icon: "business",
    name: "Admin",
    to: "/admin",
  },
  {
    icon: "people",
    name: "Leaderboard",
    to: "/leaderboard",
  },
]

const NavLinkList__cluster_manager = [
  {
    icon: "apps",
    name: "Dashboard",
    to: "/",
  },
  {
    icon: "description",
    name: "Reports",
    to: "/reports",
  },
  {
    icon: "local_phone",
    name: "Calls",
    to: "/calls",
  },
  {
    icon: "store",
    name: "Outlets",
    to: "/outlets",
  },
  {
    icon: "track_changes",
    name: "KYC",
    to: "/kyc",
  },
]

const NavLinkList__QC = [
  {
    icon: "track_changes",
    name: "KYC",
    to: "/kyc",
  },
]

class SignedInLinks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      navigation_links__operation_links_sm: "navigation-desktop",
    }
  }

  MenuNavLink = (navLinkList) =>
    navLinkList.map((item, index) => (
      <li
        key={index}
        className="navigation-link"
        onClick={() => this.props.updateSelectedMenu(index)}
      >
        <NavLink
          to={item.to}
          className={`collection-item ${
            parseInt(this.props.selectedMenu) === parseInt(index)
              ? "active-menu"
              : ""
          }`}
          key={index}
        >
          <div className="link-description">
            <i className="material-icons left">{item.icon}</i>
            <span className="">{item.name}</span>
          </div>
        </NavLink>
      </li>
    ))

  render() {
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))
    const portalType = JSON.parse(localStorage.getItem("portal"))

    return (
      <>
        <div className={`${this.state.navigation_links__operation_links_sm}`}>
          <ul className="navigation-links">
            {/* {portalType === "kam" && this.MenuNavLink(NavKAMList)} */}
            {localUserType === "bkash_user"
              ? this.MenuNavLink(NavLinkList__QC)
              : localUserType === "bkash-super-user"
              ? this.MenuNavLink(NavLinkList__super_user)
              : localUserType === "territory_manager"
              ? this.MenuNavLink(NavLinkList_territory_manager)
              : localUserType === "cluster_manager"
              ? this.MenuNavLink(NavLinkList__cluster_manager)
              : localUserType === "merchant_management" ||
                localUserType === "trade_merketing"
              ? this.MenuNavLink(KAMServicing)
              : portalType === "kam"
              ? this.MenuNavLink(NavKAMList)
              : this.MenuNavLink(NavLinkList)}
          </ul>
          <ul className="operation-links">
            <li style={{ marginRight: "20px" }} className="signout-link">
              <NavLink to="/signout">Sign Out</NavLink>
            </li>
          </ul>
        </div>
        <ul className="navigation-dropdown-button">
          <li
            onClick={() =>
              this.setState({
                navigation_links__operation_links_sm:
                  this.state.navigation_links__operation_links_sm ===
                  "navigation-desktop"
                    ? "navigation-mobile"
                    : "navigation-desktop",
              })
            }
            style={{ width: "20px", marginRight: "20px" }}
          >
            <i className="material-icons">
              {this.state.navigation_links__operation_links_sm ===
              "navigation-desktop"
                ? "format_list_bulleted "
                : "close"}
            </i>
          </li>
        </ul>
      </>
    )
  }
}

const mapSTateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authToken: state.auth.authToken,
    filterHide: state.filter.filterHide,
  }
}

export default connect(mapSTateToProps)(SignedInLinks)

import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import moment from "moment"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import axios from "axios"

import { string_uppercase } from "../../../../utilities/_string_uppercase"

import image_not_found_img from "./../../../../img/image-not-found.png"
import mdo_img from "./../../../../img/calls_card/mdo.svg"
import phone_img from "./../../../../img/calls_card/phone.svg"
import event_img from "./../../../../img/calls_card/event.svg"
import bmcc_img from "./../../../../img/calls_card/bmcc.svg"
import spot_img from "./../../../../img/calls_card/spot.svg"

import doc_img from "./../../../../img/kyc/details/doc.svg"
import reason_img from "./../../../../img/kyc/details/reason.svg"

import OutletCallLocation from "./components/outletLocation"
import KYCHistoryCard from "./components/kycHistoryCard"
import LoadingCard from "./components/loading"

import MarkReceive from "../actions/markReceive"
import AcceptReject from "../actions/acceptReject"
import SendBkashMDO from "../actions/sendBkashMDO"

import "./style.css"
import Footer from "../../../Footer/Footer"

class Details extends Component {
  constructor(props) {
    super(props)

    this.state = {
      outlet_basic_info: null,
      kyc_history: null,

      kyc_history_data_fetching: false,
      outlet_basic_info_data_fetching: false,

      backgroundPosition: "0% 0%",
    }
  }

  // state = {
  //   backgroundImage: `url(${src})`,
  //   backgroundPosition: '0% 0%'
  // }

  handleMouseMove = (e) => {
    console.log(e)
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = ((e.pageX - left) / width) * 100
    const y = ((e.pageY - top) / height) * 100
    this.setState({ backgroundPosition: `${x}% ${y}%` })
  }
  componentDidMount() {
    this.setState({
      outlet_basic_info_data_fetching: true,
      kyc_history_data_fetching: true,
    })
    this.set_data_set()
  }

  set_data_set = () => {
    this.set_call_histroy()
    this.set_kyc_history()
  }

  set_call_histroy() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/outlets/v1/${this.props.match.params.id}/last-call`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          outlet_basic_info: res.data,
          outlet_basic_info_data_fetching: false,
        })
      })
      .catch((err) => {
        return this.setState({
          outlet_basic_info_data_fetching: false,
        })
      })
  }

  set_kyc_history() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/outlets/v1/${this.props.match.params.id}/kyc-history`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          kyc_history: res.data,
          kyc_history_data_fetching: false,
        })
      })
      .catch((err) => {
        return this.setState({
          kyc_history_data_fetching: false,
        })
      })
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />

    return (
      <div className="main-container">
        {this.state.outlet_basic_info_data_fetching ? (
          <LoadingCard
          // card_heading={`${item.card_heading}`}
          />
        ) : (
          <div className="details-container">
            <div className="back-button-container">
              <button
                onClick={() => this.props.history.push("/kyc")}
                className="back-button"
              >
                <i className="material-icons">chevron_left</i>
              </button>
            </div>
            {this.state.outlet_basic_info && (
              <div className="description">
                <div className="left-side">
                  <div className="top">
                    <div
                      className="outlet-image-container"
                      style={{
                        backgroundImage: `url(${
                          this.state.outlet_basic_info.outlet_image ||
                          image_not_found_img
                        })`,
                        backgroundPosition: this.state.backgroundPosition,
                      }}
                    >
                      <Zoom>
                        <img
                          onMouseMove={this.handleMouseMove}
                          style={this.state}
                          src={
                            this.state.outlet_basic_info.outlet_image ||
                            image_not_found_img
                          }
                          alt="outlet"
                        />
                      </Zoom>
                      {/* <img src={image_not_found_img} alt="outlet" /> */}
                    </div>
                    <div className="info">
                      <div className="basic-info">
                        <div className="outlet-name">
                          {string_uppercase(
                            this.state.outlet_basic_info.outlet__name || "..."
                          )}
                        </div>

                        <div className="outlet-phone">
                          {this.state.outlet_basic_info.outlet__wallet_no ? (
                            <>
                              <div className="value">
                                {this.state.outlet_basic_info
                                  .outlet__wallet_no || "..."}
                              </div>
                              <div className="tag">Merchant Wallet Number</div>
                            </>
                          ) : (
                            <>
                              <div className="value">
                                {this.state.outlet_basic_info
                                  .field_force_whitelisting_number || "..."}
                              </div>
                              <div className="tag">Merchant Contact Number</div>
                            </>
                          )}
                        </div>

                        <div
                          className={
                            this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                            "MICRO MERCHANT"
                              ? "blue-type"
                              : this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                                "MERCHANT PLUS LITE B"
                              ? "navy-blue-type"
                              : this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                                "MERCHANT PLUS LITE A"
                              ? "purpple-type"
                              : this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                                "MEDIUM MERCHANT"
                              ? "olive-type"
                              : "green-type"
                          }
                        >
                          {string_uppercase(
                            this.state.outlet_basic_info.outlet_type || "..."
                          )}
                        </div>
                      </div>

                      <div className="extra-info">
                        <div className="call">
                          <div className="value">
                            {this.state.outlet_basic_info.total_calls || "..."}
                          </div>
                          <div className="tag">Total Calls To This Outlet</div>
                        </div>
                        <div className="mdo">
                          <div className="value">
                            {this.state.outlet_basic_info.total_mdos || "..."}
                          </div>
                          <div className="tag">MDOs Covered This Outlet</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bottom">
                    <div className="location-map">
                      {this.state.outlet_basic_info.lat &&
                        this.state.outlet_basic_info.lng && (
                          <OutletCallLocation
                            location={[
                              this.state.outlet_basic_info.lat,
                              this.state.outlet_basic_info.lng,
                            ]}
                          />
                        )}
                    </div>
                    <div className="address">
                      <div className="info">
                        <img src={bmcc_img} alt="bmcc img" />
                        <div className="data">
                          <span className="code">
                            {this.state.outlet_basic_info.bmcc__code || "..."}
                          </span>
                          <span className="code-description bmcc-code-description">
                            {this.state.outlet_basic_info.bmcc__name || "..."}
                          </span>
                        </div>
                      </div>

                      <div className="info">
                        <img src={spot_img} alt="bmcc img" />
                        <div className="data">
                          <span className="code">
                            {this.state.outlet_basic_info.spot_code__code ||
                              "..."}
                          </span>
                          <span className="code-description">
                            {this.state.outlet_basic_info.spot_code__name ||
                              "..."}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="right-side">
                  <div className="description">
                    <div className="mdo-description">
                      <div className="section-heading">MDO Info</div>

                      <div className="info">
                        <img src={mdo_img} alt="bmcc img" />
                        <div className="data">
                          MDO Name
                          <div className="mdo-name">
                            {this.state.outlet_basic_info.field_force__name ||
                              "..."}
                          </div>
                        </div>
                      </div>

                      <div className="info">
                        <img src={phone_img} alt="bmcc img" />
                        <div className="data">
                          MDO's Phone Number
                          <div className="mdo-number">
                            {this.state.outlet_basic_info
                              .field_force__whitelisting_number || "..."}
                          </div>
                        </div>
                      </div>

                      <div className="info">
                        <img src={event_img} alt="bmcc img" />
                        <div className="data">
                          Call Date & Time
                          <div className="date">
                            {this.state.outlet_basic_info.zone_aware_start_time
                              ? moment(
                                  this.state.outlet_basic_info
                                    .zone_aware_start_time
                                ).format("ddd, MMM DD; HH:MM")
                              : "..."}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="doc-list">
                      <div className="section-heading">Collected Documents</div>
                      {this.state.outlet_basic_info.documents.map(
                        (doc, index) => (
                          <div key={index} className="doc">
                            <img src={doc_img} alt="doc" />
                            {doc}
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  {this.state.outlet_basic_info.reason && (
                    <div className="rejection-reason-container">
                      <div className="info">
                        <img src={reason_img} alt="rejection reason" />
                        <div className="data">
                          Rejection Reason
                          <div className="reason">
                            {this.state.outlet_basic_info.reason || "..."}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {JSON.parse(localStorage.getItem("currUserType")) !==
                    "bkash-super-user" && (
                    <div className="action">
                      {this.props.match.params.action === "mark_receive" ? (
                        <MarkReceive
                          id={this.props.match.params.kyc}
                          kyc_id={this.state.outlet_basic_info.kyc_id}
                          kyc_received_set_data_set={this.set_data_set}
                          status={this.state.outlet_basic_info.status}
                          classNamePrefix="detail"
                        />
                      ) : this.props.match.params.action === "accept_reject" ? (
                        <AcceptReject
                          id={this.props.match.params.kyc}
                          pending_processing_set_data_set={this.set_data_set}
                          status={
                            this.state.outlet_basic_info.status
                              ? this.state.outlet_basic_info.status
                              : "fresh"
                          }
                          classNamePrefix="detail"
                        />
                      ) : this.props.match.params.action ===
                        "send_to_bkash__mdo" ? (
                        <SendBkashMDO
                          id={this.props.match.params.kyc}
                          kyc_id={this.state.outlet_basic_info.kyc_id}
                          pending_processing_set_data_set={this.set_data_set}
                          status={this.state.outlet_basic_info.status}
                          classNamePrefix="detail"
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="kyc-history-card-container">
          {this.state.kyc_history_data_fetching ? (
            <LoadingCard
            // card_heading={`${item.card_heading}`}
            />
          ) : (
            <>
              <div className="kyc-heading">
                <div className="kyc-headline">KYC History</div>
                <div className="kyc-sub-headline">Activity Summary</div>
              </div>
              <div className="card-row row kyc-history-cards">
                {this.state.kyc_history &&
                  this.state.kyc_history.map((item, index) => (
                    <div className="card-col col l12 s12 m12">
                      <KYCHistoryCard history={item} key={index} />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>

        <Footer />
      </div>
    )
  }
}

export default Details

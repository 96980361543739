import React, { useEffect } from "react"
import IndeterminateCheckbox from "./indeterminateCheckbox"

import { useTable, useRowSelect } from "react-table"

const Table = ({
  columns: userColumns,
  data,
  handleChange,
  onFetchData,
  noCheckbox,
  tempData,
  className,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    { columns: userColumns, data },
    /** Programmatically adding the "Checkbox" select column
     *  and passing props to the un-controlled checkbox component
     * */
    useRowSelect,
    (hooks) => {
      if (!noCheckbox) {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row }) => (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
            },
            ...columns,
          ]
        })
      }
    }
  )
  useEffect(() => {
    /** Updates the global state
     *  with the values of the selected items
     *  in order to be marked in bulk
     */
    if (!noCheckbox) {
      // onFetchData(state);
      handleChange(selectedFlatRows.map((row) => row.original))
    }
  }, [selectedFlatRows])

  return (
    <table {...getTableProps()} className="strategic-transfer">
      <thead className="strategic-transfer-main-head">
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr
              className="strategic-transfer-main-head-tr"
              {...headerGroup.getHeaderGroupProps()}
            >
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps()} className={className}>
                    {
                      // Render the header
                      column.render("Header")
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>

      <tbody className="strategic-transfer-main-body" {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr
                className="strategic-transfer-main-body-tr"
                {...row.getRowProps()}
                // onClick={() => console.log(row.original)}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell, i) => {
                    // Apply the cell props
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={
                          /** Instead of having a row onClick event,
                           * (that also triggers from the button
                           * and the checkbox) handling onclick on
                           * the td's excluding checkboxes and button column*/
                          i >= 1 && i <= 6
                            ? () => {
                                console.log(row.original)
                              }
                            : null
                        }
                      >
                        {
                          // Render the cell contents
                          cell.render("Cell")
                        }
                      </td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default Table

import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";

import moment from "moment";

export default class CustomPieChart extends PureComponent {
  state = {
    activeIndex: 0,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      // outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={96}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={50}
        />
        {/* <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 3}
          fill={fill}
        /> */}
        <text
          x={cx}
          y={cy - 10}
          dy={8}
          textAnchor="middle"
          fontFamily="Roboto"
          fill="#434364"
          fontSize="18px"
        >
          {`${value}%`}
        </text>

        <text
          x={cx}
          y={cy + 12}
          dy={8}
          textAnchor="middle"
          fontFamily="Roboto"
          fill="#707070"
          fontSize="12px"
        >
          {moment(this.props.date).format("ddd, MMM DD") ===
          moment(new Date()).format("ddd, MMM DD")
            ? `Today`
            : `${moment(this.props.date).format("ll")}`}
        </text>
      </g>
    );
  };

  render() {
    return (
      <ResponsiveContainer>
        <PieChart width={350} height={350}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={this.renderActiveShape}
            data={
              this.props.resistancePie
                ? this.props.data.slice(0, this.props.data.length)
                : this.props.data.slice(0, this.props.data.length - 1)
            }
            innerRadius={85}
            outerRadius={95}
            fill="#8884d8"
            dataKey="percentage"
            onMouseEnter={this.onPieEnter}
            // cornerRadius={50}
          >
            {this.props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={this.props.COLORS[index % this.props.COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

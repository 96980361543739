import React from "react"
import "./404.styles.scss"
const PageNotFound = () => {
  return (
    <div className="main-container">
      <div className="not-found-container">
        <span>ERROR 404</span>
        <h1>Sorry, Page Not Found</h1>
      </div>
    </div>
  )
}

export default PageNotFound

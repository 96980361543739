import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import MarkReceive from "../../actions/markReceive"
import AcceptReject from "../../actions/acceptReject"
import SendBkashMDO from "../../actions/sendBkashMDO"
import Status from "../../actions/status"

// import Checkbox from "@material-ui/core/Checkbox";

import { string_trim } from "./../../../../../utilities/_string_trim"

import "./../styles/table.scss"

class Table extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
      tooltip: {
        agency_user: {
          Submitted: {
            kyc_bkash_rejected: "Rejected by bKash QS team.",
          },
          "Pending Processing": {},
          Processed: {
            kyc_agency_acknowledged: "Rejected by bKash QS team.",
            kyc_bkash_cs_rejected: "Rejected by bKash CS team.",
            kyc_agency_rejected: "Rejected by Agency",
          },
        },
        bkash_user: {
          Submitted: {},
          "Pending Processing": {},
          Processed: {
            kyc_bkash_cs_rejected: "Rejected by bKash CS team.",
          },
        },

        cluster_manager: {
          Submitted: {},
          "Pending Processing": {},
          Processed: {
            kyc_bkash_cs_rejected: "Rejected by bKash CS team.",
          },
        },

        "bkash-super-user": {
          "Agency Processing": {
            kyc_agency_rejected: "Rejected by Agency.",
            kyc_agency_acknowledged: "Rejected by bKash QS team.",
            kyc_bkash_cs_rejected: "Rejected by bKash CS team.",
            kyc_bkash_rejected: "Rejected by bKash QS team.",
          },
          "bKash Processing": {
            kyc_agency_rejected: "Rejected by Agency.",
            kyc_agency_acknowledged: "Rejected by bKash QS team.",
            kyc_bkash_cs_rejected: "Rejected by bKash CS team.",
            kyc_bkash_rejected: "Rejected by bKash QS team.",
          },
          Processed: {
            kyc_agency_rejected: "Rejected by Agency.",
            kyc_agency_acknowledged: "Rejected by bKash QS team.",
            kyc_bkash_cs_rejected: "Rejected by bKash CS team.",
            kyc_bkash_rejected: "Rejected by bKash QS team.",
          },
        },
      },
    }
  }

  handleChecked = () => {
    this.setState({ checked: !this.state.checked })
  }

  routeChange = (action, id, kyc) => {
    let path = `/kyc/received/${action}/${id}/${kyc}`
    this.props.history.push(path)
  }

  render() {
    const curr_user = JSON.parse(localStorage.getItem("currUserType"))
    return (
      <div className="kyc-table-container">
        <table className="kyc-table">
          <thead>
            <tr>
              {this.props.column.map((col) => (
                <th>{col}</th>
              ))}
              {this.props.action === "status" ? <th>Status</th> : null}
            </tr>
          </thead>
          {this.props.data && (
            <tbody>
              {this.props.data.map((item, index) => (
                <tr
                  key={`${index}-${item.outlet__wallet_no || "..."}-${
                    item.created || "..."
                  }`}
                  className={`${
                    string_trim(item.status)
                      .split(" ")
                      .reverse()[0]
                      .toLowerCase() === "rejected"
                      ? `rejected-tr `
                      : string_trim(item.status)
                          .split(" ")
                          .reverse()[0]
                          .toLowerCase() === "acknowledged"
                      ? `rejected-tr `
                      : string_trim(item.status)
                          .split(" ")
                          .reverse()[0]
                          .toLowerCase() === "approved" && item.comment !== null
                      ? `rejected-tr `
                      : `other-tr`
                  }`}
                >
                  {/* <td>
                    <div>
                      <Checkbox
                        checked={this.state.checked}
                        onChange={this.handleChecked}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </td> */}
                  <td>
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {item.outlet__wallet_no || "..."}

                        {this.state.tooltip[`${curr_user}`] &&
                          this.state.tooltip[`${curr_user}`][
                            `${this.props.table_type}`
                          ] &&
                          this.state.tooltip[`${curr_user}`][
                            `${this.props.table_type}`
                          ][`${item.status}`] && (
                            <span>
                              <div
                                data-tip={`${
                                  this.state.tooltip[`${curr_user}`][
                                    `${this.props.table_type}`
                                  ][`${item.status}`]
                                } - Reason: ${item.comment}`}
                              >
                                <i className="material-icons">info</i>
                              </div>
                              <ReactTooltip
                                place="top"
                                type="dark"
                                effect="float"
                              />
                            </span>
                          )}
                      </div>
                    </Link>
                  </td>

                  <td>
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {" "}
                        {item.outlet__contact_person_phone_number || "..."}
                      </div>
                    </Link>
                  </td>

                  <td>
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {" "}
                        {item.outlet__name || "..."}
                      </div>
                    </Link>
                  </td>

                  {curr_user === "agency_user" ? null : (
                    <td>
                      <Link
                        onClick={() =>
                          this.props.action === "mark_receive" ||
                          "accept_reject" ||
                          "send_to_bkash__mdo"
                        }
                        to={`/kyc/received/${this.props.action}/${
                          item.outlet__id || item.outlet_id
                        }/${item.id}`}
                      >
                        <div className="td-wrap">
                          {JSON.parse(localStorage.getItem("currUserType")) !==
                          "agency_user"
                            ? item.agency__cluster_managers__name || "..."
                            : item.field_force__whitelisting_number || "..."}
                        </div>
                      </Link>
                    </td>
                  )}

                  <td>
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {JSON.parse(localStorage.getItem("currUserType")) !==
                        "agency_user"
                          ? item.agency__name || "..."
                          : item.field_force__name || "..."}
                      </div>
                    </Link>
                  </td>

                  <td>
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {" "}
                        {item.spot_code__code ||
                          item.outlet__spot_code__code ||
                          "..."}
                      </div>
                    </Link>
                  </td>

                  <td
                  // onClick={() =>
                  //   this.props.action === "mark_receive" ||
                  //   "accept_reject" ||
                  //   "send_to_bkash__mdo"
                  //     ? this.routeChange(
                  //         this.props.action,
                  //         item.outlet__id || item.outlet_id,
                  //         item.id
                  //       )
                  //     : null
                  // }
                  >
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {" "}
                        {item.spot_code__name ||
                          item.outlet__spot_code__name ||
                          "..."}
                      </div>
                    </Link>
                  </td>

                  <td>
                    <Link
                      onClick={() =>
                        this.props.action === "mark_receive" ||
                        "accept_reject" ||
                        "send_to_bkash__mdo"
                      }
                      to={`/kyc/received/${this.props.action}/${
                        item.outlet__id || item.outlet_id
                      }/${item.id}`}
                    >
                      <div className="td-wrap">
                        {" "}
                        {item.bmcc__code || item.outlet__bmcc_id || "..."}
                      </div>
                    </Link>
                  </td>

                  {this.props.action === "mark_receive" &&
                  JSON.parse(localStorage.getItem("currUserType")) !==
                    "bkash-super-user" ? (
                    <td>
                      <div className="td-wrap">
                        <MarkReceive
                          id={item.id}
                          kyc_id={item.kyc_id}
                          kyc_received_set_data_set={
                            this.props.kyc_received_set_data_set
                          }
                          status={item.status}
                          classNamePrefix="table"
                        />
                      </div>
                    </td>
                  ) : this.props.action === "send_to_bkash__mdo" &&
                    JSON.parse(localStorage.getItem("currUserType")) !==
                      "bkash-super-user" ? (
                    <td>
                      <div className="td-wrap">
                        <SendBkashMDO
                          id={item.id}
                          kyc_id={item.kyc_id}
                          pending_processing_set_data_set={
                            this.props.kyc_received_set_data_set
                          }
                          status={item.status}
                          classNamePrefix="table"
                        />
                      </div>
                    </td>
                  ) : this.props.action === "accept_reject" &&
                    JSON.parse(localStorage.getItem("currUserType")) !==
                      "bkash-super-user" ? (
                    <td>
                      <div className="td-wrap">
                        <AcceptReject
                          id={item.id}
                          pending_processing_set_data_set={
                            this.props.pending_processing_set_data_set
                          }
                          status={item.status}
                          classNamePrefix="table"
                        />
                      </div>
                    </td>
                  ) : this.props.action === "status" ? (
                    <td>
                      <div className="td-wrap">
                        {" "}
                        <Status status={item.status} />
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    )
  }
}

export default withRouter(Table)

import React from "react"
import "../styles/active-merchant-base.scss"
import trophy from "../assets/trophy.svg"
import LoadingCard from "./loadingCard"
import useSWR from "swr"
import { connect } from "react-redux"

import Chart from "react-apexcharts"

const ActiveMerchantBase = ({ idNumber, current_month }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/performance/v1/active-merchant-base/${idNumber}?month=${current_month}`
  const { data, error } = useSWR(url)

  return (
    <div className="card-col col s12 m12 l12 ">
      {!data ? (
        <LoadingCard />
      ) : (
        <div className="card active-merchant-card">
          <img className="img card-corner" src={trophy} alt="card-corner" />
          <div className="card-title">Active Merchant Base</div>
          <div className="card-body">
            <div className="row-outer">
              <div className="column-1 chart">
                <Chart
                  series={[Math.round(data.conversion_rate)]}
                  options={options}
                  type="radialBar"
                  height={220}
                />
              </div>
              <div className="column-2 data">
                <div className="row-inner">
                  <div className="data-col">
                    <span className="number">{data.total_merchant_base}</span>
                    <span className="label">Total Merchant Base</span>
                  </div>
                  <div className="data-col">
                    <span className="number">{data.target}</span>
                    <span className="label">Target</span>
                  </div>
                  <div className="data-col">
                    <span className="number">{data.achieved}</span>
                    <span className="label">Achieved</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const options = {
  chart: {
    height: 280,
    type: "radialBar",
  },
  colors: ["#f5beb4"],
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 2,
        size: "70%",
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: 15,
          show: true,
          color: "#888",
          fontSize: "12px",
        },
        value: {
          color: "#111",
          fontSize: "24px",
          show: true,
          offsetY: -18,
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      gradientToColors: ["#e2136e"],
      stops: [0, 100],
    },
  },

  stroke: {
    lineCap: "square",
  },
  labels: ["Target Achieved"],
}

const mapStateToProps = (state) => ({
  current_month: state.leaderboardPage.profile.current_month,
})
export default connect(mapStateToProps)(ActiveMerchantBase)

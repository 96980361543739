import axios from "axios";
import wrapper from "axios-cache-plugin";

let http = wrapper(axios, {
  maxCacheSize: 50,
  ttl: 600000,
});

http.__addFilter(/api/);

export default http;

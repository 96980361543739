import React, { Component } from "react";
import { connect } from "react-redux";

import { updateSelectedTab } from "../../../store/actions/selectedTabAction";

import "./../styles/tabNavs.css";

class TabNavs extends Component {
  // componentDidMount = () => {
  //     this.props.tab_navs && this.props.tab_navs.length > 3 ? this.props.updateSelectedTab(4) : this.props.updateSelectedTab(1)
  // }
  create_tab = (tab, index) =>
    tab && (
      <div
        key={index}
        className={
          parseInt(this.props.selectedTab) === parseInt(tab.index)
            ? "active-tab"
            : "tab"
        }
        onClick={() => this.props.updateSelectedTab(tab.index)}
      >
        <img
          src={
            parseInt(this.props.selectedTab) === parseInt(tab.index)
              ? tab.active_image
              : tab.image
          }
          alt={tab.name}
        />
        <div className="tab-tag">{tab.name}</div>
        <div className="count">{tab.count}</div>
      </div>
    );

  render() {
    return (
      <div className="tabs-container">
        <div className="heading">KYC Management</div>
        <div className="tab-container">
          {this.props.tab_navs &&
            this.props.tab_navs.map((tab, index) =>
              this.create_tab(tab, index)
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let selectedTab = state.selectedTab.selectedTabIndex;

  return { selectedTab };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedTab: (selectedTabIndex) =>
      dispatch(updateSelectedTab(selectedTabIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabNavs);

import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import history from "./utilities/_history";
import { connect } from "react-redux";

import Navbar from "./Components/layout/Navbar";

import Reports from "./Components/Reports";
// import Dashboard from "./Components/Dashboard"
import NewDashboard from "./Components/NewDashboard";
import KAMDashboard from "./Components/KAMDashboard";
import KAMTransfers from "./Components/KAMTransfers";
import Calls from "./Components/Calls";
import KAMCalls from "./Components/KAMCalls";
import Outlets from "./Components/Outlets";
import KAMOutlets from "./Components/KAMOutlets";
import OutletsDetail from "./Components/Outlets/components/OutletsDetail";
import KAMOutletsDetail from "./Components/KAMOutlets/components/KAMOutletsDetail";
import KycTracking from "./Components/KycTracking";
import Details from "./Components/KycTracking/components/details";
import Admin from "./Components/Admin";
import FSEManagment from "./Components/Admin/pages/FSEManagement/FSEManagement";
import KAMServicing from "./Components/KAMServicing";
import AdminUpload from "./Components/AdminUpload";
import PageNotFound from "./Components/PageNotFound";
import Leaderboard from "./Components/Leaderboard";
import LeaderboardProfile from "./Components/Leaderboard/pages/leaderboard-profile";
import HierarchyModule from "./Components/Hierarchy";

import SignIn from "./Components/Auth/signIn";
import SignOut from "./Components/Auth/signOut";

import "./style.scss";
/**
 * The main APP component that wraps the entire application
 *
 * @component
 */

const App = ({ token, portal, userType }) => {
  const rootRoute = () => {
    if (portal === "dexter") {
      return NewDashboard;
    } else {
      if (
        userType === "trade_merketing" ||
        userType === "merchant_management"
      ) {
        return KAMServicing;
      } else {
        return KAMDashboard;
      }
    }
  };
  return (
    <Router history={history}>
      <div className="App">
        <Navbar />
      </div>

      <Switch>
        <Route exact path="/" component={rootRoute()} />

        {/* <Route exact path="/servicing" component={KAMServicing}></Route> */}

        <Route exact path="/transfers" component={KAMTransfers}></Route>
        <Route exact path="/reports" component={Reports}></Route>
        <Route
          exact
          path="/calls"
          component={portal === "kam" ? KAMCalls : Calls}
        ></Route>
        <Route
          exact
          path="/outlets"
          component={portal === "kam" ? KAMOutlets : Outlets}
        ></Route>
        <Route
          exact
          path={`/outlets/:id`}
          component={portal === "kam" ? KAMOutletsDetail : OutletsDetail}
        ></Route>
        <Route exact path="/kyc" component={KycTracking}></Route>
        <Route
          exact
          path="/kyc/received/:action/:id/:kyc"
          component={Details}
        ></Route>
        <Route exact path="/admin" component={Admin}></Route>
        <Route exact path="/admin/fse/:id" component={FSEManagment}></Route>
        <Route exact path="/admin-upload" component={AdminUpload}></Route>
        <Route exact path="/admin/h-module" component={HierarchyModule}></Route>

        <Route path="/signout" component={SignOut}></Route>
        <Route path="/signin" component={SignIn}></Route>
        <Route exact path="/leaderboard" component={Leaderboard}></Route>
        <Route
          exact
          path="/leaderboard/:id"
          component={LeaderboardProfile}
        ></Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({ auth }) => ({
  token: auth.authToken ? auth.authToken : null,
  portal: auth.authToken ? auth.authToken.portal : null,
  userType: auth.authToken ? auth.authToken.type : null,
});

export default connect(mapStateToProps)(App);

App.propTypes = {
  token: PropTypes.object,
  portal: PropTypes.string,
  userType: PropTypes.string,
};

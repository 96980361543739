import React, { Component } from "react";
import "./EditModal.styles.scss";
import EscapeOutside from "react-escape-outside";
import axios from "axios";
// import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader";

// import fse_img from "./_image/person.svg"
import picture from "./_image/picture.svg";
import close_green_corner from "./_image/close_green_corner.svg";
import close_button from "./_image/close_button.svg";
import Dropdown from "./Dropdown/Dropdown";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const weekdays = [
  {
    id: "101",
    name: "Sunday",
  },
  {
    id: "102",
    name: "Monday",
  },
  {
    id: "103",
    name: "Tuesday",
  },
  {
    id: "104",
    name: "Wednesday",
  },
  {
    id: "105",
    name: "Thursday",
  },
  {
    id: "106",
    name: "Friday",
  },
  {
    id: "107",
    name: "Saturday",
  },
];

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <EscapeOutside onEscapeOutside={handleClose}>
        <div className="wrapper-modal">
          <section className="modal-main">{children}</section>
        </div>
      </EscapeOutside>
    </div>
  );
};

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: props.image
        ? this.props.image
        : "https://png.pngitem.com/pimgs/s/506-5067022_sweet-shap-profile-placeholder-hd-png-download.png",
      image_object: null,
      name: props.name,
      agencies: [],
      selected_option: [],

      leave_day: props.leave_day,
      leave_day_id: props.leave_day_id,

      joining_date: props.joining_date || new Date(),

      loading: false,
      contact_number: props.phone,

      showError: false,
      showSuccess: false,
    };
  }

  componentWillUpdate(prevProps) {
    if (
      this.props.image !== prevProps.image ||
      this.props.name !== prevProps.name ||
      this.props.leave_day !== prevProps.leave_day ||
      this.props.leave_day_id !== prevProps.leave_day_id ||
      this.props.contact_number !== prevProps.phone ||
      this.props.joining_date !== prevProps.joining_date
    ) {
      this.setData(
        this.props.image,
        this.props.name,
        this.props.leave_day,
        this.props.leave_day_id,
        this.props.contact_number,
        this.props.joining_date
      );
    }
  }

  setData = (
    image,
    name,
    leave_day,
    leave_day_id,
    contact_number,
    joining_date
  ) => {
    this.setState({
      image,
      name,
      leave_day,
      leave_day_id,
      contact_number,
      joining_date,
    });
  };

  static getDerivedStateFromProps(props, state) {
    console.log(`props received: ${props.name}`);

    return null;
  }

  componentDidMount() {
    this.loadAgencies();
  }

  handleChange = (selected_option) => {
    this.setState({ selected_option });
  };

  handleClick = () => {
    document.getElementById("uploadimage").click();
  };
  handleSelectLeave = (leave) => {
    this.setState({ leave_day: leave.name, leave_day_id: leave.id });
  };
  handleImageChange = (event) => {
    const fileUploaded = event.target.files[0];

    this.setState({
      image: URL.createObjectURL(fileUploaded),
      image_object: fileUploaded,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      joining_date: date,
    });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  loadAgencies = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/organizations/api/v1/agency`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
        },
      })
      .then((res) => {
        this.setState({
          agencies: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };

  handleFormSubmit = () => {
    const fd = new FormData();
    const {
      name,
      contact_number,
      selected_option,
      image_object,
      image,
      leave_day,
      joining_date,
    } = this.state;

    if (
      name &&
      contact_number &&
      selected_option &&
      leave_day &&
      joining_date
    ) {
      this.setState({ loading: true });
      if (image_object === null) {
        // fd.append("image", {})
        fd.append("image_url", image);
      } else {
        fd.append("image", image_object);
        // fd.append("image_url", null)
      }
      fd.append("name", name);
      fd.append("contact_number", contact_number);
      fd.append("reporting_agency", selected_option.id);
      fd.append("id", this.props.id);
      fd.append("leave_day", leave_day);
      fd.append(
        "joining_date",
        moment(joining_date).format("yyyy-MM-DD").toString()
      );
      console.log({
        image: fd.get("image"),
        image_url: fd.get("image_url"),
        name: fd.get("name"),
        contact_number: fd.get("contact_number"),
        reporting_agency: fd.get("reporting_agency"),
        id: fd.get("id"),
        joining_date: fd.get("joining_date"),
      });

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/admin_panel/v1/fse-create`,
          fd,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.setState({
            loading: false,
            showError: false,
            showSuccess: true,
          });
          this.props.handleSubmit();
          this.props.handleHide();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, showError: true });
          this.props.handleSubmit();
        });
    }
  };

  close_clear = () => {
    this.props.handleHide();
    this.setState({
      image_object: null,
      image: this.props.image,
      contact_number: this.props.phone,
      name: this.props.name,
      // selected_option: { id: this.props.agency_id, name: this.props.agency },
    });
    console.log(`closeclear called`);
  };

  render() {
    // const { name, contact_number, selected_option, image_object } = this.state
    return (
      <section className="editfse-modal">
        <Modal show={this.props.show} handleClose={this.hideModal}>
          <div className="modal-header">
            <div className="headline">
              <div className="main-headline">Edit FSE</div>
              <div className="sub-headline">
                Fill in the details and press Save
              </div>
            </div>
            <div className="close-button-corner" onClick={this.close_clear}>
              <img className="corner" src={close_green_corner} alt="close" />
              <img className="cross" src={close_button} alt="close" />
              {/* <button className="button--close" onClick={this.props.handleHide}>
                X
              </button> */}
            </div>
          </div>
          <div className="modal-body">
            <div className="basic">
              <div className="image">
                <img src={this.state.image} alt="basic" />
                <div className="tag" onClick={this.handleClick}>
                  <img src={picture} alt="upload" />
                  <input
                    type="file"
                    id="uploadimage"
                    onChange={this.handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className="instructions">
                <p>
                  Upload a clear picture of the FSE. Picture must be a minimum
                  of 400x400 px and maximum of 1 mb.
                </p>
              </div>
            </div>
          </div>

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <form>
              <div className="group">
                <input
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                  value={this.state.name}
                  type="text"
                  required="required"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>Full Name</label>
              </div>
              <div className="group">
                <input
                  onChange={(e) =>
                    this.setState({
                      contact_number: e.target.value,
                    })
                  }
                  value={this.state.contact_number}
                  type="text"
                  required="required"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>Phone</label>
              </div>
              <Dropdown
                className="select-agency-dd"
                title="Select Agency"
                items={this.state.agencies}
                searchable
                onChange={this.handleChange}
                default_value={{
                  id: this.props.agency_id,
                  name: this.props.agency,
                }}
              />
              <Dropdown
                className="dd-cmdo"
                title="Leave Day"
                items={weekdays}
                searchable
                default_value={{
                  id: this.props.leave_day_id,
                  name: this.props.leave_day[0],
                }}
                onChange={this.handleSelectLeave}
              />
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Joining Date"
                format="DD/MM/yyyy"
                value={this.state.joining_date}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => this.handleDateChange(date)}
              />
            </form>
          </MuiPickersUtilsProvider>

          <div className="footer">
            <BounceLoader
              className="loader"
              color={"#e2136e"}
              size={20}
              loading={this.state.loading}
            />
            <button className="button--cancel" onClick={this.close_clear}>
              {" "}
              Cancel{" "}
            </button>
            <button
              // className={
              //   name && contact_number && selected_option && image_object
              //     ? `button--save`
              //     : `button--save--disabled`
              // }
              className="button--save"
              onClick={this.handleFormSubmit}
            >
              {" "}
              Save{" "}
            </button>
          </div>
        </Modal>
      </section>
    );
  }
}

export default EditModal;

import React from "react"
import "./style.scss"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"
import { SWRConfig } from "swr"
import axios from "axios"

import Footer from "../Footer/Footer"

import KAMPerformanceCard from "./components/kamPerformanceCard"
import CallOverviewCard from "./components/callOverviewCard"
import ActiveStatusCard from "./components/activeStatusCard"
import ServicingPerformance from "./components/servicingPerformance"
// import TargetAchievement from "./components/targetAchievement"
// import ClusterPerformanceCard from "./components/clusterPerformanceCard"

const KAMDashboard = ({ date, filterElement, filterHide }) => {
  const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
  if (!localUserToken) return <Redirect to="/signin" />
  return (
    <div className="main-container">
      <div className="kam-dashboard-container">
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            fetcher: (url) =>
              axios(url, {
                headers: {
                  Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("currUserToken")),
                },
              }).then((res) => res.data),
          }}
        >
          <div className="page-header">
            <div className="page-heading">
              Dashboard
              <div className="page-sub-heading">
                {moment(date).format("ddd, MMM DD")}
              </div>
            </div>
            {/* <div className="page-settings">
              <div className="info">
                <CalendarDatePicker page={"dashboard"} date={this.props.date} />
              </div>
            </div> */}
          </div>

          <div className="card-row row">
            <ActiveStatusCard date={date} />
            <KAMPerformanceCard date={date} />
            <CallOverviewCard date={date} />
          </div>

          <div className="card-row row">
            <ServicingPerformance date={date} />
          </div>

          {/* <div className="card-row row">
            <TargetAchievement date={date} />
          </div> */}

          {/* <div className="card-row row">
            <ClusterPerformanceCard date={date} />
          </div> */}
        </SWRConfig>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.kam_dashboard
    ? state.calendarDatePicker.kam_dashboard.date
    : state.calendarDatePicker.default.date

  let filterElement = state.filterElement.kam_dashboard
    ? state.filterElement.kam_dashboard
    : state.filterElement.default

  let filterHide = state.filter.filterHide

  return { date, filterElement, filterHide }
}

export default connect(mapStateToProps)(KAMDashboard)

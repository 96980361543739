import React from "react"
import "./styles/tabs.scss"
import { connect } from "react-redux"
import { changeTable } from "../../../store/actions/servicingTableSelectAction"
import new_active from "../../../img/kyc/tabs/received_kyc_active_img.png"
import new_inactive from "../../../img/kyc/tabs/received_kyc_img.png"
import pending_active from "../../../img/kyc/tabs/processed_active_img.png"
import pending_inactive from "../../../img/kyc/tabs/processed_img.png"
import resolved_active from "../../../img/kyc/tabs/pending_processing_active_img.png"
import resolved_inactive from "../../../img/kyc/tabs/pending_processing_img.png"
import useSWR from "swr"

const Tabs = ({ active_table, changeTable }) => {
  let url = `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
  const { data } = useSWR(url)
  return (
    <div className="buttons-group">
      <div
        className={`im-btn first ${
          active_table === "new" ? `active` : `inactive`
        }`}
        onClick={() => changeTable("new")}
      >
        <img
          src={active_table === "new" ? new_active : new_inactive}
          alt="new"
        />
        <span>New</span>
        <span>{data ? data.new_count : "..."}</span>
      </div>
      <div
        className={`im-btn  ${
          active_table === "pending" ? `active` : `inactive`
        }`}
        onClick={() => changeTable("pending")}
      >
        <img
          src={active_table === "pending" ? pending_active : pending_inactive}
          alt="pending"
        />
        <span>Pending</span>
        <span>{data ? data.pending_count : "..."}</span>
      </div>
      <div
        className={`im-btn last ${
          active_table === "resolved" ? `active` : `inactive`
        }`}
        onClick={() => changeTable("resolved")}
      >
        <img
          src={
            active_table === "resolved" ? resolved_active : resolved_inactive
          }
          alt="resolved"
        />
        <span>Resolved</span>
        <span>{data ? data.resolved_count : "..."}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  let active_table = state.servicingTable.active_table

  return { active_table }
}

const mapDispatchToProps = (dispatch) => ({
  changeTable: (table) => dispatch(changeTable(table)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)

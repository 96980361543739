import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
// import FieldForceCard from "./components/FieldForceCard/FieldForceCard"
import "./index.scss"
import UserManagementSection from "./components/UserManagementSection/UserManagementSection"
// import LeaveManagementSection from "./components/LeaveManagementSection/LeaveManagementSection"
import Footer from "../Footer/Footer"
import { useNotification } from "../Notifications/NotificationProvider"

const Admin = ({ user_type }) => {
  const dispatchNotification = useNotification()

  if (user_type !== "bkash-super-user" && user_type !== "territory_manager") {
    dispatchNotification({
      type: "ERROR",
      message: "You do not have access to Admin page",
    })
    dispatchNotification({
      type: "ERROR",
      message: "Redirecting to main page",
    })
    return <Redirect to="/signin" />
  }

  return (
    <div className="main-container">
      {/* <LeaveManagementSection /> */}
      <UserManagementSection />
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => {
  let user_type = state.auth.authToken ? state.auth.authToken.type : null

  return { user_type }
}

export default connect(mapStateToProps)(Admin)

import React, { useEffect } from "react"
import "../styles/tabs.scss"
import { connect } from "react-redux"
import { changeTableFilter } from "../../../store/actions/leaderboardTableSelectAction"
import upIconActive from "../assets/up-icon-active.png"
import upIconInactive from "../assets/up-icon-inactive.png"
import DownIconActive from "../assets/down-icon-active.png"
import DownIconInactive from "../assets/down-icon-inactive.png"

const TopBottom = ({ active_table, changeTableFilter }) => {
  useEffect(() => {
    if (active_table.filter === null) {
      changeTableFilter("top")
    }
  }, [])
  return (
    <div className="buttons-group">
      <div
        className={`im-btn first ${
          active_table.filter === "top" ? `active` : `inactive`
        }`}
        onClick={() => changeTableFilter("top")}
      >
        <img
          src={active_table.filter === "top" ? upIconActive : upIconInactive}
          alt="up-icon"
        />
        <span>Top</span>
      </div>
      <div
        className={`im-btn last ${
          active_table.filter === "bottom" ? `active` : `inactive`
        }`}
        onClick={() => changeTableFilter("bottom")}
      >
        <span>Bottom</span>
        <img
          src={
            active_table.filter === "bottom" ? DownIconActive : DownIconInactive
          }
          alt="up-icon"
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  let active_table = state.leaderboardPage.active_table

  return { active_table }
}

const mapDispatchToProps = (dispatch) => ({
  changeTableFilter: (table) => dispatch(changeTableFilter(table)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TopBottom)

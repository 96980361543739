import React, { useState, useMemo, useEffect } from "react"
import { connect } from "react-redux"
import "./styles/strategicTransfers.scss"
import moment from "moment"
import daterange2Icon from "../../../img/kam_transfer/daterange2Icon.svg"
import emailIcon from "../../../img/kam_transfer/email.svg"
import downloadIcon from "../../../img/kam_transfer/download.svg"
import eyeCon from "../../../img/kam_transfer/ic_eye.svg"
import commentIcon from "../../../img/kam_transfer/comment.svg"
import LoadingCard from "./loadingCard"
import Table from "./customTable"
import axios from "axios"
import { useNotification } from "../../Notifications/NotificationProvider"
import { trigger } from "swr"
import EmailModal from "./EmailModal"
import { ReactComponent as CopyIcon } from "../../../img/copy.svg"
import { CopyToClipboard } from "react-copy-to-clipboard"
import DownloadReportButton from "./DownloadReportButton"
import BounceLoader from "react-spinners/BounceLoader"

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
}

const strategicTransfers = {
  card_heading: "Pending",
}

// Column structure to feed into react-table

// Simple button for marking all or individually
// CSS styles are in strategicTransfers.scss
const AcknowlegeButton = (props) => (
  <button {...props} className="ack-btn">
    {props.children}
  </button>
)
const IconButton = (props) => (
  <button {...props} className="icn-btn-small">
    {props.children}
  </button>
)

const PendingServices = ({
  issue,
  date,
  dateRaw,
  search,
  pageLimit,
  currentPage,
}) => {
  const getDate = () => {
    if (dateRaw.servicing) {
      if (Array.isArray(date)) {
        return `&start_time=${moment(date[0]).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date[1]).format("YYYY-MM-DD")}`
      } else {
        // console.log("date not array");
        return `&start_time=${moment(date).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date).format("YYYY-MM-DD")}`
      }
    } else return ""
  }

  let url = `${process.env.REACT_APP_KAM_API_URL}services/v1/list?tab=pending${
    search.length > 0 ? `&search_term=${search}` : ``
  }${
    issue.feedback ? `&issue=${issue.feedback}` : ``
  }${getDate()}&page_index=${currentPage}&page_size=${parseInt(pageLimit)}`

  // Global state for selected items
  const [selectedRows, setSelectedRows] = useState([])
  const [rowsToPost, setRowsToPost] = useState([])
  const dispatchNotification = useNotification()
  const [data, setAxData] = useState({})
  const [loading, setLoading] = useState(true)
  const [total, setTotalCount] = useState(0)
  const [modal, set_modal] = useState(false)
  const [email, setEmail] = useState(false)
  const [comment, setComment] = useState(false)
  const [image, setImage] = useState(false)
  const [modalData, setModalData] = useState("")
  const [daterange, setDateRange] = useState(false)
  const [selectedReports, setSelectedReports] = useState({})
  let [loadingReport, setLoadingReport] = useState(false)
  let [color, setColor] = useState("#e2136e")

  const getData = () => {
    setLoading(true)
    axios
      .get(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
        },
      })
      .then((res) => {
        setAxData(res.data.results)
        setTotalCount(res.data.pending_count)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => getData(), [issue, date, search, pageLimit, currentPage])

  // Memoized columns and data for table generation
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => (
          <CopyToClipboard text={row.original.id}>
            <span
              onClick={() =>
                alert("Copied ID " + row.original.id + " to clipboard")
              }
              style={{ display: "flex" }}
            >
              {row.original.id.slice(0, 6)}...{" "}
              <CopyIcon fill="#e2136e" width="1.2em" />
            </span>
          </CopyToClipboard>
        ),
      },
      {
        Header: "Wallet Number",
        accessor: "outlet__wallet_no",
      },
      {
        Header: "Merchant Name",
        accessor: "outlet__brand_name",
      },
      {
        Header: "KAM",
        accessor: "kam__name",
      },
      {
        Header: "Zone",
        accessor: "outlet__cluster_code__zone__name",
      },
      {
        Header: "Date",
        accessor: "zone_aware_created_date",
        Cell: ({ row }) => (
          <span>
            {moment(row.original.zone_aware_created_date).format("Do MMM")}
          </span>
        ),
      },
      {
        Header: "",
        id: "image-btn",
        Cell: ({ row }) =>
          row.original.call_feedback__image && (
            <img
              src={eyeCon}
              width="24px"
              alt="download file"
              onClick={() => {
                const multiple = row.original.call_feedback__image.includes(",")

                if (multiple) {
                  setImage(true)
                  setModalData(row.original.call_feedback__image.split(","))
                } else {
                  setImage(true)
                  setModalData(row.original.call_feedback__image)
                }
                setComment(false)
                setEmail(false)
                set_modal(true)
              }}
            />
          ),
      },
      {
        Header: "Issue",
        accessor: "call_feedback__feedback",
        Cell: ({ row }) => (
          <div className="issue-container">
            <span className="issue-title">
              {row.original.call_feedback__feedback}
            </span>
            {/* <span className="issue-desc">{row.original.issue.detail}</span> */}
          </div>
        ),
      },
      {
        Header: "",
        id: "date-btn",
        Cell: ({ row }) =>
          row.original.call_feedback__start_date && (
            <IconButton
              onClick={() => {
                let formattedSEdate = {
                  sdate: row.original.call_feedback__start_date,
                  edate: row.original.call_feedback__end_date,
                }
                setComment(false)
                setEmail(false)
                set_modal(true)
                setDateRange(true)
                setModalData(formattedSEdate)
              }}
            >
              <img src={daterange2Icon} alt="open date range" />
            </IconButton>
          ),
      },
      {
        Header: "",
        id: "email-btn",
        Cell: ({ row }) =>
          row.original.call_feedback__email && (
            <IconButton
              onClick={() => {
                setComment(false)
                setEmail(true)
                set_modal(true)
                setModalData(row.original.call_feedback__email)
              }}
            >
              <img src={emailIcon} alt="open email" />
            </IconButton>
          ),
      },
      {
        Header: "",
        id: "comment-btn",
        Cell: ({ row }) =>
          row.original.call_feedback__comment && (
            <IconButton
              onClick={() => {
                setEmail(false)
                setComment(true)
                set_modal(true)
                setModalData(row.original.call_feedback__comment)
              }}
            >
              <img src={commentIcon} alt="open email" />
            </IconButton>
          ),
      },
      // {
      //   Header: "",
      //   id: "spinner",
      //   Cell: ({ row }) => (
      //     <div>
      //       <BounceLoader
      //         color={color}
      //         loading={true}
      //         css={override}
      //         size={12}
      //       />
      //     </div>
      //   ),
      // },
      {
        Header: "",
        id: "download-btn",
        Cell: ({ row }) => (
          <img
            src={downloadIcon}
            width="24px"
            alt="download file"
            disabled={loadingReport}
            onClick={() => {
              setLoadingReport(true)

              const requestBody = {
                category: issue.category,
                issue: row.original.call_feedback__feedback,
                bulk: false,
                services: [
                  {
                    id: row.original.id,
                    wallet_no: row.original.outlet__wallet_no,
                    branch_name: row.original.outlet__branch_name,
                    brand_name: row.original.outlet__brand_name,
                    comment: row.original.call_feedback__comment,
                    status: row.original.status,
                    kam_email: row.original.kam__email,
                    call_feedback_email: row.original.call_feedback__email,
                    call_feedback_image: row.original.call_feedback__image,
                    call_feedback_start_date:
                      row.original.call_feedback__start_date,
                    call_feedback_end_date:
                      row.original.call_feedback__end_date,
                    created_date: row.original.zone_aware_created_date,
                    call_feedback_new_address:
                      row.original.call_feedback__new_address,
                    call_feedback_old_address:
                      row.original.call_feedback__old_address,
                    call_feedback_old_mno: row.original.call_feedback__old_mno,
                    call_feedback_new_mno: row.original.call_feedback__new_mno,
                  },
                ],
              }

              axios
                .post(
                  `${process.env.REACT_APP_KAM_API_URL}services/v1/generate-eml-report`,
                  requestBody,
                  {
                    headers: {
                      Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("currUserToken")),
                    },
                    responseType: "blob",
                  }
                )
                .then((res) => {
                  dispatchNotification({
                    type: "SUCCESS",
                    message: "Report fetched successfully",
                  })

                  const url = window.URL.createObjectURL(new Blob([res.data]))
                  const link = document.createElement("a")
                  link.href = url
                  link.setAttribute(
                    "download",
                    `${row.original.call_feedback__feedback}_${
                      Array.isArray(date)
                        ? `${moment(date[0]).format("YYYY-MM-DD")}_${moment(
                            date[1]
                          ).format("YYYY-MM-DD")}`
                        : moment(date).format("YYYY-MM-DD")
                    }_individual.eml`
                  ) //or any other extension
                  document.body.appendChild(link)
                  link.click()
                  setLoadingReport(false)
                })
                .catch((err) => {
                  dispatchNotification({
                    type: "ERROR",
                    message: "Could not fetch report",
                  })
                  setLoadingReport(false)
                })
            }}
          />
        ),
      },
      {
        Header: "",
        id: "button",
        Cell: ({ row }) => (
          <AcknowlegeButton
            onClick={async () => {
              setLoading(true)
              await axios
                .post(
                  `${process.env.REACT_APP_KAM_API_URL}services/v1/create-history`,
                  {
                    services: [
                      { id: row.original.id, service_type: "service_resolved" },
                    ],
                  },
                  {
                    headers: {
                      Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("currUserToken")),
                    },
                  }
                )
                .then((res) => {
                  getData()
                  trigger(
                    `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                  )
                  dispatchNotification({
                    type: "SUCCESS",
                    message: "Marked Resolved Successfully",
                  })
                })
                .catch((err) => {
                  console.error(err)
                  trigger(
                    `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                  )
                  setLoading(false)
                })
            }}
          >
            Mark Resolved
          </AcknowlegeButton>
        ),
      },
    ],
    [issue, date, search, currentPage, pageLimit]
  )

  const getSelectedReport = () => {
    const requestBody = {
      category: issue.category,
      issue: issue.feedback,
      bulk: false,
      services: selectedReports,
    }

    console.log(selectedReports)

    setLoadingReport(true)

    axios
      .post(
        `${process.env.REACT_APP_KAM_API_URL}services/v1/generate-eml-report`,
        requestBody,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        dispatchNotification({
          type: "SUCCESS",
          message: "Report fetched successfully",
        })
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `${issue.feedback}_${
            Array.isArray(date)
              ? `${moment(date[0]).format("YYYY-MM-DD")}_${moment(
                  date[1]
                ).format("YYYY-MM-DD")}`
              : moment(date).format("YYYY-MM-DD")
          }_bulk.eml`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
        setLoadingReport(false)
      })
      .catch((err) => {
        dispatchNotification({
          type: "ERROR",
          message: "Could not fetch report",
        })

        setLoadingReport(false)
      })
  }

  // Preparing table props to be passed into the dom

  const handleChange = (arr) => {
    const changedArr = {
      services: arr.map((el) => ({
        id: el.id,
        service_type: "service_resolved",
      })),
    }

    const changedArrForReport = arr.map((el) => ({
      id: el.id,
      wallet_no: el.outlet__wallet_no,
      branch_name: el.outlet__branch_name,
      brand_name: el.outlet__brand_name,
      comment: el.call_feedback__comment,
      status: el.status,
      kam_email: el.kam__email,
      call_feedback_email: el.call_feedback__email,
      call_feedback_image: el.call_feedback__image,
      call_feedback_start_date: el.call_feedback__start_date,
      call_feedback_end_date: el.call_feedback__end_date,
      created_date: el.zone_aware_created_date,
      call_feedback_new_address: el.call_feedback__new_address,
      call_feedback_old_address: el.call_feedback__old_address,
      call_feedback_old_mno: el.call_feedback__old_mno,
      call_feedback_new_mno: el.call_feedback__new_mno,
    }))

    setSelectedReports(changedArrForReport)
    setRowsToPost(changedArr)
    setSelectedRows(arr)
  }

  return (
    <div className="card-col col s12 l12">
      {loading ? (
        <div>
          <LoadingCard />
        </div>
      ) : (
        <div className="card strategic-transfer-card">
          <div className="card-title">
            <span>{strategicTransfers.card_heading}</span>
            <AcknowlegeButton
              disabled={selectedRows.length <= 0}
              onClick={() => {
                axios
                  .post(
                    `${process.env.REACT_APP_KAM_API_URL}services/v1/create-history`,
                    rowsToPost,
                    {
                      headers: {
                        Authorization:
                          "Bearer " +
                          JSON.parse(localStorage.getItem("currUserToken")),
                      },
                    }
                  )
                  .then((res) => {
                    getData()
                    dispatchNotification({
                      type: "SUCCESS",
                      message: "Successfully Marked Selected as Resolved",
                    })
                    trigger(
                      `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                    )
                  })
                  .catch((err) => {
                    trigger(
                      `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                    )
                    console.log(`Something went wrong, ${err}`)
                  })
              }}
            >
              Mark Selected as Resolved
            </AcknowlegeButton>

            <DownloadReportButton
              disabled={
                selectedRows.length <= 0 || !issue.feedback || loadingReport
              }
              onClick={getSelectedReport}
            >
              <BounceLoader
                color={color}
                loading={loadingReport}
                css={override}
                size={16}
              />
              Download Selected
            </DownloadReportButton>
          </div>
          <div className="card-body">
            {/* Table generated dynamically with React - Table v7.6 */}
            {/* See docs at https://react-table.tanstack.com/docs/quick-start */}
            <Table
              columns={columns}
              data={data}
              handleChange={handleChange}
              // onFetchData={console.log}
            />
          </div>
        </div>
      )}
      <EmailModal
        show={modal}
        handleHide={() => set_modal(false)}
        modalType={
          email ? `email` : comment ? `comment` : image ? `image` : `daterange`
        }
        data={modalData}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.servicing
    ? state.calendarDatePicker.servicing.date
    : state.calendarDatePicker.default.date

  let dateRaw = state.calendarDatePicker
  return { date, dateRaw }
}

export default connect(mapStateToProps)(PendingServices)

import React from "react"
// import axios from "axios";
// import http from "../../../utilities/_http";
import moment from "moment"
import PropTypes from "prop-types"

import { query_generate } from "../../../utilities/_query_generate"
import Chart from "react-apexcharts"

import LoadingCard from "./loadingCard"

import inc_img from "./../../../img/dashboard_card/inc_img.svg"
import dec_img from "./../../../img/dashboard_card/dec_img.svg"
import mdo_performance_img from "./../../../img/dashboard_card/MDO_performance.svg"

import "./../styles/mdoPerformanceCard.scss"
import useSWR from "swr"

const mdo_performance = {
  card_heading: "Daily Performance",
  state: "mdo_performance_state",
  api_link: `api/reports/v2/mdo-performance-report`,
  inc_img: inc_img,
  dec_img: dec_img,
  conrner_right_top_img: mdo_performance_img,
}

const options = {
  chart: {
    height: 320,
    type: "radialBar",
  },
  colors: ["#f5beb4"],
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      hollow: {
        size: "79%",
      },

      track: {
        background: "#F9EBF1",
        startAngle: -135,
        endAngle: 135,
      },
      dataLabels: {
        name: {
          show: true,
          label: "Targets Achieved",
          fontSize: "12px",
          color: "#707070",
          fontFamily: "Roboto', sans-serif",
          fontWeight: 300,
        },
        value: {
          fontFamily: "Roboto', sans-serif",
          fontSize: "24px",
          fontWeight: 500,
          color: "#434364",
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      gradientToColors: ["#e2136e"],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Target Achieved"],
}

/**
 * @description Newly designed MDO Performance card showing total calls and difference from last week. Also,
 *  Calls per active MDO and difference with last week.
 * @component MDO Performance Card 2
 * @category Dashboard
 * @author Zoha
 */
const MDOPerformanceCard2 = (props) => {
  let query_pre_process = [
    {
      key: "team",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.team
          : [],
    },
    {
      key: "region",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.region
          : [],
    },
    {
      key: "area",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.area
          : [],
    },
    {
      key: "territory",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.territory
          : [],
    },
    {
      key: "agency",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.agency
          : [],
    },
  ]
  let query = query_generate(query_pre_process)

  const url = `${process.env.REACT_APP_API_URL}/${
    mdo_performance["api_link"]
  }?date=${moment(props.date).format("YYYY-MM-DD")}${
    query !== "" ? "&" + query : ""
  }`

  const { data: mdo_performance_state } = useSWR(url)

  return (
    <div className="card-col col  s12 m6 l6">
      {!mdo_performance_state ? (
        <LoadingCard card_heading={`${mdo_performance.card_heading}`} />
      ) : (
        <div className="card data-cardxx">
          {mdo_performance.conrner_right_top_img !== "none" ? (
            <img
              className="img card-corner"
              src={mdo_performance.conrner_right_top_img}
              alt="card-corner"
            />
          ) : null}
          <div className="card-title">{mdo_performance.card_heading}</div>
          {mdo_performance_state && mdo_performance_state.current && (
            <div className="card-body">
              <div className="rad-chart">
                <Chart
                  options={options}
                  series={[
                    Math.round(
                      (mdo_performance_state.current.call_per_mdo / 35) * 100
                    ),
                  ]}
                  type="radialBar"
                  height={320}
                />
              </div>

              <div className="card-content-container">
                <div className="row1">
                  <div className="total-calls">
                    <div className="value">
                      {mdo_performance_state.current.call_count}
                    </div>
                    <div className="tag">Total Calls</div>
                  </div>

                  <div className="comparison">
                    <div
                      className={`value ${
                        mdo_performance_state.deltas.call_count
                          .with_last_week >= 0
                          ? `positive`
                          : `negative`
                      }`}
                    >
                      {mdo_performance_state.deltas.call_count.with_last_week >=
                      0
                        ? `+`
                        : ``}
                      {mdo_performance_state.deltas.call_count.with_last_week}%
                    </div>
                    <div className={`tag`}>From Last Week</div>
                  </div>
                </div>

                <div className="row2">
                  <div className="active-mdo">
                    <div className="value">
                      {mdo_performance_state.current.call_per_mdo}
                    </div>
                    <div className="tag">Calls/Active MDO</div>
                  </div>

                  <div className="comparison">
                    <div
                      className={`value ${
                        mdo_performance_state.deltas.call_rate.with_last_week >=
                        0
                          ? `positive`
                          : `negative`
                      }`}
                    >
                      {mdo_performance_state.deltas.call_rate.with_last_week >=
                      0
                        ? `+`
                        : ``}
                      {mdo_performance_state.deltas.call_rate.with_last_week}%
                    </div>
                    <div className={`tag`}>From Last Week</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MDOPerformanceCard2

MDOPerformanceCard2.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

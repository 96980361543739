import React, { useCallback, useState } from "react"
import "./UploadFile.styles.scss"
import axios from "axios"
import { useDropzone } from "react-dropzone"

import uploadImage from "../../../img/kyc/upload/Approved.svg"
import UploadConfirmationModal from "./uploadConfirmationModal"

const UploadFile = ({ heading, help, imageFile, api }) => {
  const [modal, set_modal] = useState(false)
  const [uploading, set_uploading] = useState(false)
  const [response, set_response] = useState("")

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    uploadFile(acceptedFiles[0])

    console.log(acceptedFiles)
  }, [])
  const { acceptedFiles, rejectedFiles, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
      accept:
        "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      init: function () {
        this.on("addedfile", function (file) {
          if (!file.type) {
            var ext = file.name.split(".").pop()
            console.log("extension: " + ext)
            if (ext === "xls") {
              Object.defineProperty(file, "type", {
                value: "application/vnd.ms-excel",
              }) // MIME type
            }
          }
        })
      },
      onDrop,
    })

  const uploadFile = (file) => {
    set_modal(false)
    set_uploading(true)
    const formData = new FormData()

    formData.append("file", file)

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/uploads/v1${api}`, formData, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
        },
      })
      .then((res) => {
        set_uploading(false)
        set_modal(true)
        set_response(res.data)
        console.log(res)
      })
      .catch((err) => {
        set_uploading(false)
        set_modal(true)
        set_response(err)
        console.log(err)
      })
  }

  const remove = (file) => {
    // const newFiles = [...files] // make a var for the new array
    acceptedFiles.splice(file, 1) // remove the file from the array
  }

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path} onClick={() => remove(file)}>
      {file.path} - {Math.round(file.size / 1000)} kilobytes
    </li>
  ))

  const fileRejectionItems = rejectedFiles.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <div className="file-container">
      <div {...getRootProps({ className: "upload-module-container" })}>
        <input {...getInputProps()} />
        <div className="image-column">
          <img src={imageFile ? imageFile : uploadImage} alt="upload Image" />
        </div>

        <div className="text-column">
          <span className="heading">
            {heading ? heading : "Missing Heading"}
          </span>
          <span className="help">
            {help ? help : "Drop a file here, or Select From Computer..."}
          </span>
        </div>
        {uploading && <ProgressLoader />}
        <ul>{acceptedFileItems}</ul>
        <ul>{fileRejectionItems}</ul>
      </div>
      <UploadConfirmationModal
        show={modal}
        handleHide={() => set_modal(false)}
        data={response}
      />
    </div>
  )
}

const ProgressLoader = () => (
  <div className="progress">
    <div className="indeterminate"></div>
  </div>
)

export default UploadFile

const INTITIAL_STATE = {
  active_table: "new",
};

const servicingTableSelectReducer = (state = INTITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_SERVICING_TABLE":
      return {
        ...state,
        active_table: action.payload,
      };
    default:
      return state;
  }
};

export default servicingTableSelectReducer;

const d = new Date()
const options = { month: "long", year: "numeric" }

const INTITIAL_STATE = {
  active_table: {
    section: "",
    table: null,
    filter: null,
    search: "",
  },
  profile: {
    past_months: [],
    current_month: new Intl.DateTimeFormat("en-US", options).format(d),
  },
}

const servicingTableSelectReducer = (state = INTITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_LEADERBOARD_TABLE":
      return {
        ...state,
        active_table: action.payload,
      }
    case "CHANGE_LEADERBOARD_TABLE_FILTER":
      return {
        ...state,
        active_table: {
          ...state.active_table,
          filter: action.payload,
        },
      }
    case "SET_LEADERBOARD_SEARCH":
      return {
        ...state,
        active_table: {
          ...state.active_table,
          search: action.payload,
        },
      }
    case "SET_PROFILE_PAST_MONTHS":
      return {
        ...state,
        profile: {
          ...state.profile,
          past_months: action.payload,
        },
      }
    case "SET_PROFILE_CURRENT_MONTH":
      return {
        ...state,
        profile: {
          ...state.profile,
          current_month: action.payload,
        },
      }
    default:
      return state
  }
}

export default servicingTableSelectReducer

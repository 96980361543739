import React, { useState, useMemo, useEffect } from "react"
import { connect } from "react-redux"
import "./styles/strategicTransfers.scss"
import moment from "moment"
import emailIcon from "../../../img/kam_transfer/email.svg"
import daterange2Icon from "../../../img/kam_transfer/daterange2Icon.svg"
import commentIcon from "../../../img/kam_transfer/comment.svg"
import downloadIcon from "../../../img/kam_transfer/download.svg"
import eyeCon from "../../../img/kam_transfer/ic_eye.svg"
import LoadingCard from "./loadingCard"
import { trigger } from "swr"
import axios from "axios"
import Table from "./customTable"
import { useNotification } from "../../Notifications/NotificationProvider"
import EmailModal from "./EmailModal"
import { ReactComponent as CopyIcon } from "../../../img/copy.svg"
import { CopyToClipboard } from "react-copy-to-clipboard"

// import dummy from "./formData"

const strategicTransfers = {
  card_heading: "New Requests",
}

// Simple button for marking all or individually
// CSS styles are in strategicTransfers.scss
const AcknowlegeButton = (props) => (
  <button {...props} className="ack-btn">
    {props.children}
  </button>
)
const IconButton = (props) => (
  <button {...props} className="icn-btn-small">
    {props.children}
  </button>
)

const NewServices = ({
  issue,
  date,
  dateRaw,
  search,
  pageLimit,
  currentPage,
}) => {
  const getDate = () => {
    if (dateRaw.servicing) {
      if (Array.isArray(date)) {
        return `&start_time=${moment(date[0]).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date[1]).format("YYYY-MM-DD")}`
      } else {
        // console.log("date not array");
        return `&start_time=${moment(date).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date).format("YYYY-MM-DD")}`
      }
    } else return ""
  }

  let url = `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks?${
    search.length > 0 ? `search_term=${search}` : ``
  }${
    issue.feedback ? `&issue=${issue.feedback}` : ``
  }${getDate()}&page_index=${currentPage}&page_size=${parseInt(pageLimit)}`

  // ${getDate()}
  // Global state for selected items
  const [selectedRows, setSelectedRows] = useState([])
  const [rowsToPost, setRowsToPost] = useState([])
  const dispatchNotification = useNotification()
  const [data, setAxData] = useState({})
  const [loading, setLoading] = useState(true)
  const [modal, set_modal] = useState(false)
  const [email, setEmail] = useState(false)
  const [comment, setComment] = useState(false)
  const [image, setImage] = useState(false)
  const [modalData, setModalData] = useState("")
  const [daterange, setDateRange] = useState(false)

  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  const getData = () => {
    setLoading(true)
    axios
      .get(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
        },
      })
      .then((res) => {
        setAxData(res.data.results)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    getData()
  }, [issue, date, search, currentPage, pageLimit])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => (
          <CopyToClipboard text={row.original.id}>
            <span
              onClick={() =>
                alert("Copied ID " + row.original.id + " to clipboard")
              }
              style={{ display: "flex" }}
            >
              {row.original.id.slice(0, 6)}...{" "}
              <CopyIcon fill="#e2136e" width="1.2em" />
            </span>
          </CopyToClipboard>
        ),
      },
      {
        Header: "Wallet Number",
        accessor: "call__outlet__wallet_no",
      },
      {
        Header: "Merchant Name",
        accessor: "call__outlet__branch_name",
      },
      {
        Header: "KAM",
        accessor: "call__outlet__assigned_kam__name",
      },
      {
        Header: "Zone",
        accessor: "call__outlet__cluster_code__zone__name",
      },
      {
        Header: "Date",
        accessor: "zone_aware_created_date",
        Cell: ({ row }) => (
          <span>
            {moment(row.original.zone_aware_created_date).format("Do MMM")}
          </span>
        ),
      },
      {
        Header: "",
        id: "image-btn",
        Cell: ({ row }) =>
          row.original.image && (
            <img
              src={eyeCon}
              width="24px"
              alt="download file"
              onClick={() => {
                const multiple = row.original.image.includes(",")

                if (multiple) {
                  setImage(true)
                  setModalData(row.original.image.split(","))
                } else {
                  setImage(true)
                  setModalData(row.original.image)
                }
                setComment(false)
                setEmail(false)
                set_modal(true)
              }}
            />
          ),
      },
      {
        Header: "Issue",
        accessor: "feedback",
        Cell: ({ row }) => (
          <div className="issue-container">
            <span className="issue-title">{row.original.feedback}</span>
            {/* <span className="issue-desc">{row.original.issue.detail}</span> */}
          </div>
        ),
      },
      {
        Header: "",
        id: "date-btn",
        Cell: ({ row }) =>
          row.original.start_date && (
            <IconButton
              onClick={() => {
                let formattedSEdate = {
                  sdate: row.original.start_date,
                  edate: row.original.end_date,
                }
                setComment(false)
                setEmail(false)
                set_modal(true)
                setDateRange(true)
                setModalData(formattedSEdate)
              }}
            >
              <img src={daterange2Icon} alt="open daterange" />
            </IconButton>
          ),
      },
      {
        Header: "",
        id: "email-btn",
        Cell: ({ row }) =>
          row.original.email && (
            <IconButton
              onClick={() => {
                setComment(false)
                setEmail(true)
                set_modal(true)
                setModalData(row.original.email)
              }}
            >
              <img src={emailIcon} alt="open email" />
            </IconButton>
          ),
      },
      {
        Header: "",
        id: "comment-btn",
        Cell: ({ row }) =>
          row.original.comment && (
            <IconButton
              onClick={() => {
                setEmail(false)
                setComment(true)
                set_modal(true)
                setModalData(row.original.comment)
              }}
            >
              <img src={commentIcon} alt="open email" />
            </IconButton>
          ),
      },
      {
        Header: "",
        id: "button",
        Cell: ({ row }) => (
          <div className="ack-btn-container">
            <AcknowlegeButton
              onClick={async () => {
                setLoading(true)
                await axios
                  .post(
                    `${process.env.REACT_APP_KAM_API_URL}services/v1/create`,
                    {
                      feedbacks: [{ feedback: row.original.id }],
                    },
                    {
                      headers: {
                        Authorization:
                          "Bearer " +
                          JSON.parse(localStorage.getItem("currUserToken")),
                      },
                    }
                  )
                  .then((res) => {
                    getData()
                    trigger(
                      `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                    )
                    dispatchNotification({
                      type: "SUCCESS",
                      message: "Marked Acknowledged Successfully",
                    })
                  })
                  .catch((err) => {
                    console.error(err)
                    trigger(
                      `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                    )

                    setLoading(false)
                  })
              }}
            >
              Mark Acknowledged
            </AcknowlegeButton>
          </div>
        ),
      },
    ],
    [issue, date, search, currentPage, pageLimit]
  )

  const handleChange = (arr) => {
    const changedArr = {
      feedbacks: arr.map((el) => ({
        feedback: el.id,
      })),
    }

    setRowsToPost(changedArr)
    setSelectedRows(arr)
  }

  return (
    <div className="card-col col s12 l12">
      {loading ? (
        <div>
          <LoadingCard />
        </div>
      ) : (
        <div className="card strategic-transfer-card">
          <div className="card-title">
            <span>{strategicTransfers.card_heading}</span>
            <AcknowlegeButton
              disabled={selectedRows.length <= 0}
              onClick={async () => {
                await axios
                  .post(
                    `${process.env.REACT_APP_KAM_API_URL}services/v1/create`,
                    rowsToPost,
                    {
                      headers: {
                        Authorization:
                          "Bearer " +
                          JSON.parse(localStorage.getItem("currUserToken")),
                      },
                    }
                  )
                  .then((res) => {
                    getData()
                    trigger(
                      `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                    )
                    dispatchNotification({
                      type: "SUCCESS",
                      message: "Successfully Marked Selected as Acknowledged",
                    })
                  })

                  .catch((err) => {
                    trigger(
                      `${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
                    )
                    console.log(`Something went wrong, ${err}`)
                  })
              }}
            >
              Mark Selected as Acknowledged
            </AcknowlegeButton>
          </div>

          <div className="card-body">
            {/* Table generated dynamically with React - Table v7.6 */}
            {/* See docs at react-table.tanstack.com/docs/quick-start */}

            <Table columns={columns} data={data} handleChange={handleChange} />
          </div>
        </div>
      )}
      <EmailModal
        show={modal}
        handleHide={() => set_modal(false)}
        modalType={
          email ? `email` : comment ? `comment` : image ? `image` : `daterange`
        }
        data={modalData}
      />
    </div>
  )
}
const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.servicing
    ? state.calendarDatePicker.servicing.date
    : state.calendarDatePicker.default.date

  let dateRaw = state.calendarDatePicker
  return { date, dateRaw }
}
export default connect(mapStateToProps)(NewServices)

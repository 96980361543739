import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { signIn } from "../../store/actions/authAction"
import { Redirect } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { useNotification } from "../Notifications/NotificationProvider"

import PulseLoader from "react-spinners/PulseLoader"

import sign_in_img from "./../../img/sign_in/sign_in.svg"
import Footer from "../Footer/Footer"

const SignIn = (props) => {
  const [state, setState] = useState({ username: "", password: "" })
  const reRef = useRef()
  const dispatchNotification = useNotification()

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    })
  }

  useEffect(() => {
    if (props.authStatusCode && props.authStatusCode.toString() === "429") {
      dispatchNotification({
        type: "ERROR",
        message:
          "Too Many Requests. Please Try After " +
          Math.round(props.authFreeze / 60) +
          " Minutes",
      })
    } else if (
      props.authStatusCode &&
      props.authStatusCode.toString() === "401"
    ) {
      dispatchNotification({
        type: "ERROR",
        message: "Incorrect Username or Password. Please Try Again.",
      })
    } else if (
      props.authStatusCode &&
      props.authStatusCode.toString() === "200"
    ) {
      dispatchNotification({
        type: "SUCCESS",
        message: "Successfully Logged In.",
      })
    } else {
      return
    }
  }, [props.authStatusCode])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const token = await reRef.current.executeAsync()

    reRef.current.reset()
    console.log(token)

    props.signIn(state, token)
  }

  const {
    authToken,
    logging_in,
    authResponseMessage,
    authStatusCode,
    authFreeze,
  } = props
  if (authToken && authToken.token && authToken.type === "bkash_user")
    return <Redirect to="/kyc" />

  if (authToken && authToken.token) return <Redirect to="/" />

  // if (authStatusCode.toString() === "429") {

  // }

  return (
    <div className="signIn main-container">
      <img src={sign_in_img} alt="sign-in" />

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        size="invisible"
        ref={reRef}
      />

      <form onSubmit={handleSubmit} className="white" autoComplete="off">
        <h5 className="grey-text text-darken-3">Log In</h5>
        <div className="input-field">
          <i className="material-icons prefix">account_circle</i>
          <label htmlFor="username">User Name</label>
          <input type="text" id="username" onChange={handleChange} />
        </div>

        <div className="input-field">
          <i className="material-icons prefix">vpn_key</i>
          <label htmlFor="password">Password</label>
          <input type="password" id="password" onChange={handleChange} />
        </div>

        <div className="">
          <button
            style={custombuttonstyles}
            className="submit-form btn pink lighten-1 z-depth-0"
          >
            Sign In
            <PulseLoader
              css={override}
              size={10}
              color={"#fff"}
              loading={logging_in}
            />
          </button>
          {/* <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                        </div> */}
        </div>
      </form>
      {authStatusCode && authStatusCode.toString() === "429" && (
        <span>
          If {Math.round(authFreeze / 60)} minutes have passed, please try to
          again.
        </span>
      )}
      {authStatusCode && authStatusCode.toString() === "401" && (
        <span>Username or password is incorrect, please try again.</span>
      )}

      <Footer single="signin" />
    </div>
  )
}
const custombuttonstyles = {
  position: "relative",
}

const override = {
  position: "absolute",
  right: "20px",
  top: "12px",
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authToken: state.auth.authToken,
    logging_in: state.auth.logging_in,
    authStatusCode: state.auth.authStatusCode
      ? state.auth.authStatusCode
      : null,
    authResponseMessage: state.auth.authResponseMessage
      ? state.auth.authResponseMessage
      : null,
    authFreeze: state.auth.authFreeze,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds, rtoken) => dispatch(signIn(creds, rtoken)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)

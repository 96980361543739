import React from "react"
import useSWR from "swr"
import PropTypes from "prop-types"

import moment from "moment"
import {
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ResponsiveContainer,
  LabelList,
} from "recharts"

import { query_generate } from "../../../utilities/_query_generate"

import LoadingCard from "./loadingCard"

import call_overview_img from "./../../../img/dashboard_card/call_overview.svg"

import "./../styles/callOverviewCard.scss"

const call_overview = {
  card_heading: "Call Overview",
  state: "call_overview_state",
  api_link: `api/reports/v3/call-type-wise-report`,
  conrner_right_top_img: call_overview_img,
}
const renderCustomizedLabel = ({ x, y = 25, width, height, value }) => (
  <g>
    <text
      x={
        value >= 0 && value <= 9
          ? 92
          : value >= 10 && value <= 99
          ? 99
          : value >= 100 && value <= 999
          ? 106
          : 110
      }
      y={y - height}
      fill="#434364"
      textAnchor="end"
      dominantBaseline="baseline"
    >
      {value}
    </text>
  </g>
)

/**
 * @description Newly designed Call Overview Card that shows a bar graph of call objectives.
 * Each are compared with Today, Yesterday and Last Week.
 * @component Call Overview Card 2
 * @category Dashboard
 * @author Zoha
 */

const CallOverviewCard2 = (props) => {
  let query_pre_process = [
    {
      key: "team",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.team
          : [],
    },
    {
      key: "region",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.region
          : [],
    },
    {
      key: "area",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.area
          : [],
    },
    {
      key: "territory",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.territory
          : [],
    },
    {
      key: "agency",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.agency
          : [],
    },
  ]

  let query = query_generate(query_pre_process)

  const url = `${process.env.REACT_APP_API_URL}/${
    call_overview["api_link"]
  }?date=${moment(props.date).format("YYYY-MM-DD")}${
    query !== "" ? "&" + query : ""
  }`
  const { data } = useSWR(url)
  return (
    <div className="card-col col s12 m12 l12">
      {!data ? (
        <LoadingCard card_heading={`${call_overview.card_heading}`} />
      ) : (
        <div className="card data-card">
          {call_overview.conrner_right_top_img !== "none" ? (
            <img
              className="img card-corner"
              src={call_overview.conrner_right_top_img}
              alt="card-corner"
            />
          ) : null}
          <div className="card-title">{call_overview.card_heading}</div>

          <div className="card-body">
            <ResponsiveContainer>
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 5, right: 35, bottom: 5, left: 25 }}
              >
                <XAxis
                  type="number"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fontSize: 12,
                    fontFamily: "Roboto",
                    color: "#A4A4A4",
                  }}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tick={{ fontSize: 12, fontFamily: "Roboto" }}
                  tickLine={false}
                  axisLine={false}
                ></YAxis>
                <Bar
                  name="Today"
                  dataKey="today"
                  fill="#E2136E"
                  barSize={8}
                  radius={[0, 10, 10, 0]}
                  label="today"
                >
                  <LabelList dataKey="today" content={renderCustomizedLabel} />
                </Bar>
                <Bar
                  name="Yesterday"
                  dataKey="yesterday"
                  fill="#6236FF"
                  barSize={8}
                  radius={[0, 10, 10, 0]}
                />
                <Bar
                  name="Today Last Week"
                  dataKey="today_last_week"
                  fill="#00FFBC"
                  barSize={8}
                  radius={[0, 10, 10, 0]}
                />
                <LabelList dataKey="number" position="end" />

                <Legend className="co-legends" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  )
}

export default CallOverviewCard2

CallOverviewCard2.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import moment from "moment"

import DatePicker from "react-date-picker"

import { updateCalendarDatePicker } from "../../store/actions/calendarDatePickerAction"

/**
 * @global
 * @component Calender Date Picker
 * @description A dropdown material calendar for picking single or range of dates
 * @author Arafat
 */
class CalendarDatePicker extends Component {
  handleSelect = (date) =>
    date
      ? this.props.updateCalendarDatePicker({
          [this.props.page]: {
            date: date,
          },
        })
      : null

  render() {
    return (
      <div className="date-controller">
        <div
          onClick={() =>
            this.props.updateCalendarDatePicker({
              [this.props.page]: {
                date: new Date(),
              },
            })
          }
          className="today"
        >
          <div className="heading">
            <span>Today</span>
          </div>
        </div>

        <div className="calendar">
          <div className="calendar-container">
            <div className="heading">
              <span>{moment(this.props.date).format("ddd, MMM DD")}</span>
              <DatePicker
                onChange={this.handleSelect}
                value={this.props.date}
                calendarIcon={
                  <i className="material-icons prefix">date_range</i>
                }
                calendarType="Hebrew"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCalendarDatePicker: (date) =>
      dispatch(updateCalendarDatePicker(date)),
  }
}
export default connect(null, mapDispatchToProps)(CalendarDatePicker)

CalendarDatePicker.propTypes = {
  /**
   *  Specific page name to save/retrieve selected dates in that particular page
   */
  page: PropTypes.string.isRequired,
  /**
   *  Date is passed as a prop from redux store with specific page name
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   *  Action call to redux store to save selected date of a particular page
   */
  updateCalendarDatePicker: PropTypes.func.isRequired,
}

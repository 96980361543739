import React, { Component } from "react"
import axios from "axios"
import { connect } from "react-redux"
import moment from "moment"

import { query_generate } from "../../../../utilities/_query_generate"

import Table from "./components/table"

import Pagination from "../../../layout/Pagination"
// import Calendar from '../../../layout/Calendar'

import { updateFilterElement } from "../../../../store/actions/filterElementAction"
import { updateFilterHide } from "../../../../store/actions/filterAction"

import "./styles/pendingProcessing.css"

const table_col_bkash = [
  "Wallet Number",
  "Contact Person Number",
  "Outlet Name",
  "Cluster Manager",
  "Agency",
  "Spot Code",
  "Spot Code Name",
]

const table_col_agency = [
  "Wallet Number",
  "Contact Person Number",
  "Outlet Name",
  // "MDO Number",
  "MDO Name",
  "Spot Code",
  "Spot Code Name",
  "BMCC Code",
]

class PendingProcess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pending_process: null,
      total: null,
      limit: null,
      search_term: "",

      status: {
        agency_user: {
          api: "api/kyc/v1/closed-leads",
          query: [{ value: "fresh", label: "Fresh" }],
          body: undefined,
        },

        bkash_user: {
          api: "api/kyc/v1/list",
          query: [
            { value: "bkash_qc_processing", label: "bKash QC Processing" },
          ],
          body: undefined,
        },

        "bkash-super-user": {
          api: "api/kyc/v1/list",
          query: [
            {
              value: "bkash_super_user_processing",
              label: "bKash Super User Processing",
            },
          ],
          body: undefined,
        },
        cluster_manager: {
          api: "api/kyc/v1/list",
          query: [
            { value: "bkash_qc_processing", label: "bKash QC Processing" },
          ],
          body: undefined,
        },
      },
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.currentPage !== prevProps.currentPage ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set()
  }

  set_data_set = () => {
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))

    let query = query_generate([
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "cluster_manager",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.managers
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
      {
        key: "tab",
        values:
          this.state.status[`${localUserType}`]["query"] !== undefined
            ? this.state.status[`${localUserType}`]["query"]
            : [],
      },
    ])

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${
          this.state.status[`${localUserType}`]["api"]
        }?start_time=${moment(this.props.startDate).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(this.props.endDate).format(
          "YYYY-MM-DD"
        )}&page_index=${this.props.currentPage}&page_size=10${
          query !== "" ? "&" + query : query
        }&search_term=${this.state.search_term}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          pending_process: res.data.data,
          total: res.data.count,
          limit: res.data.limit,
        })
      })
      .then(() => this.props.tabs_set_data_set())
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <div
        className={
          JSON.parse(localStorage.getItem("currUserType")) !== "agency_user"
            ? "pending-process"
            : "full-width-pending-process"
        }
      >
        <div className="page-header">
          <div className="page-heading-search">
            <div className="page-heading">
              {this.props.heading}
              <div className="page-sub-heading">
                {moment(this.props.startDate).format("ddd, MMM DD")} to{" "}
                {moment(this.props.endDate).format("ddd, MMM DD")}
              </div>
            </div>
            <input
              type="text"
              className="search_outlet"
              placeholder="Search by Wallet Number"
              onChange={(e) =>
                this.setState(
                  {
                    search_term: e.target.value,
                  },
                  () => this.set_data_set()
                )
              }
            />
          </div>
          <div className="page-settings">
            <div className="info">
              {/* <Calendar
                                page={'kyc'}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                            /> */}
            </div>
          </div>
        </div>
        <Pagination
          page={"pending_process"}
          currentPage={this.props.currentPage}
          total={this.state.total}
          limit={this.state.limit}
        />
        <Table
          column={
            JSON.parse(localStorage.getItem("currUserType")) === "agency_user"
              ? table_col_agency
              : table_col_bkash
          }
          data={this.state.pending_process}
          action={
            JSON.parse(localStorage.getItem("currUserType")) === "agency_user"
              ? "accept_reject"
              : "mark_receive"
          }
          pending_processing_set_data_set={this.set_data_set}
          table_type={this.props.heading}
          classNamePrefix="table"
        />
        <Pagination
          page={"pending_process"}
          currentPage={this.props.currentPage}
          total={this.state.total}
          limit={this.state.limit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let startDate = state.calendar.kyc_tracker
    ? state.calendar.kyc_tracker.startDate
    : state.calendar.kyc_default.startDate

  let endDate = state.calendar.kyc_tracker
    ? state.calendar.kyc_tracker.endDate
    : state.calendar.kyc_default.endDate

  let currentPage = state.page.pending_process
    ? state.page.pending_process.currentPage
    : state.page.default.currentPage
  let filterElement = state.filterElement.kyc
    ? state.filterElement.kyc
    : state.filterElement.default
  let filterHide = state.filter.filterHide
  return { startDate, endDate, currentPage, filterElement, filterHide }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
    updateFilterHide: (filterHide) => dispatch(updateFilterHide(filterHide)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingProcess)

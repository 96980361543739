import moment from "moment"

const initState = {
  default: {
    startDate: new Date(),
    endDate: new Date(),
  },
  kyc_default: {
    startDate: moment().subtract(30, "days").toDate(),
    // startDate: new Date(),
    // startDate: "01/01/2019",
    endDate: new Date(),
  },
}

const calendarReducer = (state = initState, action) => {
  switch (action.type) {
    case "CALENDAR_UPDATE":
      return {
        ...state,
        ...action.date,
      }
    default:
      return state
  }
}

export default calendarReducer

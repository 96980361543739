import React, { Component } from "react"
// import axios from "axios"
import http from "../../../utilities/_http"
import PropTypes from "prop-types"

import moment from "moment"

import LoadingCard from "./loadingCard"

import { query_generate } from "../../../utilities/_query_generate"

import "./../styles/kycTracking.scss"

const kyc_tracking = {
  card_heading: "KYC Tracking",
  state: "kyc_tracking_state",
  api_link: `api/kyc/v1/tracking`,
  conrner_right_top_img: "none",
}

/**
 * @description KYC Tracking table which gives a birds-eye view of the stages and processing of KYC
 * @author Arafat
 * @category Dashboard
 * @component KYCTracking
 */
class kycTracking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data_fetching: false,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.date !== prevProps.date ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set()
  }

  set_data_set = () => {
    let query_pre_process = [
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "region",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.region
            : [],
      },
      {
        key: "area",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.area
            : [],
      },
      {
        key: "territory",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.territory
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
    ]
    let query = query_generate(query_pre_process)

    this.setState({
      data_fetching: true,
    })

    http
      .get(
        `${process.env.REACT_APP_API_URL}/${
          kyc_tracking["api_link"]
        }?date=${moment(this.props.date).format("YYYY-MM-DD")}${
          query !== "" ? "&" + query : ""
        }`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) =>
        this.setState({
          [`${kyc_tracking["state"]}`]: res.data,
          data_fetching: false,
        })
      )
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  render() {
    return (
      <div className="card-col col s12 l12">
        {this.state.data_fetching ? (
          <LoadingCard card_heading={`${kyc_tracking.card_heading}`} />
        ) : (
          <div className="card kyc-tracking-card">
            <div className="card-title">{kyc_tracking.card_heading}</div>
            <div className="card-body">
              {this.state.kyc_tracking_state && (
                <table className="kyc-table">
                  <thead className="kyc-table-main-head">
                    <tr className="kyc-table-main-head-tr">
                      <th className="kyc-table-main-head-th blank_th"></th>
                      <th className="kyc-table-main-head-th" colSpan="2">
                        MDO
                      </th>
                      <th className="kyc-table-main-head-th" colSpan="3">
                        Agency QC
                      </th>
                      {/* <th className="kyc-table-main-head-th" colSpan="3">
                        Commercial QC
                      </th> */}
                      <th className="kyc-table-main-head-th" colSpan="3">
                        bKash CS
                      </th>
                    </tr>
                  </thead>

                  <tbody className="kyc-table-main-body">
                    <tr className="kyc-table-sub-head-tr">
                      <td className="kyc-table-side-head-td blank_td">
                        Details
                      </td>

                      <td className="kyc-table-sub-head-td">Total Base</td>
                      <td className="kyc-table-sub-head-td">Lead Closed</td>
                      <td className="kyc-table-sub-head-td"> Received</td>
                      <td className="kyc-table-sub-head-td"> Processed</td>
                      <td className="kyc-table-sub-head-td"> Passed</td>
                      {/* <td className="kyc-table-sub-head-td"> Received</td>
                      <td className="kyc-table-sub-head-td"> Processed</td>
                      <td className="kyc-table-sub-head-td"> Passed</td> */}
                      <td className="kyc-table-sub-head-td"> Activated</td>
                      <td className="kyc-table-sub-head-td"> Rejected</td>
                      <td className="kyc-table-sub-head-td">
                        {" "}
                        Yet to be Processed
                      </td>
                    </tr>
                    <tr className="kyc-table-body-tr">
                      <td className="kyc-table-side-head-td">Total MTD</td>

                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_mdo_total_base
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_mdo_lead_closed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_agency_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_agency_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_agency_passed
                        }
                      </td>
                      {/* <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_bkash_qc_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_bkash_qc_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_bkash_qc_passed
                        }
                      </td> */}
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_bkash_cs_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_bkash_cs_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.total_mtd
                            .mtd_bkash_cs_activated
                        }
                      </td>
                    </tr>

                    <tr className="kyc-table-body-tr">
                      <td className="kyc-table-side-head-td">Fresh Leads</td>

                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_lead_mdo_total
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_lead_mdo_close
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_agency_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_agency_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_agency_passed
                        }
                      </td>
                      {/* <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_bkash_qc_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_bkash_qc_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_bkash_qc_passed
                        }
                      </td> */}
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_bkash_cs_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_bkash_cs_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.fresh_lead
                            .fresh_bkash_cs_activated
                        }
                      </td>
                    </tr>

                    <tr className="kyc-table-body-tr">
                      <td className="kyc-table-side-head-td">Re-submissions</td>

                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_lead_mdo_total
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_lead_mdo_close
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_agency_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_agency_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_agency_passed
                        }
                      </td>
                      {/* <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_bkash_qc_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_bkash_qc_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_bkash_qc_passed
                        }
                      </td> */}
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_bkash_cs_received
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_bkash_cs_processed
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.resumission_lead
                            .resubmitted_bkash_cs_activated
                        }
                      </td>
                    </tr>

                    <tr className="kyc-table-body-tr blank_tr">
                      <td className="kyc-table-side-head-td">KYC at Hand</td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_total_base_mdo
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_lead_close_mdo
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_received_agency
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_processed_agency
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_passed_agency
                        }
                      </td>
                      {/* <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_received_bkash_qc
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_processed_bkash_qc
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_passed_bkash_qc
                        }
                      </td> */}
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_received_bkash_cs
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_processed_bkash_cs
                        }
                      </td>
                      <td className="kyc-table-value">
                        {
                          this.state.kyc_tracking_state.kyc_hand
                            .kyc_hand_activated_bkash_cs
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default kycTracking

kycTracking.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

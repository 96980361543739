import React, { useState, useEffect } from "react";
import "./styles/CalendarDateRangePicker.scss";

import { connect } from "react-redux";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { updateCalendarDatePicker } from "../../../store/actions/calendarDatePickerAction";

const CalendarDateRangePicker = ({ page, date, updateCalendarDatePicker }) => {
  const handleSelect = (date) => {
    if (date) {
      updateCalendarDatePicker({
        [page]: {
          date: date,
        },
      });
      console.log(date);
    } else {
      return null;
    }
  };

  const checkToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    const today = new Date();
    // console.log(today);
    // console.log(date);

    if (!Array.isArray(date)) {
      if (checkToday(date)) {
        setIsToday(true);
      } else {
        setIsToday(false);
      }
    } else {
      setIsToday(false);
    }
  }, [date]);

  const [value, onChange] = useState([new Date(), new Date()]);
  const [week, setWeek] = useState([]);
  const [isWeekSet, setWeekFlag] = useState(false);
  const [isToday, setIsToday] = useState(false);

  return (
    <div className="date-controllerx">
      <div className="heading">
        <div
          className={`today`}
          onClick={() => {
            updateCalendarDatePicker({
              [page]: {
                date: new Date(),
              },
            });
            setWeekFlag(false);
          }}
        >
          <span className={isToday ? `active` : ``}>Today</span>
        </div>
        {/* <div
          className="this-week"
          onClick={() => {
            setWeekFlag(true);
            let today = moment();
            updateCalendarDatePicker({
              [page]: {
                date: [today.startOf("isoWeek"), today.endOf("isoWeek")],
              },
            });
          }}
        >
          <span className={isWeekSet ? `active` : ``}>This Week</span>
        </div> */}
      </div>

      <div className="calendar">
        <div className="calendar-container">
          <div className="heading">
            {/* <span>{moment(date[0]).format("ddd, MMM DD")}</span> */}
            <span>Select Date Range...</span>
            <DateRangePicker
              onChange={handleSelect}
              value={date}
              calendarIcon={<i className="material-icons prefix">date_range</i>}
              calendarType="Hebrew"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCalendarDatePicker: (date) =>
      dispatch(updateCalendarDatePicker(date)),
  };
};

export default connect(null, mapDispatchToProps)(CalendarDateRangePicker);

import React, { Component } from "react"
import "./ReplaceFSEModal.styles.scss"
import EscapeOutside from "react-escape-outside"
import axios from "axios"
import history from "../../../../../../utilities/_history"
// import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader"

// import fse_img from "./_image/person.svg"
// import picture from "./_image/picture.svg"
import close_green_corner from "./_image/close_green_corner.svg"
import close_button from "./_image/close_button.svg"
import Dropdown from "./Dropdown/Dropdown"

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <EscapeOutside onEscapeOutside={handleClose}>
        <div className="wrapper-modal">
          <section className="modal-main">{children}</section>
        </div>
      </EscapeOutside>
    </div>
  )
}

class ReplaceFSEModal extends Component {
  state = {
    image:
      "https://png.pngitem.com/pimgs/s/506-5067022_sweet-shap-profile-placeholder-hd-png-download.png",

    loading: false,
    all_fse: [],
    selected_option: [],
    error: false,
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin_panel/v1/fse-list?include=all`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        this.setState({
          all_fse: res.data.data,
        })
      })
      .catch((err) =>
        console.log(`Error fetching fse list for replacement :: ${err}`)
      )
  }

  handleChange = (selected_option) => {
    this.setState({ selected_option }, () => this.getData())
    if (selected_option.image) {
      this.setState({
        image: selected_option.image,
      })
    } else {
      this.setState({
        image:
          "https://png.pngitem.com/pimgs/s/506-5067022_sweet-shap-profile-placeholder-hd-png-download.png",
      })
    }
  }

  handleSubmit = () => {
    this.setState({ loading: true })
    const fd = new FormData()

    if (this.state.selected_option) {
      fd.append("source_fse_id", this.props.id)
      fd.append("destination_fse_id", this.state.selected_option.id)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/admin_panel/v1/fse-replace`,
          fd,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
            },
          }
        )
        .then((res) => {
          console.log(res)
          this.setState({ loading: false, error: false }, () => {
            this.props.handleHide()
            this.props.handleSubmit(this.state.selected_option.id)
            history.push(`/admin/fse/${this.state.selected_option.id}`)
          })
        })
        // .then(() => )

        .catch((err) => {
          this.setState({ loading: false, error: true })
          console.log(`Error replacing fse :: ${err}`)
        })
    }
  }

  close_clear = () => {
    this.setState({ error: false }, () => this.props.handleHide())
    console.log(`closeclear`)
  }

  render() {
    return (
      <section className="replacefse-modal">
        {/* <h3 className="ex-title">Modal Type: 1</h3> */}

        <Modal show={this.props.show} handleClose={this.props.handleHide}>
          <div className="modal-header">
            <div className="headline">
              <div className="main-headline">Replace FSE</div>
              <div className="sub-headline">
                Fill in the details and press Submit
              </div>
            </div>
            <div className="close-button-corner" onClick={this.close_clear}>
              <img className="corner" src={close_green_corner} alt="close" />
              <img className="cross" src={close_button} alt="close" />
            </div>
          </div>
          <div className="replace-modal-body">
            <div className="basic">
              <div className="image">
                <img src={this.state.image} alt="basic" />
              </div>
            </div>
          </div>

          <form>
            <Dropdown
              className="select-fse-dd"
              title="Select FSE"
              items={this.state.all_fse}
              searchable
              onChange={this.handleChange}
              // default_value={{}}
            />
          </form>

          <div className="footer">
            <BounceLoader
              className="loader"
              color={"#e2136e"}
              size={20}
              loading={this.state.loading}
            />
            {this.state.error === true && (
              <div className="alert-box">
                <p> Error. Please select a FSE to replace with</p>
              </div>
            )}
            {/* {this.state.error === false && (
              <div className="success-box">
                <p>FSE has been replaced successfully</p>
              </div>
            )} */}
            <button className="button--cancel" onClick={this.close_clear}>
              {" "}
              Cancel{" "}
            </button>
            <button className="button--save" onClick={this.handleSubmit}>
              {" "}
              Save{" "}
            </button>
          </div>
        </Modal>
      </section>
    )
  }
}

export default ReplaceFSEModal

import { createTheme } from "@mui/material/styles"
import { green, pink } from "@mui/material/colors"

const theme = createTheme({
  palette: {
    primary: {
      main: pink[600],
      light: pink[300],
      dark: pink[900],
    },
    secondary: {
      main: pink[600],
      light: pink[300],
      dark: pink[900],
    },
  },
})

export default theme

import React from "react"
import PropTypes from "prop-types"
import "./index.styles.scss"
import "./../layout/styles/filter.css"

import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"
import { SWRConfig } from "swr"
import axios from "axios"

import { updateFilterElement } from "../../store/actions/filterElementAction"
import { updateFilterHide } from "../../store/actions/filterAction"
import { has_selected_filter } from "./../../utilities/_has_selected_filter"
import {
  relational_filter_list,
  non_relational_filter_list_dash_agency,
} from "./../../utilities/_filter_list_builder"
import FilterElement from "./../layout/FilterElement"

// import MDOCount from "./components/mdoCountCard";
// import MDOPerformance from "./components/mdoPerformanceCard";
// import CallOverview from "./components/callOverviewCard";
// import MerchantCalls from "./components/merchantCalls";
import MDOCount2 from "./components/mdoCountCard2"
import MDOPerformance2 from "./components/mdoPerformanceCard2"
import CallOverview2 from "./components/callOverviewCard2"
import MerchantCalls2 from "./components/merchantCalls2"
import KYCSummary from "./components/kycSummary"
import KYCTracking from "./components/kycTracking"
import AgencyPerformance from "./components/agencyPerformance"
import BinaryGraphCard from "./components/binaryGraphCard"
import ResistanceCauseCard from "./components/resistanceCauseCard"
import Footer from "../Footer/Footer"
import CalendarDatePicker from "./../layout/CalendarDatePicker"
import CoverageCard from "./components/coverageCard"

import { signOut } from "../../store/actions/authAction"

/**
 * Dashboard component.
 * @description This is the newly re-designed dashboard hence the New in Dashboard component name.
 *   This component ecompasses other components such as
 * @author Zoha
 * @category Dashboard
 * @component Dashboard
 */
class NewDashboard extends React.Component {
  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ))

  async remove_all_filter() {
    await this.props.updateFilterElement({
      dex_dashboard: {
        relational: {},
        non_relational: {},
      },
    })
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))
    if (!localUserToken) return <Redirect to="/signin" />
    /**
     * Checking to see if the local token has expired,
     * if expired, user will be redirected to the login page
     */
    let current_time = Date.now().valueOf() / 1000
    if (this.props.expiration < current_time) this.props.signOut()

    return (
      <>
        {this.props.filterHide && (
          <div className="filter-container">
            <div className="header">
              <div className="heading">Select Filter</div>
              {has_selected_filter(this.props.filterElement) ? (
                <div className="clear">
                  <button
                    className="active-button"
                    onClick={() => this.remove_all_filter()}
                  >
                    Clear All
                  </button>
                </div>
              ) : (
                <div className="clear">
                  <button
                    className="inactive-button"
                    onClick={() => this.remove_all_filter()}
                    disabled
                  >
                    Clear All
                  </button>
                </div>
              )}
            </div>
            <div className="body">
              {/* Showing non-relational filters only for Agency Dashboard */}
              {/* {localUserType !== "agency_user" ||
            localUserType !== "territory_manager" ||
            localUserType !== "area_manager" ||
            localUserType !== "regional_manager"
              ? this.create_filter_elements(
                  relational_filter_list("dex_dashboard"),
                  "relational"
                )
              : this.create_filter_elements(
                  non_relational_filter_list_dash_agency("dex_dashboard"),
                  "relational"
                )} */}

              {localUserType === "agency_user"
                ? this.create_filter_elements(
                    non_relational_filter_list_dash_agency("dex_dashboard"),
                    "relational"
                  )
                : this.create_filter_elements(
                    relational_filter_list("dex_dashboard"),
                    "relational"
                  )}
            </div>
          </div>
        )}

        <div className="main-container">
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              // revalidateOnMount: false,
              dedupingInterval: 50000,
              fetcher: (url) =>
                axios(url, {
                  headers: {
                    Authorization:
                      "Bearer " +
                      JSON.parse(localStorage.getItem("currUserToken")),
                  },
                }).then((res) => res.data),
            }}
          >
            <div className="dexter-dashboard-container">
              <div className="page-header">
                <div className="page-heading">
                  Dashboard
                  <div className="page-sub-heading">
                    {moment(this.props.date).format("ddd, MMM DD")}
                  </div>
                </div>
                <div className="page-settings">
                  <div className="info">
                    <CalendarDatePicker
                      page={"dex_dashboard"}
                      date={this.props.date}
                    />
                  </div>
                </div>
              </div>

              <div className="card-row row">
                <div className="col s12 m12 l8">
                  <div className="card-row row">
                    <CoverageCard
                      date={this.props.date}
                      filterElement={this.props.filterElement}
                    />
                  </div>
                  <div className="card-row row">
                    <MDOCount2
                      date={this.props.date}
                      filterElement={this.props.filterElement}
                    />
                    <MDOPerformance2
                      date={this.props.date}
                      filterElement={this.props.filterElement}
                    />
                  </div>
                </div>

                <div className="col s12 m12 l4">
                  <div className="card-row row">
                    <CallOverview2
                      date={this.props.date}
                      filterElement={this.props.filterElement}
                    />
                  </div>
                </div>
              </div>

              <div className="card-row row">
                <MerchantCalls2
                  date={this.props.date}
                  filterElement={this.props.filterElement}
                />
              </div>

              <div className="card-row row">
                <KYCSummary
                  date={this.props.date}
                  filterElement={this.props.filterElement}
                />
              </div>

              <div className="card-row row">
                <KYCTracking
                  date={this.props.date}
                  filterElement={this.props.filterElement}
                />
              </div>

              <div className="card-row row">
                <BinaryGraphCard
                  date={this.props.date}
                  filterElement={this.props.filterElement}
                />
                <ResistanceCauseCard
                  date={this.props.date}
                  filterElement={this.props.filterElement}
                />
              </div>

              <div className="card-row row">
                {/* <BinaryGraphCard2
                date={this.props.date}
                filterElement={this.props.filterElement}
              /> */}
              </div>

              <div className="card-row row">
                <AgencyPerformance
                  date={this.props.date}
                  filterElement={this.props.filterElement}
                />
              </div>
            </div>

            <Footer />
          </SWRConfig>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.dex_dashboard
    ? state.calendarDatePicker.dex_dashboard.date
    : state.calendarDatePicker.default.date

  let filterElement = state.filterElement.dex_dashboard
    ? state.filterElement.dex_dashboard
    : state.filterElement.default

  let filterHide = state.filter.filterHide

  let expiration = state.auth.authToken
    ? state.auth.authToken.expiration
    : 20000

  return { date, filterElement, filterHide, expiration }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
    updateFilterHide: (filterHide) => dispatch(updateFilterHide(filterHide)),
    signOut: () => dispatch(signOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard)

NewDashboard.propTypes = {
  updateFilterElement: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
}

import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import authReducer from "./authReducer"
import calendarReducer from "./calendarReducer"
import calendarDatePickerReducer from "./calendarDatePickerReducer"
import paginationReducer from "./paginationReducer"
import alertReducer from "./alertReducer"
import filterReducer from "./filterReducer"
import filterElementReducer from "./filterElementReducer"
import selectedMenuReducer from "./selectedMenuReducer"
import selectedTabReducer from "./selectedTabReducer"
import localActionReducer from "./localActionReducer"
import uplaodActionReducer from "./uploadActionReducer"
import uploadRejectedReducer from "./uploadRejectedReducer"
import paginationLimitReducer from "./paginationLimitReducer"
import adminTableSelectReducer from "./adminTableSelectReducer"
import dashboardTableSelectReducer from "./dashboardTableSelectReducer"
import servicingTableSelectReducer from "./servicingTableSelectReducer"
import leaderboardTableSelectReducer from "./leaderboardTableSelectReducer"

import { firebaseReducer } from "react-redux-firebase"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
}

const rootReducer = combineReducers({
  calendar: calendarReducer,
  calendarDatePicker: calendarDatePickerReducer,
  dashboardTable: dashboardTableSelectReducer,
  servicingTable: servicingTableSelectReducer,
  leaderboardPage: leaderboardTableSelectReducer,
  auth: authReducer,
  page: paginationReducer,
  alert: alertReducer,
  filter: filterReducer,
  firebase: firebaseReducer,
  filterElement: filterElementReducer,
  selectedMenu: selectedMenuReducer,
  selectedTab: selectedTabReducer,
  localAction: localActionReducer,
  uplaodAction: uplaodActionReducer,
  uploadRejected: uploadRejectedReducer,
  paginationLimit: paginationLimitReducer,
  selectedAdminTable: adminTableSelectReducer,
})

export default persistReducer(persistConfig, rootReducer)

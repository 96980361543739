const initState = {
  default: {
    date: new Date(),
  },
};

const calendarDatePickerReducer = (state = initState, action) => {
  switch (action.type) {
    case "CALENDAR_DATE_PICKER_UPDATE":
      return {
        ...state,
        ...action.date,
      };
    case "CALENDAR_DATE_RANGE_PICKER_UPDATE":
      return {
        ...state,
        ...action.daterange,
      };
    default:
      return state;
  }
};

export default calendarDatePickerReducer;

import React from "react"
import "../styles/overall-performance.scss"
import Chart from "react-apexcharts"
import useSWR from "swr"
import moment from "moment"
import { connect } from "react-redux"

import LoadingCard from "./loadingCard"

const OverallPerformance = ({ idNumber, current_month }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/performance/v1/get-monthly-score/${idNumber}?month=${current_month}`
  const { data, error } = useSWR(url)
  return (
    <div className="card-col col s12 m12 l12 ">
      {!data ? (
        <LoadingCard />
      ) : (
        <div className="card overall-performance-card">
          <div className="card-title">Overall performance Score</div>
          <div className="card-body">
            {/* <div className="row chart">
              <Chart
                options={options}
                series={data.chart_data}
                type="radialBar"
                height={240}
              />
            </div> */}
            <div className="row">
              <hr className="custom-divider" />
            </div>
            <div className="row data">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th className="shorten">Rank</th>
                    <th className="shorten">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {data.month_data.map((item, index) => (
                    <tr key={index}>
                      <td>{moment(item.month, "MM").format("MMMM")}</td>
                      <td>{item.rank}</td>
                      <td>{item.score}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const options = {
  chart: {
    height: 320,
    type: "radialBar",
  },
  colors: ["#f5beb4"],
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      hollow: {
        size: "72%",
      },

      track: {
        background: "#F9EBF1",
        startAngle: -135,
        endAngle: 135,
      },
      dataLabels: {
        name: {
          offsetY: 18,
          show: true,
          label: "Targets Achieved",
          fontSize: "12px",
          color: "#707070",
          fontFamily: "Roboto', sans-serif",
          fontWeight: 300,
        },
        value: {
          offsetY: -18,
          fontFamily: "Roboto', sans-serif",
          fontSize: "24px",
          fontWeight: 500,
          color: "#434364",
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      gradientToColors: ["#e2136e"],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Target Achieved"],
}
const mapStateToProps = (state) => ({
  current_month: state.leaderboardPage.profile.current_month,
})
export default connect(mapStateToProps)(OverallPerformance)

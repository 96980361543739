import React, { useState } from "react"
import { connect } from "react-redux"
import "../styles/table-module.scss"
import TopBottom from "./top-bottom"
import TextField from "@mui/material/TextField"
import Chart from "react-apexcharts"
import { setSearch } from "../../../store/actions/leaderboardTableSelectAction"
import useSWR from "swr"
import { Link } from "react-router-dom"
import { updateFilterElement } from "../../../store/actions/filterElementAction"
import Pagination from "../../layout/Pagination"
import { query_generate } from "../../../utilities/_query_generate"

const options = {
  chart: {
    height: 40,
    type: "radialBar",
  },
  colors: ["#f5beb4"],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      hollow: {
        size: "40%",
      },

      track: {
        background: "#F9EBF1",
        startAngle: -90,
        endAngle: 90,
      },
      dataLabels: {
        name: {
          show: false,
          // label: "Targets Achieved",
          fontSize: "12px",
          color: "#707070",
          fontFamily: "Roboto', sans-serif",
          fontWeight: 300,
        },
        value: {
          fontFamily: "Roboto', sans-serif",
          fontSize: "12px",
          fontWeight: 500,
          color: "#434364",
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      gradientToColors: ["#e2136e"],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Target Achieved"],
}

const TableModule = ({
  setSearch,
  search,
  filter,
  currentPage,
  currentLimit,
  filterElement,
}) => {
  const [total, setTotal] = useState(null)

  let query_pre_process = [
    {
      key: "team",
      values:
        filterElement.relational !== undefined
          ? filterElement.relational.team
          : [],
    },
    {
      key: "region",
      values:
        filterElement.relational !== undefined
          ? filterElement.relational.region
          : [],
    },
    {
      key: "area",
      values:
        filterElement.relational !== undefined
          ? filterElement.relational.area
          : [],
    },
    {
      key: "territory",
      values:
        filterElement.relational !== undefined
          ? filterElement.relational.territory
          : [],
    },
    {
      key: "agency",
      values:
        filterElement.relational !== undefined
          ? filterElement.relational.agency
          : [],
    },
  ]
  let query = query_generate(query_pre_process)

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/performance/v1/filter-mdo-performance?page_index=${currentPage}&page_size=${currentLimit}&search_param=${search}&filter=${filter}${
    query !== "" ? "&" + query : ""
  }`
  const { data, error } = useSWR(url)

  if (error) return <div>failed to load</div>

  return (
    <div className="card-row row">
      {data && data.data && (
        <Pagination
          page={"leaderboard"}
          currentPage={currentPage}
          total={data.count}
          limit={currentLimit}
        />
      )}
      <div className="card-col col s12 l12 table-module-column">
        <div className="table-module-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="theadbox">
                      <div className="table-options">
                        <div className="top-bottom-tab-button">
                          <TopBottom />
                        </div>
                        <div className="table-search-container">
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Search by Name/Mobile.."
                            style={{ width: 328 }}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Acquisition <br /> Achievement
                      </span>
                      <span className="desc">Acquisition Target</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Active <br /> Merchant
                      </span>
                      <span className="desc">Active Merchant Target</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Leads <br />
                        Closed{" "}
                      </span>
                      <span className="desc">Leads Generated</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        <br />
                        Rejected
                      </span>
                      <span className="desc">Agency Submitted</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Merchant <br />
                        Base Cover
                      </span>
                      <span className="desc">Merchant Base</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        QR <br />
                        Present
                      </span>
                      <span className="desc">Merchant Base</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Days <br />
                        Present
                      </span>
                      <span className="desc">Total Working Days 24</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Green
                        <br /> Merchant Visit
                      </span>
                      <span className="desc">Target Merchant</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Amber
                        <br /> Merchant Visit
                      </span>
                      <span className="desc">Target Merchant</span>
                    </div>
                  </th>
                  <th>
                    <div className="theadbox">
                      <span className="title">
                        Red
                        <br /> Merchant Visit
                      </span>
                      <span className="desc">Target Merchant</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 ? (
                  data.data.map((item, index) => (
                    <Rows key={index} data={item} />
                  ))
                ) : error ? (
                  <tr>
                    <td colSpan="8">Error Fetching Data</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="8">Loading... Please Wait</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {data && data.data && (
        <Pagination
          page={"leaderboard"}
          currentPage={currentPage}
          total={data.count}
          limit={currentLimit}
        />
      )}
    </div>
  )
}

const Rows = ({ data }) => {
  const returnPercentageOf = (value, total) => {
    return (value / total) * 100
  }

  return (
    <tr>
      <td>
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="information-card-container">
            <div className="image-container">
              <img
                src={
                  data.mdo_image
                    ? data.mdo_image
                    : `https://via.placeholder.com/40`
                }
              />
            </div>
            <div className="information-container">
              <span className="name">{data.mdo_name}</span>
              <span className="number">{data.mdo_number}</span>
              <div className="agency">{data.agency_name}</div>
            </div>
            <div className="graph-container">
              <Chart
                options={options}
                series={[data.mdo_score]}
                type="radialBar"
                height={100}
              />
            </div>
          </div>
        </Link>
      </td>
      <td>
        {/* Acquisition Achievement %Acquisition Target */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.acquisition_total}</span>
            <span className="two">840</span>
          </div>
        </Link>
      </td>
      <td>
        {/* Active Merchant %Active Merchant Target */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.activation_total}</span>
            <span className="two">40</span>
          </div>
        </Link>
      </td>
      <td>
        {/* Leads Closed % Leads Generated */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.lead_close_total}</span>
            <span className="two">40</span>
          </div>
        </Link>
      </td>
      <td>
        {/* Rejected %Agency Submitted */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.rejection_total}</span>
            <span className="two">
              {Math.round(data.activation_total * 0.05)}
            </span>
          </div>
        </Link>
      </td>
      <td>
        {/* Merchant Base Cover %Merchant Base */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.regular_total}</span>
            <span className="two">{data.total_tagged_outlet}</span>
          </div>
        </Link>
      </td>
      <td>
        {/* QR Present %Merchant Base */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.qr_present_total}</span>
            <span className="two">
              {Math.round(data.total_tagged_outlet * 0.9)}
            </span>
          </div>
        </Link>
      </td>
      <td>
        {/* Days Present %Total Working Days (24) */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.attendance_total}</span>
            <span className="two">24</span>
          </div>
        </Link>
      </td>
      <td>
        {/* Green Merchant Visit */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.green_visit_total}</span>
            <span className="two">
              {Math.round(data.total_tagged_outlet * 0.4)}
            </span>
          </div>
        </Link>
      </td>
      <td>
        {/* Amber Merchant Visit */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.amber_visit_total}</span>
            <span className="two">
              {Math.round(data.total_tagged_outlet * 0.25)}
            </span>
          </div>
        </Link>
      </td>
      <td>
        {/* Red Merchant Visit */}
        <Link to={`/leaderboard/${data.field_force_id}`}>
          <div className="number-container">
            <span className="one">{data.red_visit_total}</span>
            <span className="two">
              {Math.round(data.total_tagged_outlet * 0.35)}
            </span>
          </div>
        </Link>
      </td>
    </tr>
  )
}

const mapStateToProps = (state) => ({
  search: state.leaderboardPage.active_table.search,
  filter: state.leaderboardPage.active_table.filter,

  filterElement: state.filterElement.dex_leaderboard
    ? state.filterElement.dex_leaderboard
    : state.filterElement.default,

  currentPage: state.page.leaderboard
    ? state.page.leaderboard.currentPage
    : state.page.default.currentPage,

  currentLimit: state.paginationLimit.leaderboard
    ? state.paginationLimit.leaderboard.currentLimit
    : 10,
})

const mapDispatchToProps = (dispatch) => ({
  setSearch: (data) => dispatch(setSearch(data)),
  updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TableModule)

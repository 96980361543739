import React from "react"
import "./index.scss"
import { Redirect } from "react-router-dom"
import Tabs from "./components/tabs"
import FilterModule from "./components/filter-module"
import TableModule from "./components/table-module"
import { connect } from "react-redux"
import { useNotification } from "../Notifications/NotificationProvider"

import { updateFilterElement } from "../../store/actions/filterElementAction"
import { updateFilterHide } from "../../store/actions/filterAction"
import { has_selected_filter } from "./../../utilities/_has_selected_filter"
import {
  relational_filter_list,
  non_relational_filter_list_dash_agency,
} from "./../../utilities/_filter_list_builder"
import FilterElement from "./../layout/FilterElement"

const Leaderboard = ({
  user_type,
  updateFilterElement,

  filterElement,
  filterHide,
}) => {
  const dispatchNotification = useNotification()

  if (user_type !== "bkash-super-user" && user_type !== "territory_manager") {
    dispatchNotification({
      type: "ERROR",
      message: "You do not have access to Leaderboard page",
    })
    dispatchNotification({
      type: "ERROR",
      message: "Redirecting to main page",
    })
    return <Redirect to="/signin" />
  }

  const create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={filterElement}
        type={type}
      />
    ))

  const remove_all_filter = async () => {
    await updateFilterElement({
      dex_leaderboard: {
        relational: {},
        non_relational: {},
      },
    })
  }

  const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
  const localUserType = JSON.parse(localStorage.getItem("currUserType"))
  if (!localUserToken) return <Redirect to="/signin" />

  return (
    <>
      {filterHide && (
        <div className="filter-container">
          <div className="header">
            <div className="heading">Select Filter</div>
            {has_selected_filter(filterElement) ? (
              <div className="clear">
                <button
                  className="active-button"
                  onClick={() => remove_all_filter()}
                >
                  Clear All
                </button>
              </div>
            ) : (
              <div className="clear">
                <button
                  className="inactive-button"
                  onClick={() => remove_all_filter()}
                  disabled
                >
                  Clear All
                </button>
              </div>
            )}
          </div>
          <div className="body">
            {/* Showing non-relational filters only for Agency Dashboard */}
            {/* {localUserType !== "agency_user" ||
            localUserType !== "territory_manager" ||
            localUserType !== "area_manager" ||
            localUserType !== "regional_manager"
              ? create_filter_elements(
                  relational_filter_list("dex_leaderboard"),
                  "relational"
                )
              : create_filter_elements(
                  non_relational_filter_list_dash_agency("dex_leaderboard"),
                  "relational"
                )} */}

            {localUserType === "agency_user"
              ? create_filter_elements(
                  non_relational_filter_list_dash_agency("dex_leaderboard"),
                  "relational"
                )
              : create_filter_elements(
                  relational_filter_list("dex_leaderboard"),
                  "relational"
                )}
          </div>
        </div>
      )}

      <div className="main-container">
        <div className="leaderboard-container">
          <NavPanel />
          <TableModule />
        </div>
      </div>
    </>
  )
}

const NavPanel = () => {
  // const agency = {
  //   name: "agency",
  //   tabs: ["Your Team (MDO)", "Your Team (FSE)"],
  // }
  // const gmtl = {
  //   name: "gmtl",
  //   tabs: ["National", "All MDO", "All FSE"],
  // }

  // const cm = {
  //   name: "cm",
  //   tabs: ["National", "Your Team (MDO)", "Your Team (FSE)"],
  // }
  const temporaryAll = {
    name: "agency",
    tabs: ["MDO"],
  }

  return (
    <div className="nav-panel-container">
      <div className="nav-panel-header-container">
        <h2>Leaderboard</h2>
        <span>Today's data only</span>
      </div>

      <div className="nav-panel-tabs-container">
        <Tabs section="leaderboard" options={temporaryAll} />
      </div>
      <div className="nav-panel-filter-container">
        <FilterModule />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user_type: state.auth.authToken ? state.auth.authToken.type : null,
  filterElement: state.filterElement.dex_leaderboard
    ? state.filterElement.dex_leaderboard
    : state.filterElement.default,
  filterHide: state.filter.filterHide,
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard)

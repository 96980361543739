export const updateCalendarDatePicker = (date) => {
  return (dispatch, getState) => {
    dispatch({ type: "CALENDAR_DATE_PICKER_UPDATE", date });
  };
};

export const updateCalendarDateRangePicker = (daterange) => {
  return (dispatch, getState) => {
    dispatch({ type: "CALENDAR_DATE_RANGE_PICKER_UPDATE", daterange });
  };
};

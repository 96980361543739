import React, { Component } from "react"
import { connect } from "react-redux"

import moment from "moment"

import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import { updateCalendar } from "../../store/actions/calendarAction"

class Calendar extends Component {
  handleSelect = (date) =>
    date
      ? this.props.updateCalendar({
          [this.props.page]: {
            startDate: date[0],
            endDate: date[1],
          },
        })
      : null

  render() {
    return (
      <div className="date-controller">
        <div
          onClick={() =>
            this.props.updateCalendar({
              [this.props.page]: {
                startDate: new Date(),
                endDate: new Date(),
              },
            })
          }
          className="today"
        >
          <div className="heading">
            <span>Today</span>
          </div>
        </div>

        <div className="calendar">
          <div className="calendar-container">
            <div className="heading">
              <span>
                {moment(this.props.startDate).format("ddd, MMM DD") ===
                moment(this.props.endDate).format("ddd, MMM DD")
                  ? `${moment(this.props.startDate).format("ddd, MMM DD")}`
                  : `${moment(this.props.startDate).format(
                      "ddd, MMM DD"
                    )} to ${moment(this.props.endDate).format("ddd, MMM DD")}`}
              </span>
              <DateRangePicker
                onChange={this.handleSelect}
                value={[this.props.startDate, this.props.endDate]}
                calendarIcon={
                  <i className="material-icons prefix">date_range</i>
                }
                calendarType="Hebrew"
                // showDoubleView={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCalendar: (date) => dispatch(updateCalendar(date)),
  }
}
export default connect(null, mapDispatchToProps)(Calendar)

import React from "react";
import "../styles/legends.styles.scss";

const Legends = ({ title, type, percent, color }) => (
  <div className="mc-legend-container">
    <div className="circle-group">
      <svg
        className="svgx"
        width="45"
        height="45"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          ry="19.2"
          rx="19.2"
          id="svg_1"
          cy="22.5"
          cx="22.5"
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="4"
          stroke={color}
          fill="#fff"
        />

        <text
          className="txt"
          textAnchor="start"
          id="svg_2"
          y="26.8"
          x={parseInt(percent) >= 10 ? "10.49333" : "15.5"}
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="0"
        >
          {percent}
        </text>
      </svg>
    </div>
    <div className="text-block">
      <div className="legend-title">
        <span>{title}</span>
      </div>
      <div className="legend-type">
        <span>{type}</span>
      </div>
    </div>
  </div>
);

export default Legends;

import React from "react"
import styled, { css } from "styled-components"
import { ReactComponent as downloadIcon } from "../../../img/reports_card/download.svg"

// const DownloadReportButton = ({ onClick, children }) => {
//   return <button onClick={onClick}>{children}</button>
// }

const DownloadReportButton = styled.button`
  padding: 0.55em;
  margin: 1em;
  color: #e2136e;
  background: #fff;
  border: 1px solid #e2136e;
  border-radius: 3px;
  display: ${(props) => props.hide && "none"};
  cursor: pointer;
  display: flex;

  &:focus {
    background-color: #fff;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e1e1e1;
    color: #dda9c0;
    border-color: #dda9c0;
  }
`
export default DownloadReportButton

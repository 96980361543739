import React from "react"
import "./../styles/mdoCountCard.scss"
import PropTypes from "prop-types"

import moment from "moment"
import Chart from "react-apexcharts"

import useSWR from "swr"

import { query_generate } from "../../../utilities/_query_generate"

import LoadingCard from "./loadingCard"

import mdo_count_img from "./../../../img/dashboard_card/MDO_count.svg"

const mdo_count = {
  card_heading: "MDO Count",
  state: "mdo_count_state",
  api_link: `api/reports/v2/active-mdo-report`,
  conrner_right_top_img: mdo_count_img,
}

const options = {
  chart: {
    height: 320,
    type: "radialBar",
  },

  stroke: {
    lineCap: "round",
  },
  fill: {
    colors: ["#E2136E", "#F7D700", "#00CFFF"],
  },

  plotOptions: {
    radialBar: {
      hollow: {
        size: "58%",
      },
      dataLabels: {
        enabled: true,
        name: {
          fontSize: "12px",
        },
        value: {
          fontFamily: "Roboto', sans-serif",
          fontSize: "24px",
          fontWeight: 500,
          color: "#00CFFF",
        },
        total: {
          show: true,
          width: "20px",
          fontSize: "12px",
          color: "#707070",
          fontFamily: "Roboto', sans-serif",
          fontWeight: 300,
          label: "Active of Total Assigned",
          formatter: function (w) {
            console.log(w.config.series)
            return Math.round(w.config.series[2]) + "%"
          },
        },
      },
    },
  },
  labels: ["Registered", "Assigned", "Active of Total Assigned"],
}

/**
 * @description Newly designed MDO count card showing active MDOs, assigned MDOs, active MDO number
 * and gains or losses from last week.
 * @component MDO Count Card 2
 * @category Dashboard
 * @author Zoha
 */
const MDOCountCard2 = (props) => {
  let query_pre_process = [
    {
      key: "team",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.team
          : [],
    },
    {
      key: "region",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.region
          : [],
    },
    {
      key: "area",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.area
          : [],
    },
    {
      key: "territory",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.territory
          : [],
    },
    {
      key: "agency",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.agency
          : [],
    },
  ]
  let query = query_generate(query_pre_process)

  const url = `${process.env.REACT_APP_API_URL}/${
    mdo_count["api_link"]
  }?date=${moment(props.date).format("YYYY-MM-DD")}${
    query !== "" ? "&" + query : ""
  }`

  const { data: mdo_count_state } = useSWR(url)

  return (
    <div className="card-col col s12 m6 l6 ">
      {!mdo_count_state ? (
        <LoadingCard card_heading={`${mdo_count.card_heading}`} />
      ) : (
        <div className="card data-cardx">
          {mdo_count.conrner_right_top_img !== "none" ? (
            <img
              className="img card-corner"
              src={mdo_count.conrner_right_top_img}
              alt="card-corner"
            />
          ) : null}
          <div className="card-title">{mdo_count.card_heading}</div>
          {mdo_count_state && mdo_count_state.current && (
            <div className="card-body">
              <div className="card-graph">
                <Chart
                  options={options}
                  series={[
                    100,
                    Math.round(
                      (mdo_count_state.current.assigned_count /
                        mdo_count_state.current.total_count) *
                        100
                    ),
                    mdo_count_state.current.total_count !== 0
                      ? Math.round(
                          (mdo_count_state.current.attendance_count /
                            mdo_count_state.current.assigned_count) *
                            100
                        )
                      : 0,
                  ]}
                  type="radialBar"
                  height={320}
                />
              </div>

              <div className="card-content-container">
                <div className="row1">
                  <div className="registered">
                    <div className="value">
                      {mdo_count_state.current.total_count}
                    </div>
                    <div className="tag">Registered</div>
                  </div>

                  <div className="assigned">
                    <div className="value">
                      {mdo_count_state.current.assigned_count}
                    </div>
                    <div className="tag">Assigned For The Day</div>
                  </div>
                </div>

                <div className="row2">
                  <div className="active-today">
                    <div className="value">
                      {mdo_count_state.current.attendance_count}
                    </div>
                    <div className="tag">Active Today</div>
                  </div>

                  <div className="comparison">
                    <div
                      className={`value ${
                        mdo_count_state.deltas.with_last_week >= 0
                          ? `positive`
                          : `negative`
                      }`}
                    >
                      {mdo_count_state.deltas.with_last_week >= 0 ? `+` : ``}
                      {mdo_count_state.deltas.with_last_week}%
                    </div>
                    <div className={`tag`}>From Last Week</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MDOCountCard2

MDOCountCard2.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

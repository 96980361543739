import React, { useState, useMemo, useEffect } from "react"
import { connect } from "react-redux"
import "./styles/strategicTransfers.scss"
import moment from "moment"
import LoadingCard from "./loadingCard"
import axios from "axios"
import Table from "./customTable"
import { ReactComponent as CopyIcon } from "../../../img/copy.svg"
import { CopyToClipboard } from "react-copy-to-clipboard"
import downloadIcon from "../../../img/kam_transfer/download.svg"
import DownloadReportButton from "./DownloadReportButton"
import BounceLoader from "react-spinners/BounceLoader"
import { useNotification } from "../../Notifications/NotificationProvider"

const strategicTransfers = {
  card_heading: "Resolved",
}

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
}

// Simple button for marking all or individually
// CSS styles are in strategicTransfers.scss
// const AcknowlegeButton = (props) => (
//   <button {...props} className="ack-btn">
//     {props.children}
//   </button>
// )

const ResolvedServices = ({
  issue,
  date,
  dateRaw,
  search,
  pageLimit,
  currentPage,
}) => {
  const getDate = () => {
    if (dateRaw.servicing) {
      if (Array.isArray(date)) {
        return `&start_time=${moment(date[0]).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date[1]).format("YYYY-MM-DD")}`
      } else {
        // console.log("date not array");
        return `&start_time=${moment(date).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(date).format("YYYY-MM-DD")}`
      }
    } else return ""
  }

  let url = `${process.env.REACT_APP_KAM_API_URL}services/v1/list?tab=resolved${
    search.length > 0 ? `&search_term=${search}` : ``
  }${
    issue.feedback ? `&issue=${issue.feedback}` : ``
  }${getDate()}&page_index=${currentPage}&page_size=${parseInt(pageLimit)}`

  // Global state for selected items
  const [selectedRows, setSelectedRows] = useState([])
  const dispatchNotification = useNotification()
  const [selectedReports, setSelectedReports] = useState({})
  let [loadingReport, setLoadingReport] = useState(false)
  let [color, setColor] = useState("#e2136e")

  const [data, setAxData] = useState({})
  const [loading, setLoading] = useState(true)

  const getData = () => {
    setLoading(true)
    axios
      .get(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
        },
      })
      .then((res) => {
        setAxData(res.data.results)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => getData(), [issue, date, search, pageLimit, currentPage])

  // Memoized columns and data for table generation
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => (
          <CopyToClipboard text={row.original.id}>
            <span
              onClick={() =>
                alert("Copied ID " + row.original.id + " to clipboard")
              }
              style={{ display: "flex" }}
            >
              {row.original.id.slice(0, 6)}...{" "}
              <CopyIcon fill="#e2136e" width="1.2em" />
            </span>
          </CopyToClipboard>
        ),
      },
      {
        Header: "Wallet Number",
        accessor: "outlet__wallet_no",
        width: "200px",
      },
      {
        Header: "Merchant Name",
        accessor: "outlet__brand_name",
        width: 200,
      },
      {
        Header: "KAM",
        accessor: "kam__name",
        width: 200,
      },
      {
        Header: "Zone",
        accessor: "outlet__cluster_code__zone__name",
        width: 200,
      },
      {
        Header: "Date",
        accessor: "zone_aware_created_date",

        Cell: ({ row }) => (
          <span>
            {moment(row.original.zone_aware_created_date).format("Do MMM")}
          </span>
        ),
      },
      {
        Header: "Issue",
        accessor: "call_feedback__feedback",
        width: 200,
        Cell: ({ row }) => (
          <div className="issue-container">
            <span className="issue-title">
              {row.original.call_feedback__feedback}
            </span>
            {/* <span className="issue-desc">{row.original.issue.detail}</span> */}
          </div>
        ),
      },
      {
        Header: "",
        id: "download-btn",
        Cell: ({ row }) => (
          <img
            src={downloadIcon}
            width="24px"
            alt="download file"
            disabled={loadingReport}
            onClick={() => {
              setLoadingReport(true)

              const requestBody = {
                category: issue.category,
                issue: row.original.call_feedback__feedback,
                bulk: false,
                services: [
                  {
                    id: row.original.id,
                    wallet_no: row.original.outlet__wallet_no,
                    branch_name: row.original.outlet__branch_name,
                    brand_name: row.original.outlet__brand_name,
                    comment: row.original.call_feedback__comment,
                    status: row.original.status,
                    kam_email: row.original.kam__email,
                    call_feedback_email: row.original.call_feedback__email,
                    call_feedback_image: row.original.call_feedback__image,
                    call_feedback_start_date:
                      row.original.call_feedback__start_date,
                    call_feedback_end_date:
                      row.original.call_feedback__end_date,
                    created_date: row.original.zone_aware_created_date,
                    call_feedback_new_address:
                      row.original.call_feedback__new_address,
                    call_feedback_old_address:
                      row.original.call_feedback__old_address,
                    call_feedback_old_mno: row.original.call_feedback__old_mno,
                    call_feedback_new_mno: row.original.call_feedback__new_mno,
                  },
                ],
              }

              axios
                .post(
                  `${process.env.REACT_APP_KAM_API_URL}services/v1/generate-eml-report`,
                  requestBody,
                  {
                    headers: {
                      Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("currUserToken")),
                    },
                    responseType: "blob",
                  }
                )
                .then((res) => {
                  dispatchNotification({
                    type: "SUCCESS",
                    message: "Report fetched successfully",
                  })
                  const url = window.URL.createObjectURL(new Blob([res.data]))
                  const link = document.createElement("a")
                  link.href = url
                  link.setAttribute(
                    "download",
                    `${row.original.call_feedback__feedback}_${
                      Array.isArray(date)
                        ? `${moment(date[0]).format("YYYY-MM-DD")}_${moment(
                            date[1]
                          ).format("YYYY-MM-DD")}`
                        : moment(date).format("YYYY-MM-DD")
                    }_individual.eml`
                  ) //or any other extension
                  document.body.appendChild(link)
                  link.click()

                  setLoadingReport(false)
                })
                .catch((err) => {
                  dispatchNotification({
                    type: "ERROR",
                    message: "Could not fetch report",
                  })
                  setLoadingReport(false)
                })
            }}
          />
        ),
      },
      // {
      //   Header: "",
      //   id: "button",
      //   Cell: ({ row }) => (
      //     <AcknowlegeButton onClick={() => console.log(row.original)}>
      //       Resolution File
      //     </AcknowlegeButton>
      //   ),
      // },
    ],
    []
  )

  const handleChange = (arr) => {
    const changedArrForReport = arr.map((el) => ({
      id: el.id,
      wallet_no: el.outlet__wallet_no,
      branch_name: el.outlet__branch_name,
      brand_name: el.outlet__brand_name,
      comment: el.call_feedback__comment,
      status: el.status,
      kam_email: el.kam__email,
      call_feedback_email: el.call_feedback__email,
      call_feedback_image: el.call_feedback__image,
      call_feedback_start_date: el.call_feedback__start_date,
      call_feedback_end_date: el.call_feedback__end_date,
      created_date: el.zone_aware_created_date,
      call_feedback_new_address: el.call_feedback__new_address,
      call_feedback_old_address: el.call_feedback__old_address,
      call_feedback_old_mno: el.call_feedback__old_mno,
      call_feedback_new_mno: el.call_feedback__new_mno,
    }))

    setSelectedReports(changedArrForReport)
    setSelectedRows(arr)
  }

  const getSelectedReport = () => {
    const requestBody = {
      category: issue.category,
      issue: issue.feedback,
      bulk: false,
      services: selectedReports,
    }

    console.log(selectedReports)

    setLoadingReport(true)

    axios
      .post(
        `${process.env.REACT_APP_KAM_API_URL}services/v1/generate-eml-report`,
        requestBody,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        dispatchNotification({
          type: "SUCCESS",
          message: "Report fetched successfully",
        })
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `${issue.feedback}_${
            Array.isArray(date)
              ? `${moment(date[0]).format("YYYY-MM-DD")}_${moment(
                  date[1]
                ).format("YYYY-MM-DD")}`
              : moment(date).format("YYYY-MM-DD")
          }_bulk.eml`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
        setLoadingReport(false)
      })
      .catch((err) => {
        dispatchNotification({
          type: "ERROR",
          message: "Could not fetch report",
        })
        setLoadingReport(false)
      })
  }

  return (
    <div className="card-col col s12 l12">
      {loading ? (
        <div>
          <LoadingCard />
        </div>
      ) : (
        <div className="card strategic-transfer-card">
          <div className="card-title">
            <span>{strategicTransfers.card_heading}</span>
            {/* <AcknowlegeButton
            disabled={selectedRows.length <= 0}
            onClick={() => console.log(selectedRows)}
          >
            Mark Selected as Unresolved
          </AcknowlegeButton> */}
            <DownloadReportButton
              disabled={
                selectedRows.length <= 0 || !issue.feedback || loadingReport
              }
              onClick={getSelectedReport}
            >
              <BounceLoader
                color={color}
                loading={loadingReport}
                css={override}
                size={16}
              />
              Download Selected
            </DownloadReportButton>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              data={data}
              className="resolved"
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.servicing
    ? state.calendarDatePicker.servicing.date
    : state.calendarDatePicker.default.date

  let dateRaw = state.calendarDatePicker
  return { date, dateRaw }
}

export default connect(mapStateToProps)(ResolvedServices)

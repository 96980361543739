import React, { Component } from "react";
import moment from "moment";

import { string_uppercase } from "../../../utilities/_string_uppercase";
import CallModal from "./callModal";

import outlet_img from "./../../../img/calls_card/outlet.svg";
import mdo_img from "./../../../img/calls_card/mdo.svg";
import bKash_img from "./../../../img/calls_card/bKash.svg";
import phone_img from "./../../../img/calls_card/phone.svg";
import event_img from "./../../../img/calls_card/event.svg";
import bmcc_img from "./../../../img/calls_card/bmcc.svg";
import spot_img from "./../../../img/calls_card/spot.svg";
import biponon_img from "./../../../img/calls_card/biponon.svg";

import lead_closed_img from "./../../../img/calls_card/lead_closed.svg";
import lead_generated_img from "./../../../img/calls_card/lead_generated.svg";
import existing_merchant_served_img from "./../../../img/calls_card/existing_merchant_served.svg";
import lead_followed_up_img from "./../../../img/calls_card/lead_followed_up_img.svg";

import lead_closed__card_corner_img from "./../../../img/calls_card/lead_closed_card_corner.svg";
import lead_generated__card_corner_img from "./../../../img/calls_card/lead_generated_card_corner.svg";
import existing_merchant_served__card_corner_img from "./../../../img/calls_card/existing_merchant_served_card_corner.svg";
import lead_followed_up__card_corner_img from "./../../../img/calls_card/lead_followed_up_card_corner_img.svg";

import "../styles/callCard.css";

export default class CallCard extends Component {
  render() {
    return (
      <div className="card call-card" key={this.props.index}>
        <img
          className="img card-corner"
          src={
            this.props.call.call_outcome.toUpperCase() ===
            "EXISTING MERCHANT SERVED"
              ? existing_merchant_served__card_corner_img
              : this.props.call.call_outcome.toUpperCase() === "LEAD GENERATED"
              ? lead_generated__card_corner_img
              : this.props.call.call_outcome.toUpperCase() ===
                "LEAD FOLLOWED-UP"
              ? lead_followed_up__card_corner_img
              : lead_closed__card_corner_img
          }
          alt="card-corner"
        />
        <div className="row header">
          <div className="col s12 l12">
            <img
              src={
                this.props.call.call_outcome.toUpperCase() ===
                "EXISTING MERCHANT SERVED"
                  ? existing_merchant_served_img
                  : this.props.call.call_outcome.toUpperCase() ===
                    "LEAD GENERATED"
                  ? lead_generated_img
                  : this.props.call.call_outcome.toUpperCase() ===
                    "LEAD FOLLOWED-UP"
                  ? lead_followed_up_img
                  : lead_closed_img
              }
              alt="outlet"
            />

            <div className="call-info">
              Call Outcome
              <span
                className={
                  this.props.call.call_outcome.toUpperCase() ===
                  "EXISTING MERCHANT SERVED"
                    ? "existing_merchant_served_info"
                    : this.props.call.call_outcome.toUpperCase() ===
                      "LEAD GENERATED"
                    ? "lead_generated_info"
                    : this.props.call.call_outcome.toUpperCase() ===
                      "LEAD FOLLOWED-UP"
                    ? "lead_followed_up_info"
                    : "lead_closed_info"
                }
              >
                {string_uppercase(this.props.call.call_outcome) || "..."}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 l6">
            <img src={outlet_img} alt="outlet img" />
            <div className="call-info">
              Outlet Name
              <span className="name">
                {string_uppercase(this.props.call.outlet__brand_name) || "..."}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={mdo_img} alt="mdo img" />
            <div className="call-info">
              Assigned KAM's Name
              <span className="name">
                {string_uppercase(this.props.call.outlet__assigned_kam__name) ||
                  "..."}
              </span>
            </div>
          </div>

          <div className="col s12 l6">
            <img src={bKash_img} alt="bKash img" />
            <div className="call-info">
              Outlet bKash
              <span>{this.props.call.outlet__wallet_no || "..."}</span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={phone_img} alt="phone img" />
            <div className="call-info">
              KAM's Phone Number
              <span>
                {this.props.call.outlet__assigned_kam__whitelisting_number}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={biponon_img} alt="event img" />
            <div className="call-info">
              Merchant Type
              <span
                className={
                  // this.props.call.outlet_type.toUpperCase() === "MICRO MERCHANT"
                  //   ? "blue-type"
                  //   : this.props.call.outlet_type.toUpperCase() ===
                  //     "MERCHANT PLUS LITE B"
                  //   ? "navy-blue-type"
                  //   : this.props.call.outlet_type.toUpperCase() ===
                  //     "MERCHANT PLUS LITE A"
                  //   ? "purpple-type"
                  //   : this.props.call.outlet_type.toUpperCase() ===
                  //     "MEDIUM MERCHANT"
                  //   ? "olive-type"
                  //   : "green-type"
                  "green-type"
                }
              >
                {/* {string_uppercase(this.props.call.outlet_type)} */}
                Merchant Plus
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={event_img} alt="event img" />
            <div className="call-info">
              Call Date & Time
              <span>
                {moment(this.props.call.zone_aware_created).format("llll")}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={spot_img} alt="spot img" />
            <div className="call-info">
              Cluster Code
              <span>{this.props.call.outlet__cluster_code__code || "..."}</span>
              <span className="code-description">
                {this.props.call.outlet__cluster_code__name || "..."}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={bmcc_img} alt="bmcc img" />
            <div className="call-info">
              BMCC Code
              <span>{this.props.call.outlet__bmcc__code || "..."}</span>
              <span className="code-description bmcc-code-description">
                {this.props.call.outlet__bmcc__name || "..."}
              </span>
            </div>
          </div>
        </div>
        <CallModal
          corner_image={
            this.props.call.call_outcome.toUpperCase() ===
            "EXISTING MERCHANT SERVED"
              ? existing_merchant_served__card_corner_img
              : this.props.call.call_outcome.toUpperCase() === "LEAD GENERATED"
              ? lead_generated__card_corner_img
              : this.props.call.call_outcome.toUpperCase() ===
                "LEAD FOLLOWED-UP"
              ? lead_generated__card_corner_img
              : lead_closed__card_corner_img
          }
          call_outcome_image={
            this.props.call.call_outcome.toUpperCase() ===
            "EXISTING MERCHANT SERVED"
              ? existing_merchant_served_img
              : this.props.call.call_outcome.toUpperCase() === "LEAD GENERATED"
              ? lead_generated_img
              : this.props.call.call_outcome.toUpperCase() ===
                "LEAD FOLLOWED-UP"
              ? lead_generated_img
              : lead_closed_img
          }
          call_outcome={this.props.call.call_outcome}
          call_id={this.props.call.id}
        />
      </div>
    );
  }
}

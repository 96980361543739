import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { createStore, applyMiddleware } from "redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import rootReducer from "./store/reducers/rootReducer"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import NotificationProvider from "./Components/Notifications/NotificationProvider"
import { SWRConfig } from "swr"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"

import axios from "axios"

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

const persistor = persistStore(store)

// Entry Point
ReactDOM.render(
  <Provider store={store}>
    <NotificationProvider>
      <PersistGate persistor={persistor}>
        <SWRConfig
          value={{
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            fetcher: (url) =>
              axios(url, {
                headers: {
                  Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("currUserToken")),
                },
              }).then((res) => res.data),
          }}
        >
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </SWRConfig>
      </PersistGate>
    </NotificationProvider>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, { useState } from "react"
import "./styles/createTransferModal.scss"
// import EscapeOutside from "react-escape-outside";

import close_green_corner from "../../../img/dashboard_card/close_green_corner.svg"
import close_button from "../../../img/dashboard_card/close_button.svg"

import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
// import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete"
// import CircularProgress from "@material-ui/core/CircularProgress";

import { Autocomplete as AutocompleteF } from "formik-material-ui-lab"
import { Formik, Form, Field } from "formik"

// import TransferLeadModal from "./transferLeadModal";
import useSWR from "swr"
import axios from "axios"
import { useNotification } from "../../Notifications/NotificationProvider"

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      {/* <EscapeOutside onEscapeOutside={handleClose}> */}
      <div className="wrapper-modal">
        <section className="modal-main">{children}</section>
      </div>
      {/* </EscapeOutside> */}
    </div>
  )
}

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#a39f9f",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#B7B7B7",
      },
      "&:hover fieldset": {
        borderColor: "#a39f9f",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#B7B7B7",
      },
    },
  },
})(TextField)

const initialValues = {
  brand_name: "",
  branch_name: "",
  address: "",
  kam_whitelisting_number: "",
  cluster_code: { code: "", name: "" },
  contact_person: "",
  contact_person_designation: "",
  phone_number: "",
  competitions: null,
  lead: null,
  transfer: false,
  selected_kam: "",
}

const CreateTransferModal = ({ handleHide, show }) => {
  const [transfer, setTransfer] = useState(false)
  const [kamID, setKamID] = useState("")
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const dispatchNotification = useNotification()

  const kam_list_url = `${process.env.REACT_APP_KAM_API_URL}users/v1/get-kam-list`
  const cluster_per_kam_url = `${process.env.REACT_APP_KAM_API_URL}users/v1/${kamID}/get-cluster-info`

  const { data: kam_list, error: kam_list_err } = useSWR(kam_list_url)
  const { data: cluster_list, error: cluster_list_error } =
    useSWR(cluster_per_kam_url)

  const submit = (values, { resetForm }) => {
    setSubmitting(true)
    const formattedData = {
      branch_name: values.branch_name,
      brand_name: values.brand_name,
      contact_person_name: values.contact_person,
      contact_person_phone_number: values.phone_number,
      contact_person_role: values.contact_person_designation.toLowerCase(),
      address: values.address,
      kam_whitelisting_number: values.selected_kam.whitelisting_number,
      cluster_code: values.cluster_code.code,
      competitions: [
        {
          name: values.competitions.name,
          completed: true,
        },
      ],
    }

    axios
      .post(
        `${process.env.REACT_APP_KAM_API_URL}outlets/v1/transfer-outlet`,
        formattedData,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        console.log(res)
        resetForm({})
        setError(false)
        setSubmitting(false)
        handleHide()
        dispatchNotification({
          type: "SUCCESS",
          message: "Lead created successfully",
        })
      })
      .catch((err) => {
        console.log(err)
        resetForm({})
        setSubmitting(false)
        setError(true)
      })

    // if (values.transfer) setTransfer(true);
  }

  return (
    <section className={`modal-main createTransfer-modal-section`}>
      <Modal show={show} handleClose={handleHide}>
        <div className="modal-header">
          <div className="headline">
            <div className="main-headline">Create Lead</div>
            <div className="sub-headline">Lead Creation {`&`} Transfer </div>
          </div>
          <div className="close-button-corner" onClick={handleHide}>
            <img className="corner" src={close_green_corner} alt="close" />
            <img className="cross" src={close_button} alt="close" />
          </div>
        </div>

        <div className="modal-body">
          <div className="basic">
            <div className="form-container">
              <Formik initialValues={initialValues} onSubmit={submit}>
                {({
                  submitForm,
                  resetForm,
                  handleBlur,
                  isSubmitting,
                  handleChange,
                  values,
                  setFieldValue,
                  touched,
                  errors,
                  handleReset,
                }) => (
                  <Form>
                    <div className="row">
                      <CustomTextField
                        required
                        className="txt-field rm"
                        name="brand_name"
                        label="Brand Name"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brand_name}
                      />
                      <CustomTextField
                        required
                        className="txt-field lm"
                        name="branch_name"
                        label="Branch Name"
                        variant="outlined"
                        onChange={handleChange("branch_name")}
                        onBlur={handleBlur}
                        value={values.branch_name}
                      />
                    </div>
                    <div className="row">
                      <CustomTextField
                        required
                        className="txt-field address"
                        name="address"
                        label="Merchant Address"
                        variant="outlined"
                        fullWidth={true}
                        onChange={handleChange("address")}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                    </div>
                    <div className="row">
                      <CustomTextField
                        required
                        className="txt-field rm"
                        name="contact_person"
                        label="Contact Person"
                        variant="outlined"
                        onChange={handleChange("contact_person")}
                        onBlur={handleBlur}
                        value={values.contact_person}
                      />
                      <Autocomplete
                        required
                        id="contact_person_designation"
                        name="contact_person_designation"
                        options={options2}
                        inputValue={values.contact_person_designation}
                        // getOptionLabel={(option) => option.name}
                        // style={{ height: 30 }}
                        onChange={(e, value) => {
                          console.log(value)
                          setFieldValue(
                            "contact_person_designation",
                            value !== null
                              ? value
                              : initialValues.contact_person_designation
                          )
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            // className="txt-field lm"
                            required
                            label="Contact Person Designation"
                            variant="outlined"
                            name="contact_person_designation"
                            onBlur={handleBlur}
                            onChange={handleChange(
                              "contact_person_designation"
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="row">
                      <CustomTextField
                        required
                        className="txt-field rm"
                        name="phone_number"
                        label="Phone Number"
                        variant="outlined"
                        onChange={handleChange("phone_number")}
                        onBlur={handleBlur}
                        value={values.phone_number}
                      />

                      <Field
                        required
                        name="competitions"
                        component={AutocompleteF}
                        options={competitions}
                        getOptionLabel={(option) => option.name}
                        // fullWidth={true}
                        // style={{ width: "97%" }}
                        onChange={(e, value) => {
                          console.log(value)
                          setFieldValue(
                            "competitions",
                            value !== null ? value : initialValues.competitions
                          )
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            // error={touched["lead"] && !!errors["lead"]}
                            // helperText={errors["lead"]}
                            required
                            label="Competition"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                    <div className="row">
                      {kam_list ? (
                        <Field
                          required
                          name="selected_kam"
                          component={AutocompleteF}
                          loading={!kam_list}
                          options={
                            kam_list ? kam_list : { name: "Loading", id: null }
                          }
                          getOptionLabel={(option) => option.name}
                          fullWidth={true}
                          style={{ width: "97%" }}
                          onChange={(e, value) => {
                            console.log(value)
                            setKamID(
                              value !== null
                                ? value.id
                                : initialValues.selected_kam
                            )
                            setFieldValue(
                              "selected_kam",
                              value !== null
                                ? value
                                : initialValues.selected_kam
                            )
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              // error={touched["lead"] && !!errors["lead"]}
                              // helperText={errors["lead"]}
                              label="Select KAM"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      ) : null}
                    </div>
                    <div className="row">
                      {!cluster_list && !cluster_list_error && (
                        <div className="loading-msg">
                          Loading cluster list ...
                        </div>
                      )}
                      {cluster_list && !cluster_list_error ? (
                        <Field
                          required
                          name="cluster_code"
                          component={AutocompleteF}
                          options={
                            cluster_list
                              ? cluster_list
                              : { code: "Loading", name: null }
                          }
                          getOptionLabel={(option) => option.code}
                          fullWidth={true}
                          style={{ width: "97%" }}
                          onChange={(e, value) => {
                            console.log(value)
                            setFieldValue(
                              "cluster_code",
                              value !== null ? value : null
                            )
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              // error={touched["lead"] && !!errors["lead"]}
                              // helperText={errors["lead"]}
                              required
                              label="Select Cluster Code"
                              variant="outlined"
                            />
                          )}
                        />
                      ) : null}
                    </div>

                    {/* <div className="transfer-lead-container">
                      <div className="checkbox-container">
                        <Checkbox
                          name="transfer"
                          onChange={handleChange}
                          checked={values.transfer}
                        />
                      </div>
                      <div className="label-container">
                        <span className="heading">Transfer Lead?</span>
                        <span className="description">
                          Select a KAM, if you want to transfer this lead. Else,
                          it’ll be created without being assigned to anyone.
                        </span>
                      </div>
                    </div> */}

                    {/* <div className="row">
                      <Field
                        name="lead"
                        component={AutocompleteF}
                        options={kamsx}
                        getOptionLabel={(option) => option.name}
                        fullWidth={true}
                        style={{ width: "97%" }}
                        onChange={(e, value) => {
                          console.log(value);
                          setFieldValue(
                            "lead",
                            value !== null ? value : initialValues.lead
                          );
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            // error={touched["lead"] && !!errors["lead"]}
                            // helperText={errors["lead"]}
                            label="Select KAM to Transfer Lead"
                            variant="outlined"
                          />
                        )}
                      />
                    </div> */}

                    <div className="footer">
                      <span className="helper">
                        {isSubmitting
                          ? "Submitting... Please Wait"
                          : error
                          ? "An error occured"
                          : "* Required"}
                      </span>
                      <button
                        className="button--cancel"
                        type="reset"
                        onClick={() => {
                          console.log(values)
                          handleReset()
                          handleHide()
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="button--save"
                        type="submit"
                        disabled={isSubmitting}
                        // onClick={submit}
                      >
                        Confirm
                      </button>
                      {/* <TransferLeadModal
                        data={values}
                        show={transfer}
                        closeAll={handleHide}
                        handleHideTransfer={() => {
                          setTransfer(false);
                          setFieldValue("transfer", false);
                        }}
                      /> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  )
}

// export const CheckBox = ({ ...props }) => {
//   const [field] = useField(props.name);

//   return <Checkbox {...field} checked={field.value} />;
// };

const kamsx = [
  { name: "Arafat Hossain Tanim", id: 24196412132 },
  { name: "Azaz Ahamed", id: 65468746513 },
  { name: "Nibir Hasan Kamal", id: 515768546549 },
  { name: "Nibras Chowdhury", id: 654165194874 },
  { name: "Raju Ahmed Shetu", id: 601330354684 },
  { name: "Mushfiqur Rahman", id: 652135483432 },
  { name: "Bilal Hossein", id: 654165874654 },
  { name: "Wahidul Haque", id: 123134366756 },
  { name: "sdfsadfihj0ij", id: 456457646756 },
  { name: "asodufy9sadhf", id: 684321689346 },
  { name: "opiayf90hsadf", id: 4968465313541 },
  { name: "apsdify0[asod", id: 120654016590 },
  { name: "osdaifh0-asdihf", id: 541568465131 },
]

const competitions = [
  {
    name: "rocket",
    completed: true,
  },
  {
    name: "nagad",
    completed: true,
  },
]
const options2 = ["Manager", "Assistant", "Quality Assurance"]

export default CreateTransferModal

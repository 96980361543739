import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"

import moment from "moment"

import Report from "./components/report"

import { has_selected_filter } from "./../../utilities/_has_selected_filter"
import {
  relational_filter_list,
  non_relational_filter_list_dash_agency,
} from "./../../utilities/_filter_list_builder"

import FilterElement from "./../layout/FilterElement"
import Calendar from "./../layout/Calendar"

import { updateFilterElement } from "../../store/actions/filterElementAction"
import { signOut } from "../../store/actions/authAction"

import "./style.css"
import "./../layout/styles/filter.css"
import Footer from "../Footer/Footer"

const outlet_calls_by_merchandisersList = [
  {
    name: "Raw Data Report",
    url: `/api/reports/v1/raw-data-report`,
    filename: "raw_data",
    file_type: "xlsx",
  },
  {
    name: "MDO Performance Report",
    url: `/api/reports/v1/mdo-wise-report`,
    filename: "mdo_data",
    file_type: "xlsx",
  },
  {
    name: "Merchant Call Report",
    url: `/api/reports/v2/merchant-call-report`,
    filename: "merchant_call",
    file_type: "xlsx",
  },
  {
    name: "MDO Covid Phase Performance Report",
    url: `/api/reports/v2/mdo-wise-covid-report`,
    filename: "mdo_covid_phase_data",
    file_type: "xlsx",
  },
  {
    name: "KYC Action Report",
    url: `/api/reports/v2/kyc-action-report`,
    filename: "kyc_action_report",
    file_type: "xlsx",
  },
  {
    name: "MDO Average Performance",
    url: `/api/reports/v3/agency-wise-report`,
    filename: "agency-wise_mdo_average_performance_report",
    file_type: "xlsx",
  },
  // {
  //   name: "Competition Update Report",
  //   url: `/api/reports/v1/competition-update-report`,
  //   filename: "competition_update_report",
  //   file_type: "xlsx",
  // },
  {
    name: "Cumulative Merchant Unique Visit Report",
    url: `/api/reports/v1/cumulative-merchant-unique-visit-summary-report`,
    filename: "cumulative_merchant_unique_visit_summary_report",
    file_type: "xlsx",
  },
  {
    name: "Not Visited Merchant List Report",
    url: `/api/reports/v1/not-visited-merchant-list-report`,
    filename: "not_visited_merchant_list_report",
    file_type: "xlsx",
  },
  {
    name: "Unique Regular Visit Report",
    url: `/api/reports/v1/unique-visit-summary-report`,
    filename: "unique_regular_visit_report",
    file_type: "xlsx",
  },
  {
    name: "Spot Info Report",
    url: `/api/reports/v3/spot-info-report`,
    filename: "spot_info_report_download_at_",
    file_type: "xlsx",
  },
  {
    name: "Spot Mapping Report",
    url: `/api/reports/v3/spot-mapping-report`,
    filename: "spot_mapping_report_download_at_",
    file_type: "xlsx",
  },
  {
    name: "Merchant Report",
    url: `/api/reports/v3/merchant-list-report`,
    filename: "merchant_report_report_download_at_",
    file_type: "xlsx",
  },
  {
    name: "Merchant Lead Report",
    url: `/api/reports/v3/merchant-lead-report`,
    filename: "merchant_lead_report_download_at_",
    file_type: "xlsx",
  },
  {
    name: "Merchant Last Followup Report",
    url: `/api/reports/v3/merchant-last-visit-report`,
    filename: "merchant_last_followup_report_download_at_",
    file_type: "xlsx",
  },
  {
    name: "Merchant Next Followup Report",
    url: `/api/reports/v3/merchant-followup-report`,
    filename: "merchant_next_followup_report_download_at_",
    file_type: "xlsx",
  },
  {
    name: "Merchant Lead Close Report",
    url: `/api/reports/v3/merchant-lead-close-report`,
    filename: "merchant_lead_close_report",
    file_type: "xlsx",
  },
  {
    name: "Employee information Report",
    url: `/api/reports/v3/mdo-information-report`,
    filename: "employee_mdo_information_report",
    file_type: "xlsx",
  },
]

const kam_reports = [
  {
    name: "Raw Data Report",
    url: `reports/v1/raw-data-report`,
    filename: "raw_data_report",
    file_type: "xlsx",
  },
  {
    name: "Not Visited Merchant Report",
    url: `reports/v1/not-visited-merchant-report`,
    filename: "not_visited_merchant_report",
    file_type: "xlsx",
  },
  {
    name: "Unique Merchant Visit Report",
    url: `reports/v1/unique-merchant-visit-report`,
    filename: "unique_merchant_visit_report",
    file_type: "xlsx",
  },
  {
    name: "Cumulative Merchant Visit Report",
    url: `reports/v1/cumulative-merchant-visit-report`,
    filename: "cumulative_merchant_visit_report",
    file_type: "xlsx",
  },
  {
    name: "Servicing Status Report",
    url: `reports/v1/servicing-status-report`,
    filename: "servicing_status_report",
    file_type: "xlsx",
  },
]

const kam_merchant_management_reports = [
  {
    name: "Merchant Management Portal Report",
    url: `reports/v1/mmt/raw-service-report`,
    filename: "mmt_portal_report",
    file_type: "xlsx",
  },
]

class Reports extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: null,
      endDate: null,
      currentPage: null,
      filterElement: null,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterElement !== prevProps.filterElement)
      this.set_data_set()
  }

  set_data_set = () =>
    this.setState({
      currentPage: this.props.currentPage,
      filterElement: this.props.filterElement,
    })

  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ))

  async remove_all_filter() {
    await this.props.updateFilterElement({
      reports: {
        relational: {},
        non_relational: {},
      },
    })
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))

    let current_time = Date.now().valueOf() / 1000
    if (this.props.expiration < current_time) this.props.signOut()

    return (
      <div className="main-container">
        <div
          className={
            JSON.parse(localStorage.getItem("currUserType")) !==
              "agency_user" && this.props.portal !== "kam"
              ? "reports"
              : "full-width-reports"
          }
        >
          <div className="page-header">
            <div className="page-heading">
              Reports
              <div className="page-sub-heading">
                {moment(this.props.startDate).format("ddd, MMM DD")} to{" "}
                {moment(this.props.endDate).format("ddd, MMM DD")}
              </div>
            </div>
            <div className="page-settings">
              {JSON.parse(localStorage.getItem("currUserType")) !==
                "agency_user" &&
                this.props.portal !== "kam" &&
                this.props.filterHide && (
                  <div className="filter-container">
                    <div className="header">
                      <div className="heading">Select Filter</div>
                      {has_selected_filter(this.props.filterElement) ? (
                        <div className="clear">
                          <button
                            className="active-button"
                            onClick={() => this.remove_all_filter()}
                          >
                            Clear All
                          </button>
                        </div>
                      ) : (
                        <div className="clear">
                          <button
                            className="inactive-button"
                            onClick={() => this.remove_all_filter()}
                            disabled
                          >
                            Clear All
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="body">
                      {localUserType === "agency_user"
                        ? this.create_filter_elements(
                            non_relational_filter_list_dash_agency("reports"),
                            "relational"
                          )
                        : this.create_filter_elements(
                            relational_filter_list("reports"),
                            "relational"
                          )}
                    </div>
                  </div>
                )}
              <div className="info">
                <Calendar
                  page={"reports"}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                />
              </div>
            </div>
          </div>

          <div className="card-row row">
            {this.props.portal === "dexter" &&
              outlet_calls_by_merchandisersList.map((item, index) => (
                <div key={index} className="card-col col l4 m6 s12">
                  <Report
                    report={item}
                    // currentPage={this.state.currentPage}
                    filterElement={this.state.filterElement}
                    // actions={this.props.actions}
                  />
                </div>
              ))}

            {this.props.portal === "kam" &&
              this.props.userType === "merchant_management" &&
              kam_merchant_management_reports.map((item, index) => (
                <div key={index} className="card-col col l4 m6 s12">
                  <Report
                    report={item}
                    currentPage={this.state.currentPage}
                    filterElement={this.state.filterElement}
                    // actions={this.props.actions}
                  />
                </div>
              ))}

            {this.props.portal === "kam" &&
              this.props.userType === "super_user" &&
              kam_reports.map((item, index) => (
                <div key={index} className="card-col col l4 m6 s12">
                  <Report
                    report={item}
                    currentPage={this.state.currentPage}
                    filterElement={this.state.filterElement}
                    // actions={this.props.actions}
                  />
                </div>
              ))}

            {this.props.portal === "kam" &&
              this.props.userType === "app_user" &&
              kam_reports.map((item, index) => (
                <div key={index} className="card-col col l4 m6 s12">
                  <Report
                    report={item}
                    currentPage={this.state.currentPage}
                    filterElement={this.state.filterElement}
                    // actions={this.props.actions}
                  />
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  let startDate = state.calendar.reports
    ? state.calendar.reports.startDate
    : state.calendar.default.startDate
  let endDate = state.calendar.reports
    ? state.calendar.reports.endDate
    : state.calendar.default.endDate
  let currentPage = state.page.currentPage
  let filterElement = state.filterElement.reports
    ? state.filterElement.reports
    : state.filterElement.default
  let filterHide = state.filter.filterHide

  let expiration = state.auth.authToken
    ? state.auth.authToken.expiration
    : 20000

  let portal = state.auth.authToken ? state.auth.authToken.portal : "dexter"

  let userType = state.auth.authToken ? state.auth.authToken.type : null

  // console.log(actions)

  return {
    startDate,
    endDate,
    currentPage,
    filterElement,
    filterHide,
    expiration,
    portal,
    userType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
    signOut: () => dispatch(signOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)

import React, { Component } from "react"
// import axios from "axios";
import http from "../../../utilities/_http"
import PropTypes from "prop-types"

import moment from "moment"

import { query_generate } from "../../../utilities/_query_generate"

import PieChart from "./pieChart"
import LoadingCard from "./loadingCard"

import "../styles/binaryGraphCard.scss"

import inc_img from "./../../../img/dashboard_card/inc_img.svg"
import dec_img from "./../../../img/dashboard_card/dec_img.svg"
import { ResponsiveContainer } from "recharts"

const qr__posm__trade = [
  {
    card_heading: "QR Code in Display",
    state: "qr_state",
    api_link: `api/reports/v1/qr-report`,
    inc_img: inc_img,
    dec_img: dec_img,
  },
  {
    card_heading: "Presence of POSM",
    state: "posm_state",
    api_link: `api/reports/v1/posm-report`,
    inc_img: inc_img,
    dec_img: dec_img,
  },

  // {
  //   card_heading: "Presence of Trade Letter",
  //   state: "trade_state",
  //   api_link: `api/reports/v1/trade-letter-report`,
  //   inc_img: inc_img,
  //   dec_img: dec_img,
  // },
]

const QR_POSM_COLORS = ["#E2136E", "#F2F2F2"]

/**
 * @description Two radial graps presenting QR Codes in Display and Presence of POSM.
 * @component Binary Graph Card
 * @category Dashboard
 * @author Arafat
 */

class BinaryGraphCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data_fetching: false,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.date !== prevProps.date ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set()
  }

  set_data_set = () => {
    qr__posm__trade.map((item, index) =>
      this.get_mdo__call_types__qr__posm__trade_data(item.api_link, item.state)
    )
  }

  get_mdo__call_types__qr__posm__trade_data = (api_link, state) => {
    let query_pre_process = [
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "region",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.region
            : [],
      },
      {
        key: "area",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.area
            : [],
      },
      {
        key: "territory",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.territory
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
    ]
    let query = query_generate(query_pre_process)

    this.setState({
      data_fetching: true,
    })

    http
      .get(
        `${process.env.REACT_APP_API_URL}/${api_link}?date=${moment(
          this.props.date
        ).format("YYYY-MM-DD")}${query !== "" ? "&" + query : ""}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) =>
        this.setState({
          [`${state}`]: res.data,
          data_fetching: false,
        })
      )
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  render() {
    return qr__posm__trade.map((item, index) => (
      <div className="card-col col s12 l6" key={index}>
        {this.state.data_fetching ? (
          <LoadingCard card_heading={`${item.card_heading}`} />
        ) : (
          <div className="card binary-graph-cardx">
            <div className="card-title">{item.card_heading}</div>
            {this.state[`${item.state}`] && (
              <div className="card-body">
                <div className="chart">
                  <ResponsiveContainer>
                    <PieChart
                      date={this.props.date}
                      data={this.state[`${item.state}`]}
                      COLORS={QR_POSM_COLORS}
                    />
                  </ResponsiveContainer>
                </div>
                <div className="data-table">
                  {this.state[`${item.state}`].map((info, index) =>
                    index < 2 ? (
                      <div className="data-container" key={"div-" + index}>
                        <div
                          className="rect"
                          style={{ background: `${QR_POSM_COLORS[index]}` }}
                        ></div>
                        <div className="data">
                          <div className="value">{info.counts}</div>
                          <div
                            className="tag tag--light"
                            style={{ textTransform: "capitalize" }}
                          >
                            {info.type.split("_").join(" ")}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="data-container"
                        key={"summary-" + index}
                        style={{
                          paddingTop: "24px",
                          borderTop: "1px solid #FAFAFA",
                        }}
                      >
                        {info.percentage < 0 ? (
                          <img src={item.dec_img} alt="dec" />
                        ) : info.percentage > 0 ? (
                          <img src={item.inc_img} alt="inc" />
                        ) : null}

                        <div className="data">
                          <div className="value">
                            {info.percentage < 0
                              ? info.percentage * -1
                              : info.percentage}{" "}
                            %
                          </div>
                          <div
                            className="tag tag--light"
                            style={{ textTransform: "capitalize" }}
                          >
                            {info.percentage < 0
                              ? "Less"
                              : info.percentage > 0
                              ? "More"
                              : ""}{" "}
                            Than Last Week
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    ))
  }
}

export default BinaryGraphCard

BinaryGraphCard.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

import React from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { query_generate } from "../../../utilities/_query_generate"

import LoadingCard from "./loadingCard"
import coverage_card_img from "./../../../img/dashboard_card/coverage_corner_image.svg"
import "./../styles/coverageCard.scss"
import useSWR from "swr"

/**
 * @description Newly designed MDO Coverage card.
 * @component Coverage Card
 * @category Dashboard
 * @author Zoha
 */

const coverage_card = {
  card_heading: "Merchant Visit Coverage",
  card_subheading: "Month to Date",
  api_link: `api/reports/v3/merchant-coverage-report`,
  conrner_right_top_img: coverage_card_img,
}

const options = {
  series: [
    {
      data: [400],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ["South Korea"],
  },
}

const CoverageCard = (props) => {
  let query_pre_process = [
    {
      key: "team",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.team
          : [],
    },
    {
      key: "region",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.region
          : [],
    },
    {
      key: "area",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.area
          : [],
    },
    {
      key: "territory",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.territory
          : [],
    },
    {
      key: "agency",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.agency
          : [],
    },
  ]
  let query = query_generate(query_pre_process)

  const url = `${process.env.REACT_APP_API_URL}/${
    coverage_card["api_link"]
  }?date=${moment(props.date).format("YYYY-MM-DD")}${
    query !== "" ? "&" + query : ""
  }`

  const { data: coverageData } = useSWR(url)
  return (
    <div className="card-col col s12 m12 l12 ">
      {!coverageData ? (
        <LoadingCard card_heading={`${coverage_card.card_heading}`} />
      ) : (
        <div className="card data-card-coverage">
          <img
            className="img card-corner"
            src={coverage_card.conrner_right_top_img}
            alt="card-corner"
          />

          <div className="card-title">{coverage_card.card_heading}</div>
          <div className="card-subtitle">{coverage_card.card_subheading}</div>

          <div className="card-body">
            <div className="card-content-container">
              <div className="row1">
                <div className="stats">
                  <div className="numbers">
                    <span className="total">{coverageData.call_generates}</span>
                    {` / `}
                    <span className="out-of">{coverageData.total_outlets}</span>
                  </div>
                  <div className="description">Outlets Covered</div>
                </div>
                <div className="divide"></div>
                <div className="chart">
                  <div className="percentage">{coverageData.percentage}%</div>
                  <div className="bar">
                    <div className="bar-bg">
                      <div
                        className="bar-progress"
                        style={{ width: coverageData.percentage + "%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CoverageCard.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

export default CoverageCard

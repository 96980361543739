import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { string_uppercase } from "../../../utilities/_string_uppercase";

import outlet_img from "./../../../img/outlets_card/outlet.svg";
import mdo_img from "./../../../img/outlets_card/mdo.svg";
import bKash_img from "./../../../img/outlets_card/bKash.svg";
import event_img from "./../../../img/outlets_card/event.svg";
import bmcc_img from "./../../../img/outlets_card/bmcc.svg";
import spot_img from "./../../../img/outlets_card/spot.svg";
import biponon_img from "./../../../img/outlets_card/biponon.svg";

import "./../styles/outletCard.css";

export default class OutletCard extends Component {
  render() {
    return (
      <div className="card outlet-card" key={this.props.index}>
        <div className="row header">
          <div className="col s12 l12">
            <img src={outlet_img} alt="outlet img" />
            <div className="outlet-info">
              Outlet Name
              <span>{string_uppercase(this.props.outlet.name) || "..."}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 l6">
            <img src={bKash_img} alt="bKash img" />
            {this.props.outlet.wallet_no ? (
              <div className="outlet-info">
                Merchant Wallet Number
                <span>{string_uppercase(this.props.outlet.wallet_no)}</span>
              </div>
            ) : (
              <div className="outlet-info">
                Merchant Contact Number
                <span>
                  {string_uppercase(
                    this.props.outlet.contact_person_phone_number
                  )}
                </span>
              </div>
            )}
          </div>

          <div className="col s12 l6">
            <img src={mdo_img} alt="mdo img" />
            <div className="outlet-info">
              Last Call By
              <span>
                {this.props.outlet.last_call_field_force_name || "..."}
              </span>
            </div>
          </div>

          <div className="col s12 l6">
            <img src={biponon_img} alt="biponon img" />
            <div className="outlet-info">
              Merchant Type
              <span
                className={
                  this.props.outlet.outlet_type.toUpperCase() ===
                  "MICRO MERCHANT"
                    ? "blue-type"
                    : this.props.outlet.outlet_type.toUpperCase() ===
                      "MERCHANT PLUS LITE B"
                    ? "navy-blue-type"
                    : this.props.outlet.outlet_type.toUpperCase() ===
                      "MERCHANT PLUS LITE A"
                    ? "purpple-type"
                    : this.props.outlet.outlet_type.toUpperCase() ===
                      "MEDIUM MERCHANT"
                    ? "olive-type"
                    : "green-type"
                }
              >
                {string_uppercase(this.props.outlet.outlet_type)}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={event_img} alt="event img" />
            <div className="outlet-info">
              Last Call Date & Time
              <span>
                {this.props.outlet.last_call
                  ? moment(this.props.outlet.last_call).format("llll")
                  : "..."}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={spot_img} alt="spot img" />
            <div className="outlet-info">
              Spot Code
              <span>{this.props.outlet.spot_code_id || "..."}</span>
              <span className="code-description">
                {this.props.outlet.spot_code__name || "..."}
              </span>
            </div>
          </div>
          <div className="col s12 l6">
            <img src={bmcc_img} alt="bmcc img" />
            <div className="outlet-info">
              BMCC Code
              <span>{this.props.outlet.bmcc_id || "..."}</span>
              <span className="code-description bmcc-code-description">
                {this.props.outlet.bmcc__name || "..."}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <button
            className="outlet-detail-navigation-button"
            onClick={this.handleOpenModal}
          >
            <Link to={`/outlets/${this.props.outlet.id}`}>
              View More Details
            </Link>
          </button>
        </div>
      </div>
    );
  }
}

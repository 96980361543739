import React, { useEffect } from "react"
import { connect } from "react-redux"
import "../styles/pw-history.scss"

import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"

import CallOverview from "./call-overview"
import OverallPerformance from "./overall-performance"
import TargetAchievement from "./target-achievement"
import ActiveMerchantBase from "./active-merchant-base"
import RegisteringSuccess from "./registering-success"
import LoadingCard from "../components/loadingCard"
import { setCurrentMonth } from "../../../store/actions/leaderboardTableSelectAction"

const PWHistorySection = ({
  past_months,
  current_month,
  setCurrentMonth,

  idNumber,
}) => {
  const handleChange = (event) => {
    setCurrentMonth(event.target.value)
  }

  return (
    <div className="pw-history-container">
      <div className="pw-history-header">
        <div className="section-title">
          <h1>Performance & Work History</h1>
          <span>May 2020</span>
        </div>
        <div className="header-options">
          {/* <Button className="back-btn" variant="outlined">
            Download Report
          </Button> */}
          <FormControl>
            <Select
              id="select-month"
              value={current_month}
              onChange={handleChange}
            >
              {past_months.map((month, i) => (
                <MenuItem key={i} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="card-row row">
        <div className="col s12 m12 l8">
          <div className="card-row row">
            <CallOverview idNumber={idNumber} />
          </div>
          <div className="card-row row">
            <TargetAchievement idNumber={idNumber} />
          </div>
          <div className="card-row row">
            <ActiveMerchantBase idNumber={idNumber} />
          </div>
          {/* <div className="card-row row">
            <RegisteringSuccess idNumber={idNumber} />
          </div> */}
        </div>
        <div className="col s12 m12 l4">
          <div className="card-row row">
            <OverallPerformance idNumber={idNumber} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  past_months: state.leaderboardPage.profile.past_months,
  current_month: state.leaderboardPage.profile.current_month,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentMonth: (month) => dispatch(setCurrentMonth(month)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PWHistorySection)

import React, { useEffect } from "react";
import "./leaderboard-profile-styles.scss";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
// import useSWR from "swr"
import Divider from "@mui/material/Divider";
import Footer from "../../Footer/Footer";
import ProfileHeader from "../components/profile-header";
import PWHistorySection from "../components/pw-history";
import {
  setCurrentMonth,
  setPastMonths,
} from "../../../store/actions/leaderboardTableSelectAction";
import { useNotification } from "../../Notifications/NotificationProvider";

const LeaderboardProfile = ({ match, setPastMonths, user_type }) => {
  const getPastSixMontsWithYear = () => {
    const pastSixMonths = [];
    for (let i = 0; i < 6; i++) {
      pastSixMonths.push(moment().subtract(i, "months").format("MMMM YYYY"));
    }
    return pastSixMonths;
  };

  useEffect(() => {
    setPastMonths(getPastSixMontsWithYear());
  }, []);

  const dispatchNotification = useNotification();

  // if (user_type !== "bkash-super-user") {
  //   dispatchNotification({
  //     type: "ERROR",
  //     message: "You do not have access to Leaderboard page",
  //   })
  //   dispatchNotification({
  //     type: "ERROR",
  //     message: "Redirecting to main page",
  //   })
  //   return <Redirect to="/signin" />
  // }

  return (
    <div className="profile-main-container">
      <div className="leaderboard-profile-container">
        <ProfileHeader idNumber={match.params.id} />
        <Divider />
        <PWHistorySection idNumber={match.params.id} />
        <Footer />
        {/* <h1>{match.params.id}</h1> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_type: state.auth.authToken ? state.auth.authToken.type : null,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentMonth: (month) => dispatch(setCurrentMonth(month)),
  setPastMonths: (months) => dispatch(setPastMonths([...months])),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardProfile);

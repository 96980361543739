import React, { Component } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";

import "./../styles/callLocation.css";

export default class CallLocation extends Component {
  render() {
    return (
      <div className="call-location-container">
        <Map center={this.props.location} zoom={15}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={this.props.location} />
        </Map>
      </div>
    );
  }
}

import React, { useEffect } from "react"
import "../styles/tabs.scss"
import { connect } from "react-redux"
import { changeTable } from "../../../store/actions/leaderboardTableSelectAction"

import useSWR from "swr"

const Tabs = ({ section, options, active_table, changeTable }) => {
  // let url = `https://${process.env.REACT_APP_KAM_API_URL}services/v1/feedbacks`
  // const { data } = useSWR(url)

  useEffect(() => {
    if (active_table.table === null) {
      changeTable({
        user: options.name,
        page: section,
        table: options.tabs[0],
        filter: "top",
        search: "",
      })
    }
  }, [])
  return (
    <div className="buttons-group">
      {options.tabs.map((tab, i) => (
        <div
          key={i}
          className={`im-btn ${
            i === 0 ? `first` : i !== options.tabs.length - 1 ? `` : `last`
          } ${active_table.table === tab ? `active` : `inactive`}`}
          onClick={() =>
            changeTable({
              user: options.name,
              page: section,
              table: tab,
              filter: "top",
            })
          }
        >
          <span>{tab}</span>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  let active_table = state.leaderboardPage.active_table

  return { active_table }
}

const mapDispatchToProps = (dispatch) => ({
  changeTable: (table) => dispatch(changeTable(table)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)

import React from "react"
import "./style.scss"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import UploadStatusTable from "./components/UploadStatusTable"

import rejectImage from "../../img/kyc/upload/Rejected.svg"

import UploadFile from "./components/UploadFile"

const AdminUpload = ({ expiration, user_type }) => {
  const uploadModules = [
    {
      id: 1,
      title: "Upload Outlet Status",
      api: "/upload-outlet-status",
      // helpText: "Select from computer",
    },
    {
      id: 2,
      title: "Upload Outlet Campaign",
      api: "/upload-outlet-campaign",
    },
    {
      id: 3,
      title: "Upload Spots",
      api: "/upload-spots",
    },
    {
      id: 4,
      title: "Upload Outlet Tagging",
      api: "/upload-outlet-tagging",
    },
    {
      id: 5,
      title: "Upload MDO Spots Mapping",
      api: "/spot-mapping-update",
    },
    {
      id: 6,
      title: "Upload Agency",
      api: "/upload-agencies",
    },
    {
      id: 7,
      title: "Upload Merchant Bill Info",
      api: "/upload-bill-info",
    },
  ]

  const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
  if (!localUserToken) return <Redirect to="/signin" />

  if (user_type !== "bkash-super-user") return <Redirect to="/" />

  let current_time = Date.now().valueOf() / 1000
  if (expiration < current_time) this.props.signOut()

  return (
    <div className="main-container">
      <div className="AdminUpload-container">
        <div className="page-header">
          <div className="page-heading">
            Admin Upload
            <div className="page-sub-heading">
              Upload necessary files using this portal
            </div>
          </div>
        </div>

        <div className="card-row row">
          {uploadModules.map((module) => (
            <div key={module.id} className="card-col col s12 m6 l6 ">
              <UploadFile
                heading={module.title}
                api={module.api}
                help={module.helpText ? module.helpText : null}
              />
            </div>
          ))}
        </div>

        <div className="card-row row">
          <UploadStatusTable />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  let expiration = state.auth.authToken
    ? state.auth.authToken.expiration
    : 20000

  let user_type = state.auth.authToken ? state.auth.authToken.type : null

  return { expiration, user_type }
}
export default connect(mapStateToProps)(AdminUpload)

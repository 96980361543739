import React from "react"
import "../styles/merchantCalls.styles.scss"
import moment from "moment"
import PropTypes from "prop-types"

// import axios from "axios";
// import http from "../../../utilities/_http";

import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
// import Chart from "react-apexcharts";

import { query_generate } from "../../../utilities/_query_generate"
import LoadingCard from "./loadingCard"
import Legends from "./legends"
import useSWR from "swr"

const COLORS = [
  "#F7D700",
  "#FF8400",
  "#FFC599",
  "#DD8E9E",
  "#E2136E",
  "#B620E0",
  "#6236FF",
  "#0066FF",
  "#00CFFF",
  "#00FFBC",
]

const api_link = "api/reports/v2/merchant-response-report"

/**
 * @description Newly designed Merchant Calls section that provides merchant response report
 * @component Merchant Calls 2
 * @category Dashboard
 * @author Zoha
 */

const MerchantCalls2 = (props) => {
  let query_pre_process = [
    {
      key: "team",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.team
          : [],
    },
    {
      key: "region",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.region
          : [],
    },
    {
      key: "area",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.area
          : [],
    },
    {
      key: "territory",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.territory
          : [],
    },
    {
      key: "agency",
      values:
        props.filterElement.relational !== undefined
          ? props.filterElement.relational.agency
          : [],
    },
  ]
  let query = query_generate(query_pre_process)

  const url = `${process.env.REACT_APP_API_URL}/${api_link}?date=${moment(
    props.date
  ).format("YYYY-MM-DD")}${query !== "" ? "&" + query : ""}`

  const { data } = useSWR(url)

  return (
    <div
      className={`card-col col s12 l12 ${
        !data || (data.length === 0 && `hide`)
      }`}
    >
      {!data ? (
        <LoadingCard card_heading={`Top Responses from Merchant Calls`} />
      ) : (
        <div className="mc-container">
          <div className="mc-header">Top Responses from Merchant Calls</div>
          <div className="mc-graph-container">
            <div className="mc-donut">
              <ResponsiveContainer>
                <PieChart
                  className="mc-pie"
                  width={250}
                  height={250}
                  // ticks={0}
                  // onMouseEnter={this.onPieEnter}
                >
                  <Pie
                    data={data.map((item) => ({
                      name: item.detail,
                      value: item.percentage,
                    }))}
                    nameKey="name"
                    dataKey="value"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    stroke="rgba(255, 255, 255, 0)"
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className={`mc-legends-1 ${data.length <= 5 ? `col2x` : ``}`}>
              {data.map((data, index) => (
                <Legends
                  key={COLORS[index]}
                  title={data.detail}
                  type={data.category_name}
                  percent={`${data.percentage}%`}
                  color={COLORS[index]}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MerchantCalls2

MerchantCalls2.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

import React from "react"
import "../styles/profile-header.scss"
import { useHistory } from "react-router-dom"
import useSWR from "swr"
import LoadingCard from "../components/loadingCard"
import Button from "@mui/material/Button"
import moment from "moment"

import agency from "../assets/agency.svg"
import id from "../assets/id.svg"
import call from "../assets/call.svg"
// import star from "../assets/star.svg"

const ProfileHeader = ({ idNumber }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/performance/v1/get-mdo-score?mdo_id=${idNumber}`
  const { data, error } = useSWR(url)
  const history = useHistory()
  return !data ? (
    <LoadingCard />
  ) : (
    <div className="profile-header-container">
      <Button
        className="back-btn"
        variant="outlined"
        onClick={() => history.goBack()}
      >
        {"< "}Back to Table
      </Button>
      <div className="image-column">
        <div className="image-container">
          <img
            src={
              data.data[0].mdo_image
                ? data.data[0].mdo_image
                : "https://via.placeholder.com/173"
            }
          />
        </div>
        <div className="rank-badge">
          <span>Rank # {data.data[0].mdo_rank}</span>
        </div>
      </div>
      <div className="details-column">
        <div className="name-position-row">
          <div className="name-container">
            <h1>{data.data[0].mdo_name}</h1>
            {/* IMPACT ZONE BADGE */}
            {/* <div className="imp-zone">
                          <img src={star} alt="impact zone" />
                          <span>Impact Zone</span>
                        </div> */}
          </div>

          <h3>Merchant Development Officer</h3>
          <p>
            MDO since{" "}
            {moment(data.data[0].mdo_activated_since).format("MMMM Do YYYY")}
          </p>
        </div>
        <div className="extra-details-row">
          <div className="id-col">
            <div className="icon-col">
              <img src={id} alt="id" />
            </div>
            <div className="text-col">
              <span className="label">ID</span>
              <span className="id">{data.data[0].employee_id}</span>
            </div>
          </div>
          <div className="number-col">
            <div className="icon-col">
              <img src={call} alt="id" />
            </div>
            <div className="text-col">
              <span className="label">Mobile Number</span>
              <span className="id">{data.data[0].mdo_number}</span>
            </div>
          </div>
          <div className="agency-col">
            <div className="icon-col">
              <img src={agency} alt="id" />
            </div>
            <div className="text-col">
              <span className="label">Agency</span>
              <span className="id">{data.data[0].agency_name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileHeader

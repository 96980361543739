import React, { Component } from "react"
// import axios from "axios";
import http from "../../../utilities/_http"
import PropTypes from "prop-types"

import moment from "moment"

import PieChart from "./pieChart"
import LoadingCard from "./loadingCard"

import "../styles/rightGraphCard.scss"

const REASON_COLORS = ["#E2136E", "#FF8400", "#8BB100", "#00CFFF"]
const resistance_causes = {
  card_heading: "Causes of Resistance",
  state: "resistance_causes_state",
  api_link: `api/reports/v1/reason-based-report`,
}

/**
 * @description Graph showing causes of resistance
 * @component Resistance Cause Card
 * @category Dashboard
 * @author Zoha
 */
class ResistanceCauseCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data_fetching: false,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.date !== prevProps.date ||
      this.props.filterElement !== prevProps.filterElement
    )
      this.set_data_set()
  }

  set_data_set = () => {
    let body = {
      start_time: moment(this.props.date).format("YYYY-MM-DD"),
      end_time: moment(this.props.date).format("YYYY-MM-DD"),
      team: this.props.filterElement.team
        ? this.props.filterElement.team.map((item) => item.value)
        : [],
      region: this.props.filterElement.region
        ? this.props.filterElement.region.map((item) => item.value)
        : [],
      area: this.props.filterElement.area
        ? this.props.filterElement.area.map((item) => item.value)
        : [],
      territory: this.props.filterElement.territory
        ? this.props.filterElement.territory.map((item) => item.value)
        : [],
      agency: this.props.filterElement.agency
        ? this.props.filterElement.agency.map((item) => item.value)
        : [],
    }

    this.setState({
      data_fetching: true,
    })

    http
      .post(
        `${process.env.REACT_APP_API_URL}/${resistance_causes["api_link"]}`,
        {
          ...body,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) =>
        this.setState({
          [`${resistance_causes["state"]}`]: res.data,
          data_fetching: false,
        })
      )
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  render() {
    return (
      <div className="card-col col s12 l6">
        {this.state.data_fetching ? (
          <LoadingCard card_heading={`${resistance_causes.card_heading}`} />
        ) : (
          <div className="card right-graph-cardx">
            <div className="card-title">{resistance_causes.card_heading}</div>
            {this.state[`${resistance_causes["state"]}`] && (
              <div className="card-body">
                <div className="chart">
                  <PieChart
                    resistancePie
                    date={this.props.date}
                    data={this.state.resistance_causes_state}
                    COLORS={REASON_COLORS}
                  />
                </div>
                <div className="data-table">
                  {this.state[`${resistance_causes["state"]}`].map(
                    (item, index) => (
                      <div className="data-container" key={"div-" + index}>
                        <div
                          className="rect"
                          style={{ background: `${REASON_COLORS[index]}` }}
                        ></div>
                        <div className="data">
                          <div className="value">{item.percentage}%</div>
                          <div
                            className="tag tag--light"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.reason}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ResistanceCauseCard

ResistanceCauseCard.propTypes = {
  /**
   * Current date to send fetch request for data on that particular date
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Object of filters selected from the Filter Element component to send fetch request accordingly
   */
  filterElement: PropTypes.object.isRequired,
}

// import React, { useState } from "react";
// import "./style.scss";

// import { SWRConfig } from "swr";
// import { connect } from "react-redux";
// import axios from "axios";
// import moment from "moment";
// import styled from "styled-components";

// import CreateTransferModal from "./components/createTransferModal";
// import Footer from "../Footer/Footer";
// import CalendarDateRangePicker from "./components/CalendarDateRangePicker";
// import PlusIcon from "../../img/kam_transfer/plus.svg";

// import Tabs from "./components/tabs";
// import NewStrategicTransfers from "./components/newStrategicTransfers";
// import PendingStrategicTransfers from "./components/pendingStrategicTransfers";
// import ResolvedStrategicTransfers from "./components/resolvedStrategicTransfers";

// import StrategicTransfersOverview from "./components/strategicTransfersOverview";

// const KAMTransfers = ({ date, active_table, changeTable }) => {
//   const [create_modal, set_create_modal] = useState(false);

//   return (
//     <div className="main-container">
//       <div className="kam-transfers-container">
//         <SWRConfig
//           value={{
//             shouldRetryOnError: false,
//             revalidateOnFocus: false,
//             fetcher: (url) =>
//               axios(url, {
//                 headers: {
//                   Authorization:
//                     "Bearer " +
//                     JSON.parse(localStorage.getItem("currUserToken")),
//                 },
//               }).then((res) => res.data),
//           }}
//         >
//           <div className="page-header">
//             <div className="page-heading-container">
//               <div className="text-container">
//                 Transfers
//                 <div className="page-sub-heading">
//                   {date.length > 0
//                     ? `${moment(date[0]).format("ddd, MMM DD")} to ${moment(
//                         date[1]
//                       ).format("ddd, MMM DD")}`
//                     : moment(date).format("ddd, MMM DD")}
//                   {/* {moment(date).format("ddd, MMM DD")} */}
//                   {/* Showing Today's Data Only */}
//                 </div>
//               </div>
//             </div>
//             <div className="page-settings">
//               <div className="info">
//                 <CalendarDateRangePicker page={"transfers"} date={date} />
//               </div>
//             </div>
//           </div>
//           <div className="create-button-container">
//             <Tabs />
//             {create_modal ? (
//               <CreateTransferModal
//                 show={create_modal}
//                 handleHide={() => set_create_modal(false)}
//               />
//             ) : null}

//             <LargeButton icon={PlusIcon} onClick={() => set_create_modal(true)}>
//               Create Lead {`&`} Transfer
//             </LargeButton>
//           </div>
//           <div className="card-row row">
//             {active_table === "new" && <NewStrategicTransfers />}
//             {active_table === "pending" && <PendingStrategicTransfers />}
//             {active_table === "resolved" && <ResolvedStrategicTransfers />}
//           </div>
//           <div className="card-row row">
//             <StrategicTransfersOverview />
//           </div>
//         </SWRConfig>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// const LargeButton = ({ icon, children, onClick }) => {
//   const Button = styled.button`
//     cursor: pointer;
//     display: flex;
//     /* justify-content: space-around; */
//     align-items: center;
//     background-color: #e2136e;
//     padding: 9px 20px;
//     border-radius: 4px;
//     border: none;
//     img {
//       padding-right: 5 px;
//     }
//     span {
//       color: white;
//       padding-left: 5px;
//       font-family: Roboto;
//       font-weight: 500;
//       font-size: 16px;
//     }

//     &:focus {
//       outline: none;
//       background-color: #e2136e;
//     }
//   `;
//   return (
//     <Button onClick={onClick}>
//       <img src={icon} alt="create-transfer" />
//       <span>{children}</span>
//     </Button>
//   );
// };

// const mapStateToProps = (state) => {
//   let date = state.calendarDatePicker.transfers
//     ? state.calendarDatePicker.transfers.date
//     : state.calendarDatePicker.default.date;

//   let active_table = state.transferTable.active_table;

//   return { date, active_table };
// };

// export default connect(mapStateToProps)(KAMTransfers);

// OLD;
import React, { useState } from "react"
import "./style.scss"

import { SWRConfig } from "swr"
import { connect } from "react-redux"
import axios from "axios"
import { Redirect } from "react-router-dom"
// import moment from "moment";
import styled from "styled-components"

import CreateTransferModal from "./components/createTransferModal"
import Footer from "../Footer/Footer"
// import CalendarDateRangePicker from "./components/CalendarDateRangePicker";
import PlusIcon from "../../img/kam_transfer/plus.svg"

// import StrategicTransfers from "./components/strategicTransfers";
// import StrategicTransfersOverview from "./components/strategicTransfersOverview";

const KAMTransfers = ({ date, expiration, user_type, portal }) => {
  const [create_modal, set_create_modal] = useState(false)

  const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
  if (!localUserToken) return <Redirect to="/signin" />

  let current_time = Date.now().valueOf() / 1000
  if (expiration < current_time) this.props.signOut()
  if (user_type !== "super_user" || portal !== "kam") return <Redirect to="/" />

  return (
    <div className="main-container">
      <div className="kam-transfers-container">
        <SWRConfig
          value={{
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            fetcher: (url) =>
              axios(url, {
                headers: {
                  Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("currUserToken")),
                },
              }).then((res) => res.data),
          }}
        >
          <div className="page-header">
            <div className="page-heading-container">
              <div className="text-container">
                Transfers
                <div className="page-sub-heading">
                  {/* {moment(date).format("ddd, MMM DD")} */}
                  Showing Today's Data Only
                </div>
              </div>
              <div className="create-button-container">
                <LargeButton
                  icon={PlusIcon}
                  onClick={() => set_create_modal(true)}
                >
                  Create Lead {`&`} Transfer
                </LargeButton>
                <CreateTransferModal
                  show={create_modal}
                  handleHide={() => set_create_modal(false)}
                />
              </div>
            </div>
            {/* <div className="page-settings">
              <div className="info">
                <CalendarDateRangePicker page={"transfers"} date={date} />
              </div>
            </div> */}
          </div>
          {/* <div className="card-row row">
            <StrategicTransfers />
          </div>
          <div className="card-row row">
            <StrategicTransfersOverview />
          </div> */}
        </SWRConfig>
      </div>
      <Footer />
    </div>
  )
}

const LargeButton = ({ icon, children, onClick }) => {
  const Button = styled.button`
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #e2136e;
    padding: 9px 20px;
    border-radius: 4px;
    border: none;
    img {
      padding-right: 5 px;
    }
    span {
      color: white;
      padding-left: 5px;
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
    }
    &:focus {
      outline: none;
      background-color: #e2136e;
    }
  `
  return (
    <Button onClick={onClick}>
      <img src={icon} alt="create-transfer" />
      <span>{children}</span>
    </Button>
  )
}

const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.transfers
    ? state.calendarDatePicker.transfers.date
    : state.calendarDatePicker.default.date

  let expiration = state.auth.authToken
    ? state.auth.authToken.expiration
    : 20000

  let portal = state.auth.authToken ? state.auth.authToken.portal : null

  let user_type = state.auth.authToken ? state.auth.authToken.type : null

  return { date, expiration, portal, user_type }
}

export default connect(mapStateToProps)(KAMTransfers)
